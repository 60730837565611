import AuthContext from '@/context';
import { SUPER_ADMIN_ACCOUNTING_ALLOWED_EMAILS, SUPER_ADMIN_DELETED_PROFILES_EMAILS } from '@/utils';
import { Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

interface Props {
  role?: string;
  level?: string;
}

const MenuSuperAdmin: React.FC<Props> = ({ role, level, ...props }) => {
  const { t } = useTranslation();
  const {
    state: { userDetails, impersonate },
  } = useContext(AuthContext);

  return (
    <Menu style={{ maxHeight: 'calc(100vh - 80px)', overflowY: 'auto' }} {...props}>
      <Menu.Item key="accounts">
        <NavLink activeClassName="is-active" to="/app/super-admin/accounts/all" className="menu_super_admin_accounts">
          Liste des comptes
        </NavLink>
      </Menu.Item>
      <Menu.Item key="pricing-simulator">
        <NavLink
          activeClassName="is-active"
          to="/app/super-admin/pricing-simulator"
          className="menu_super_admin_pricing_simulator"
        >
          Pricing simulator
        </NavLink>
      </Menu.Item>
      <Menu.Item key="change-email">
        {role === 'SUPER-ADMIN' && (
          <NavLink
            activeClassName="is-active"
            to="/app/super-admin/change-email"
            className="menu_super_admin_change-email"
          >
            Modification adresses e-mail
          </NavLink>
        )}
      </Menu.Item>
      <Menu.Item key="profiles-import">
        <NavLink
          activeClassName="is-active"
          to="/app/super-admin/users-import"
          className="menu_super_admin_users-import"
        >
          {`Importation d'utilisateurs`}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="users-batch">
        <NavLink activeClassName="is-active" to="/app/super-admin/users-batch" className="menu_super_admin_users-batch">
          {`Modification d'utilisateurs`}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="create-invoice">
        {role === 'SUPER-ADMIN' && (
          <NavLink
            activeClassName="is-active"
            to="/app/super-admin/create-invoice"
            className="menu_super_admin_create-invoice"
          >
            {`Create invoice`}
          </NavLink>
        )}
      </Menu.Item>
      <Menu.Item key="impersonate">
        {role === 'SUPER-ADMIN' && (
          <NavLink
            activeClassName="is-active"
            to="/app/super-admin/impersonate"
            className="menu_super_admin_impersonate"
          >
            {`Impersonate`}
          </NavLink>
        )}
      </Menu.Item>
      {role === 'SUPER-ADMIN' && (level == '1000' || level == '1001') && (
        <Menu.Item key="marketplace">
          <NavLink
            activeClassName="is-active"
            to="/app/super-admin/marketplace"
            className="menu_super_admin_marketplace"
          >
            {t('SUPER_ADMIN.TITLES.MARKETPLACE')}
          </NavLink>
        </Menu.Item>
      )}
      {role === 'SUPER-ADMIN' && (level == '1000' || level == '1001') && (
        <Menu.Item key="collective-agreements">
          <NavLink
            activeClassName="is-active"
            to="/app/super-admin/collective-agreements"
            className="menu_super_admin_collective-agreements"
          >
            Collective agreements
          </NavLink>
        </Menu.Item>
      )}
      {role === 'SUPER-ADMIN' &&
        userDetails?.email &&
        SUPER_ADMIN_DELETED_PROFILES_EMAILS.includes(userDetails.email) &&
        !impersonate && (
          <Menu.Item key="deleted-profiles">
            <NavLink
              activeClassName="is-active"
              to="/app/super-admin/deleted-profiles"
              className="menu_super_admin_deleted-profiles"
            >
              Deleted profiles
            </NavLink>
          </Menu.Item>
        )}
      <SubMenu title="Stats" key="stats-submenu">
        {role === 'SUPER-ADMIN' && level === '1001' && (
          <Menu.Item key="billing-recap">
            <NavLink
              activeClassName="is-active"
              to="/app/super-admin/billing-recap"
              className="menu_super_admin_billing_recap"
            >
              {t('SUPER_ADMIN.BILLING_RECAP.TITLE')}
            </NavLink>
          </Menu.Item>
        )}
        {role === 'SUPER-ADMIN' && level === '1001' && (
          <Menu.Item key="data-metrics">
            <NavLink
              activeClassName="is-active"
              to="/app/super-admin/data-metrics"
              className="menu_super_admin_data_metrics"
            >
              {t('SUPER_ADMIN.TITLES.DATA_METRICS')}
            </NavLink>
          </Menu.Item>
        )}
        {role === 'SUPER-ADMIN' && (level === '1000' || level === '1001') && (
          <Menu.Item key="cx-stats">
            <NavLink activeClassName="is-active" to="/app/super-admin/cx-stats" className="menu_super_admin_cx-stats">
              CX Stats
            </NavLink>
          </Menu.Item>
        )}
        {role === 'SUPER-ADMIN' && (level === '1000' || level === '1001') && (
          <Menu.Item key="sales-stats">
            <NavLink
              activeClassName="is-active"
              to="/app/super-admin/sales-stats"
              className="menu_super_admin_sales-stats"
            >
              Sales Stats
            </NavLink>
          </Menu.Item>
        )}
      </SubMenu>
      {SUPER_ADMIN_ACCOUNTING_ALLOWED_EMAILS.includes(userDetails!.email) && !impersonate && (
        <SubMenu title="Accounting" key="accounting-submenu">
          <>
            <Menu.Item key="customers">
              <NavLink
                activeClassName="is-active"
                to="/app/super-admin/accounting/customers"
                className="menu_super_admin_accounting_customers"
                exact
              >
                Customers
              </NavLink>
            </Menu.Item>
            <Menu.Item key="invoices">
              <NavLink
                activeClassName="is-active"
                to="/app/super-admin/accounting/invoices"
                className="menu_super_admin_accounting_invoices"
                exact
              >
                Invoices
              </NavLink>
            </Menu.Item>
            <Menu.Item key="subscriptions">
              <NavLink
                activeClassName="is-active"
                to="/app/super-admin/accounting/subscriptions"
                className="menu_super_admin_accounting_subscriptions"
                exact
              >
                Subscriptions
              </NavLink>
            </Menu.Item>
            <Menu.Item key="credit-notes">
              <NavLink
                activeClassName="is-active"
                to="/app/super-admin/accounting/credit-notes"
                className="menu_super_admin_accounting_credit-notes"
                exact
              >
                Credit notes
              </NavLink>
            </Menu.Item>
          </>
        </SubMenu>
      )}
    </Menu>
  );
};

export default MenuSuperAdmin;
