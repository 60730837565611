import ScrollToTop from '@/components/ScrollToTop';
import Constraints from '@/pages/app/components/Constraints';
import TimePickerFormItem from '@/pages/app/components/TimePickerFormItem';
import AppContext from '@/pages/app/context';
import { default as Colors, default as colors } from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { IShortcut } from '@/types/shortcut.model';
import { ISkill } from '@/types/skill.model';
import { ITask } from '@/types/task.model';
import { IUser, IUserWithInfos } from '@/types/user.model';
import {
  ORDINAL_NUMBERS,
  difference,
  getWindowSize,
  isFeatureEnabled,
  isNullOrUndefined,
  minutesToHours,
  valueForSearch,
} from '@/utils';
import { CloseOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Spin,
  message,
} from 'antd';
import { SelectValue } from 'antd/es/select';
import { Store } from 'antd/lib/form/interface';
import { RadioChangeEvent } from 'antd/lib/radio';
import axios from 'axios';
import { cloneDeep, debounce, isEmpty, pick } from 'lodash';
import moment from 'moment';
import React, { Dispatch, useCallback, useContext, useEffect, useState } from 'react';
import { ReactHeight } from 'react-height';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ActionType, updateShiftsAndContextWithShiftResponse } from '../../hours/manage/redux/actions';
import { InitialStateType } from '../../hours/manage/redux/store';
import ModalShiftRecurrence from '../ModalShiftRecurrence';
import UserSelectInfos from '../UserSelectInfos';
import DrawerSkill from './Skill';
import DrawerTask from './Task';
import DrawerUser from './User';

const { Option, OptGroup } = Select;
const { TextArea } = Input;

interface Props {
  className?: string;
  visible: boolean;
  setVisible: (visibility: boolean) => void;
}

const DrawerShift: React.FC<Props> = ({ className, visible, setVisible }) => {
  const { state: appContextState, dispatch: appContextDispatch } = useContext(AppContext);
  const {
    sections,
    loadingSections,
    activeSection,
    activeDepartmentId,
    activeDepartment,
    departments,
    skills,
    loadingSkills,
    tasks,
    loadingTasks,
    resources,
    loadingResources,
    dayoffs,
    loadingDayoffs,
    packages,
    loadingPackages,
    shortcuts,
    loadingShortcuts,
    userCategories,
    features,
  } = appContextState;
  const { t } = useTranslation(undefined, { useSuspense: false });
  const { selectedShift: shift, startDate, endDate, picker } = useSelector(
    ({ selectedShift, usersMap, shiftsMap, startDate, endDate, picker }: InitialStateType) => ({
      selectedShift,
      usersMap,
      shiftsMap,
      startDate,
      endDate,
      picker,
    }),
    shallowEqual,
  );
  const hoursDispatch: Dispatch<ActionType> = useDispatch();

  const [form] = Form.useForm();
  const [shiftDay, setShiftDay] = useState<moment.Moment>();
  const [monthDate, setMonthDate] = useState<moment.Moment>(moment());
  const [newStart, setNewStart] = useState<number | null>(null);
  const [tasksStart, setTasksStart] = useState<(number | null)[]>([]);

  const [shiftType, setShiftType] = useState<'shift' | 'absence'>('shift');
  const [loading, setLoading] = useState<boolean>(false);
  const [originalForm, setOriginalForm] = useState<any>(null);
  const [users, setUsers] = useState<IUserWithInfos[]>([]);
  const [hrPartners, setHrPartners] = useState<any[]>([]);
  const [loadingHrPartners, setLoadingHrPartners] = useState<boolean>(false);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [activeUser, setActiveUser] = useState<IUserWithInfos | null>(null);
  const [department, setDepartment] = useState<IDepartment | null>(null);
  const [checkUsersParams, setCheckUsersParams] = useState<{
    start: number;
    end?: number;
    skills: string[];
    section?: string;
    userRecordId?: string;
    pause: {
      unpaid?: number;
      paid?: number;
    };
  } | null>(null);
  const [recurrence, setRecurrence] = useState<any>(null);
  const [constraintsHeight, setConstraintsHeight] = useState<number>(0);
  const [lastTaskEndTimestamp, setLastTaskEndTimestamp] = useState<number>(0);

  // DRAWERS
  const [drawerUserIsVisible, setDrawerUserIsVisible] = useState<boolean>(false);
  const [drawerSkillIsVisible, setDrawerSkillIsVisible] = useState<boolean>(false);
  const [drawerTaskIsVisible, setDrawerTaskIsVisible] = useState<boolean>(false);

  // MODALS
  const [modalShiftRecurrenceIsVisible, setModalShiftRecurrenceIsVisible] = useState<boolean>(false);

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onValuesChange = (changedValues: Store, values: Store) => {
    if (changedValues?.dayoff?.fullDay && activeUser?.defaultHours) {
      const startHour = activeDepartment?.scheduleParams?.default_shift_start_time || 0;
      form.setFieldsValue({
        start_time: `${startHour.toString().padStart(2, '0')}:${new Number(0)?.toString().padStart(2, '0')}`,
      });
      changeStartDate({ hour: startHour, minute: 0 });
      const startTimestamp = moment(shiftDay).set('hour', startHour).set('minute', 0);
      const endTimestamp = moment(startTimestamp).add(activeUser.defaultHours!, 'second');

      form.setFieldsValue({
        end_time: `${endTimestamp.hours().toString().padStart(2, '0')}:${endTimestamp
          .minutes()
          .toString()
          .padStart(2, '0')}`,
      });
      changeEndDate({ hour: endTimestamp.hours(), minute: endTimestamp.minutes() });
    }

    const pickedChangedValues = pick(changedValues, ['skills', 'section', 'userRecordId']);
    if (!isEmpty(pickedChangedValues)) {
      const picked = {
        ...pick(values, ['start', 'end', 'skills', 'section', 'userRecordId', 'pause']),
        ...pickedChangedValues,
      };
      if (!picked.start) {
        picked.start = shiftDay?.unix();
      }
      setCheckUsersParams(picked);
    }
  };

  const onUsersParamsChange = () => {
    const cancelTokenSource = axios.CancelToken.source();
    setLoadingUsers(true);
    setUsers([]);
    setActiveUser(null);
    const hasUserRecordId = checkUsersParams?.userRecordId && checkUsersParams?.userRecordId !== 'open_shift';
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/operations/get-users`, {
        params: {
          departmentId: activeDepartmentId,
          ...checkUsersParams,
          userRecordId: hasUserRecordId ? checkUsersParams?.userRecordId : undefined,
          shiftId: shift?.id,
          pause: checkUsersParams?.pause?.unpaid,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        const users: IUserWithInfos[] = response.data;
        setUsers(users);
        setLoadingUsers(false);
        if (hasUserRecordId) {
          const user = users.find((x) => x.recordId === checkUsersParams!.userRecordId!);
          if (user) {
            setActiveUser(user);
          }
        }
      })
      .catch((error) => {
        setLoadingUsers(false);
        console.log(error);
      });

    return () => {
      cancelTokenSource.cancel();
    };
  };

  const getHrPartners = () => {
    const cancelTokenSource = axios.CancelToken.source();
    setLoadingHrPartners(true);
    setHrPartners([]);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/operations/hr-partners`, {
        params: {
          departmentId: activeDepartmentId,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        const hr = response.data;
        setHrPartners(hr);
        setLoadingHrPartners(false);
      })
      .catch((error) => {
        setLoadingHrPartners(false);
        console.log(error);
      });

    return () => {
      cancelTokenSource.cancel();
    };
  };

  const onUsersParamsChangeDebounce = useCallback(debounce(onUsersParamsChange, 200), [
    visible,
    checkUsersParams?.userRecordId,
    checkUsersParams?.end,
    checkUsersParams?.start,
    checkUsersParams?.skills,
    checkUsersParams?.section,
    checkUsersParams?.pause,
  ]);

  useEffect(() => {
    const departmentId = shift?.departmentId || activeDepartmentId;
    const department = departments.find((department) => department.id === departmentId);
    setDepartment(department || null);
  }, [activeDepartmentId, shift?.departmentId]);

  useEffect(() => {
    if (visible && checkUsersParams) {
      onUsersParamsChangeDebounce();
      return () => {
        onUsersParamsChangeDebounce.cancel();
      };
    }
  }, [
    visible,
    checkUsersParams?.userRecordId,
    checkUsersParams?.end,
    checkUsersParams?.start,
    checkUsersParams?.skills,
    checkUsersParams?.section,
    onUsersParamsChangeDebounce,
  ]);

  useEffect(() => {
    if (picker !== 'month' || shift || !visible) return;
    form.resetFields();
    const startHour = activeDepartment?.scheduleParams?.default_shift_start_time || 9;
    const endHour = activeDepartment?.scheduleParams?.default_shift_end_time || 17;
    const startTimestamp = monthDate
      .set('hour', startHour || 0)
      .set('minute', 0)
      .format('HH:mm');
    const endTimestamp = monthDate
      .set('hour', endHour || 0)
      .set('minute', 0)
      .format('HH:mm');
    changeStartDate({ hour: activeDepartment?.scheduleParams?.default_shift_start_time || 9, minute: 0 });
    changeEndDate({ hour: activeDepartment?.scheduleParams?.default_shift_end_time || 17, minute: 0 });
    form.setFieldsValue({
      start_time: startTimestamp,
      end_time: endTimestamp,
      start: monthDate
        .set('hour', startHour || 0)
        .set('minute', 0)
        .unix(),
      end: monthDate
        .set('hour', endHour || 0)
        .set('minute', 0)
        .unix(),
    });
  }, [monthDate, visible]);

  useEffect(() => {
    getHrPartners();

    if (!visible || !shift) {
      return;
    }
    const fieldsValue: any = {
      ...cloneDeep(initialValues),
    };
    // Set fields value
    form.resetFields();
    let shiftDay = moment().startOf('day');

    if (shift) {
      const { start, end, pause, section, id, comment, userRecordId, homeworking = false, hr_code, hide } = shift;

      fieldsValue.homeworking = homeworking;

      if (comment) {
        fieldsValue.comment = comment;
      }

      if (section) {
        fieldsValue.section = section.id;
      } else {
        if (sections.length == 1) {
          fieldsValue.section = sections[0].id;
        }
      }

      if (start) {
        const startMoment = moment.unix(start);
        fieldsValue.start = start;
        fieldsValue.start_time = startMoment.format('HH:mm');
        setNewStart(start);
        shiftDay = startMoment.startOf('day');
      } else if (shift.timestamp) {
        shiftDay = moment(shift.timestamp).startOf('day');
      }

      if (end) {
        const endMoment = moment.unix(end);
        fieldsValue.end = end;
        fieldsValue.end_time = endMoment.format('HH:mm');
      }

      if (userRecordId) {
        fieldsValue.userRecordId = userRecordId;
      } else if (userRecordId === null) {
        fieldsValue.userRecordId = 'open_shift';
      }

      if (shift.dayoff) {
        setShiftType('absence');
        fieldsValue.type = 'absence';
        fieldsValue.dayoff = pick(shift.dayoff, ['paid', 'fullDay', 'dayOffType']);
      } else {
        setShiftType('shift');
        fieldsValue.type = 'shift';

        if (pause?.unpaid) {
          const _minutes = moment.duration(pause!.unpaid, 'seconds').asMinutes();
          const minutes = _minutes % 60;
          const hours = Math.trunc(_minutes / 60);
          fieldsValue.pause.unpaid_time = `${hours.toString().padStart(2, '0')}h${minutes.toString().padStart(2, '0')}`;
          fieldsValue.pause.unpaid = pause.unpaid;
        }

        if (pause?.paid) {
          const _minutes = moment.duration(pause!.paid, 'seconds').asMinutes();
          const minutes = _minutes % 60;
          const hours = Math.trunc(_minutes / 60);
          fieldsValue.pause.paid_time = `${hours.toString().padStart(2, '0')}h${minutes.toString().padStart(2, '0')}`;
          fieldsValue.pause.paid = pause.paid;
        }

        if (shift.tasks && shift.tasks.length > 0) {
          fieldsValue.tasks = shift.tasks.map((task) => {
            const { end, start, taskTypeId } = task;
            return {
              taskTypeId,
              end,
              start,
              start_time: moment.unix(start!).format('HH:mm'),
              end_time: moment.unix(end!).format('HH:mm'),
            };
          });
          setTasksStart(fieldsValue.tasks.map((task: any) => task.start));
          const _lastTaskEndTimestamp = Math.max(
            ...(fieldsValue.tasks?.map((task: { end?: number }) => task?.end || 0) || [0]),
            0,
          );
          setLastTaskEndTimestamp(_lastTaskEndTimestamp);
        } else {
          fieldsValue.tasks = [];
          setTasksStart([]);
          setLastTaskEndTimestamp(0);
        }

        if (shift.skills && shift.skills.length > 0) {
          fieldsValue.skills = shift.skills.map((skill) => {
            return skill.id;
          });
        } else {
          fieldsValue.skills = [];
        }

        if (shift.attributes && shift.attributes.length > 0) {
          fieldsValue.attributes = shift.attributes.map((attribut) => {
            return attribut.id;
          });
        } else {
          fieldsValue.attributes = [];
        }

        if (shift.package) {
          fieldsValue.packageId = [`${shift.package.id}`];
        }

        if (hr_code) {
          fieldsValue.hr_code = hr_code;
        }
      }

      if (hide) {
        fieldsValue.hide = hide;
      }

      form.setFieldsValue(fieldsValue);
      const picked = pick(fieldsValue, ['start', 'end', 'skills', 'section', 'userRecordId', 'pause']);
      if (!picked.start) {
        picked.start = shift?.start ? moment.unix(shift.start!).startOf('day').unix() : shift?.timestamp;
      }
      setCheckUsersParams(picked);
      setOriginalForm(fieldsValue);
      if (!id) {
        didChangeDate();
      }
    }
    setShiftDay(shiftDay);
    setRecurrence(null);
    // eslint-disable-next-line
  }, [visible, shift, sections]);

  const updateCheckUsersParams = () => {
    const fieldsValue = form.getFieldsValue();
    const picked = pick(fieldsValue, ['start', 'end', 'skills', 'section', 'userRecordId', 'pause']);
    setCheckUsersParams(picked);
  };

  const changeStartDate = ({ hour, minute }: { hour: number | null; minute: number | null }) => {
    const startTimestamp = moment(shiftDay)
      .set('hour', hour || 0)
      .set('minute', minute || 0)
      .unix();
    form.setFieldsValue({
      start: startTimestamp,
    });
    setNewStart(hour ? startTimestamp : null);
    const endDate = form.getFieldValue('end');
    if (endDate) {
      const endMoment = moment.unix(endDate);
      let endTimestamp = moment(shiftDay).set('hour', endMoment.hours()).set('minute', endMoment.minutes()).unix();
      if (endTimestamp < startTimestamp) {
        endTimestamp = moment.unix(endTimestamp).add(1, 'day').unix();
      }
      form.setFieldsValue({
        end: endTimestamp,
      });
      setCheckUsersParams({
        skills: [],
        ...checkUsersParams,
        start: startTimestamp || (shift?.start ? moment.unix(shift!.start!).startOf('day').unix() : shift!.timestamp!),
        end: endTimestamp,
        pause: form.getFieldValue('pause'),
      });
    } else {
      setCheckUsersParams({
        skills: [],
        ...checkUsersParams,
        start: startTimestamp || (shift?.start ? moment.unix(shift!.start!).startOf('day').unix() : shift!.timestamp!),
        pause: form.getFieldValue('pause'),
      });
    }

    const tasks = form.getFieldValue('tasks');
    if (tasks && tasks.length > 0) {
      const newTasks = tasks.map((task: any, index: number) => {
        // START
        let taskStartTimestamp = 0;
        if (task?.start) {
          const taskStartMoment = moment.unix(task?.start);
          taskStartTimestamp = moment(shiftDay)
            .set('hour', taskStartMoment.hours())
            .set('minute', taskStartMoment.minutes())
            .unix();
          if (taskStartTimestamp < startTimestamp) {
            taskStartTimestamp = moment.unix(taskStartTimestamp).add(1, 'day').unix();
          }
          task.start = taskStartTimestamp;
          const _tasksStart = tasksStart;
          _tasksStart[index] = taskStartTimestamp;
          setTasksStart(_tasksStart);
        }

        // END
        if (task?.end) {
          const taskEndMoment = moment.unix(task?.end);
          let taskEndTimestamp = moment(shiftDay)
            .set('hour', taskEndMoment.hours())
            .set('minute', taskEndMoment.minutes())
            .unix();
          if (taskEndTimestamp < startTimestamp || taskEndTimestamp < taskStartTimestamp) {
            taskEndTimestamp = moment.unix(taskEndTimestamp).add(1, 'day').unix();
          }
          task.end = taskEndTimestamp;
        }

        return task;
      });
      form.setFieldsValue({
        tasks: newTasks,
      });
    }
    if (shiftType === 'shift') {
      didChangeDate();
    }
  };

  const changeEndDate = ({ hour, minute }: { hour: number | null; minute: number | null }) => {
    let endValue = null;
    const startDate = form.getFieldValue('start');
    if (!isNullOrUndefined(hour)) {
      let endTimestamp = moment(shiftDay)
        .set('hour', hour || 0)
        .set('minute', minute || 0)
        .unix();
      if (startDate && endTimestamp < startDate) {
        endTimestamp = moment.unix(endTimestamp).add(1, 'day').unix();
      }
      endValue = endTimestamp;
    }

    setCheckUsersParams({
      skills: [],
      ...checkUsersParams,
      start: startDate,
      end: endValue || undefined,
      pause: form.getFieldValue('pause'),
    });

    form.setFieldsValue({
      end: endValue,
    });

    if (shiftType === 'shift') {
      didChangeDate();
    }
  };

  const didChangeDate = () => {
    if (department?.scheduleParams?.pauseAuto) {
      const { pauses } = department?.scheduleParams;
      const startDate = form.getFieldValue('start');
      const endDate = form.getFieldValue('end');
      const duration = endDate - startDate;

      const sortedPauses = pauses?.filter((x) => duration >= x.from).sort((a, b) => b.from - a.from);

      if (sortedPauses?.length) {
        const greatestPause = sortedPauses[0];
        const paid = greatestPause?.paid ? greatestPause.duration : 0;
        const unpaid = !greatestPause?.paid ? greatestPause.duration : 0;

        if (shiftType === 'shift') {
          if (!form.getFieldValue(['pause', 'paid_time']) || !form.getFieldValue(['pause', 'unpaid_time'])) {
            form.setFieldsValue({
              pause: {
                paid,
                unpaid,
                paid_time: paid ? moment.unix(paid).format('HH:mm') : null,
                unpaid_time: unpaid ? moment.unix(unpaid).format('HH:mm') : null,
              },
            });
          }
        } else {
          if (unpaid) {
            const endDate = moment.unix(form.getFieldValue('end')).subtract(unpaid, 'seconds');
            form.setFieldsValue({
              end_time: endDate.format('HH:mm'),
            });
            changeEndDate({
              hour: endDate.hour(),
              minute: endDate.minute(),
            });
          }
        }
      } else {
        form.setFieldsValue({
          pause: {
            paid: 0,
            unpaid: 0,
            paid_time: null,
            unpaid_time: null,
          },
        });
      }
      updateCheckUsersParams();
    }
  };

  const changePaidPauseDate = ({ hour, minute }: { hour: number | null; minute: number | null }) => {
    const pause = form.getFieldValue('pause');
    if (isNullOrUndefined(hour)) {
      pause.paid = 0;
    } else {
      pause.paid = moment
        .duration(0)
        .add(hour || 0, 'hour')
        .add(minute || 0, 'minute')
        .asSeconds();
    }
    form.setFieldsValue({
      pause,
    });
  };

  const changeUnpaidPauseDate = ({ hour, minute }: { hour: number | null; minute: number | null }) => {
    const pause = form.getFieldValue('pause');
    if (isNullOrUndefined(hour)) {
      pause.unpaid = 0;
    } else {
      pause.unpaid = moment
        .duration(0)
        .add(hour || 0, 'hour')
        .add(minute || 0, 'minute')
        .asSeconds();
    }
    form.setFieldsValue({
      pause,
    });
    updateCheckUsersParams();
  };

  const changeTaskDate = ({
    index,
    name,
    hour,
    minute,
  }: {
    index: number;
    name: string;
    hour: number | null;
    minute: number | null;
  }) => {
    if (isNullOrUndefined(hour)) {
      const tasks = form.getFieldValue('tasks');
      if (tasks[index]) {
        tasks[index][name] = null;
        if (name === 'start') {
          const _tasksStart = tasksStart;
          _tasksStart[index] = null;
          setTasksStart(_tasksStart);
        }
      }
      form.setFieldsValue({
        tasks,
      });
    } else {
      let timestamp = moment(shiftDay)
        .set('hour', hour || 0)
        .set('minute', minute || 0)
        .unix();
      const startDate = form.getFieldValue('start');
      if (startDate && timestamp < startDate) {
        timestamp = moment.unix(timestamp).add(1, 'day').unix();
      }
      const taskItems = form.getFieldValue('tasks');
      if (name === 'end') {
        const task = taskItems[index];
        if (task) {
          const startTask = task['start'];
          if (startTask && timestamp < startTask) {
            timestamp = moment.unix(timestamp).add(1, 'day').unix();
          }
        }
      } else if (name === 'start') {
        const _tasksStart = tasksStart;
        _tasksStart[index] = timestamp;
        setTasksStart(_tasksStart);
        const { end, taskTypeId } = taskItems[index] || {};
        if (!end && !!taskTypeId) {
          const task = tasks?.find((x) => x.id === taskTypeId);
          if (task?.minutes) {
            const endTimestamp = task.minutes * 60 + timestamp;
            taskItems[index].end = endTimestamp;
            taskItems[index].end_time = moment.unix(endTimestamp).format('HH:mm');
          }
        }
      }

      taskItems[index][name] = timestamp;
      const _lastTaskEndTimestamp = Math.max(...(taskItems?.map((task: { end?: number }) => task?.end || 0) || [0]), 0);
      setLastTaskEndTimestamp(_lastTaskEndTimestamp);
      form.setFieldsValue({
        tasks: taskItems,
      });
    }
  };

  const changeShortcut = (value: string) => {
    if (value) {
      const shortcut = shortcuts.find((x) => x.id === value);
      if (shortcut) {
        const { starthour, startmin, endhour, endmin } = shortcut;
        form.setFieldsValue({
          start_time: `${starthour?.toString().padStart(2, '0')}:${startmin?.toString().padStart(2, '0')}`,
        });
        changeStartDate({ hour: shortcut.starthour || null, minute: shortcut.startmin || null });
        form.setFieldsValue({
          end_time: `${endhour?.toString().padStart(2, '0')}:${endmin?.toString().padStart(2, '0')}`,
        });
        if (shortcut?.endhour == 0 || shortcut.endmin == 0) {
          changeEndDate({ hour: shortcut.endhour as number, minute: shortcut.endmin as number });
        } else {
          changeEndDate({ hour: shortcut.endhour || null, minute: shortcut.endmin || null });
        }
      }
    }
    if (shiftType === 'absence') {
      didChangeDate();
    }
  };

  const onFinish = async (values: Store) => {
    setLoading(true);
    if (values.userRecordId == 'open_shift') {
      values.userRecordId = 0;
    }
    const diffs = difference({ ...values }, { ...originalForm });

    let shiftResponse;

    try {
      if (shift && shift.id) {
        if (!isEmpty(diffs)) {
          if (diffs.type === 'shift') {
            diffs.dayoff = null;
          }

          if (diffs.type === 'absence' && !!isFeatureEnabled(features, FEATURES.FREESHIFTS)) {
            let error = false;
            do {
              error = false;
              const wantToCreateOpenShift = await new Promise((resolve) => {
                Modal.confirm({
                  title: t('SCHEDULE.MODAL_FREE_SHIFT_CREATION.TITLE'),
                  content: (
                    <React.Fragment>
                      <h1>{t('SCHEDULE.MODAL_FREE_SHIFT_CREATION.SUBTITLE')}</h1>
                      <p>{t('SCHEDULE.MODAL_FREE_SHIFT_CREATION.DESCRIPTION')}</p>
                    </React.Fragment>
                  ),
                  onOk: () => {
                    resolve(true);
                  },
                  onCancel: () => {
                    resolve(false);
                  },
                  cancelText: t('GLOBAL.NO'),
                  okText: t('GLOBAL.YES'),
                });
              });

              if (wantToCreateOpenShift) {
                try {
                  shiftResponse = await axios.post(
                    `${process.env.REACT_APP_API_URL}/v3/shifts`,
                    {
                      ...removeUnnecessaryProperties(originalForm),
                      dayoff: null,
                      departmentId: activeDepartmentId,
                      userRecordId: null,
                    },
                    {
                      params: {
                        picker,
                        startDate: startDate.unix(),
                        endDate: endDate.unix(),
                        departmentId: activeDepartmentId,
                        sectionId: activeSection,
                      },
                    },
                  );
                } catch (err) {
                  error = true;
                  message.error(t('SCHEDULE.MODAL_FREE_SHIFT_CREATION.CREATION_ERROR'));
                  console.error(err);
                }
              }
            } while (error);
          }

          if (diffs.skills) {
            diffs.skills = values.skills;
          }

          if (diffs.tasks) {
            diffs.tasks = values.tasks;
          }

          if (diffs.pause) {
            diffs.pause = values.pause;
          }

          if (diffs.attributes) {
            diffs.attributes = values.attributes;
          }

          if (diffs.dayoff) {
            diffs.dayoff = values.dayoff;
          }

          if (diffs.homeworking) {
            diffs.homeworking = values.homeworking || false;
          }

          if (Object.keys(diffs).includes('hr_code')) {
            if (diffs.hr_code === undefined) {
              diffs.hr_code = null;
            }
          }

          shiftResponse = await axios.patch(
            `${process.env.REACT_APP_API_URL}/v3/shifts/${shift.id}`,
            {
              ...removeUnnecessaryProperties(diffs),
              departmentId: shift.departmentId,
            },
            {
              params: {
                picker,
                startDate: startDate.unix(),
                endDate: endDate.unix(),
                departmentId: activeDepartmentId,
                sectionId: activeSection,
              },
            },
          );
        } else {
          setVisible(false);
          setLoading(false);
          return;
        }
      } else {
        if (values.packageId || values.packageIds) {
          values.start = shiftDay?.unix();
        }

        shiftResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/v3/shifts`,
          {
            ...removeUnnecessaryProperties(values),
            departmentId: activeDepartmentId,
            recurrence: recurrence
              ? {
                  ...recurrence,
                  end: recurrence?.end ? recurrence.end.unix() : undefined,
                }
              : undefined,
          },
          {
            params: {
              picker,
              startDate: startDate.unix(),
              endDate: endDate.unix(),
              departmentId: activeDepartmentId,
              sectionId: activeSection,
            },
          },
        );
      }

      if (shiftResponse) {
        if (shiftResponse?.data?.occurrence) {
          if (shift?.id) {
            const { shiftsModified = 0, shiftsTotal = 0 } = shiftResponse?.data?.message;
            message.success(t('SHIFTS.MESSAGE_X_SHIFT(S)_UPDATED', { count: shiftsModified, total: shiftsTotal }));
          } else {
            const { shiftsCreated = 0, shiftsTotal = 0, shiftsExists = 0 } = shiftResponse?.data?.message;
            message.success(`
              ${t('SHIFTS.MESSAGE_X_SHIFT(S)_CREATED', { count: shiftsCreated, total: shiftsTotal })}
              ${shiftsExists ? t('SHIFTS.MESSAGE_X_SHIFT(S)_ALREADY_EXISTING', { count: shiftsExists }) : ''}
            `);
          }
        }
        updateShiftsAndContextWithShiftResponse(hoursDispatch, shiftResponse, {
          department: activeDepartment!,
          picker,
          startDate,
          endDate,
          activeSection,
          skills,
          userStatus: userCategories,
        });
        setLoading(false);
        setVisible(false);
      } else {
        throw new Error('No response');
      }
    } catch (error) {
      if (error?.response?.status === 409) {
        const message = error.response?.data?.message as any[];
        const forceable = !message?.some((m) => m.type === 'not_available');
        if (forceable) {
          Modal.confirm({
            className: 'modal-constraints',
            title: t('SCHEDULE.CONSTRAINTS.TITLE'),
            icon: null,
            content: <Constraints message={message || []} />,
            cancelText: t('GLOBAL.CANCEL'),
            okText: t('GLOBAL.FORCE'),
            onOk: () => {
              axios({
                method: error.response.config.method,
                url: error.response.config.url,
                data: {
                  ...JSON.parse(error.response.config.data),
                  force: true,
                },
                params: {
                  picker,
                  startDate: startDate.unix(),
                  endDate: endDate.unix(),
                  departmentId: activeDepartmentId,
                  sectionId: activeSection,
                },
              })
                .then((response) => {
                  updateShiftsAndContextWithShiftResponse(hoursDispatch, response, {
                    department: activeDepartment!,
                    picker,
                    startDate,
                    endDate,
                    activeSection,
                    skills,
                    userStatus: userCategories,
                  });
                  setLoading(false);
                  setVisible(false);
                })
                .catch((err) => {
                  console.error(err);
                  setLoading(false);
                });
            },
            onCancel: () => {
              setLoading(false);
            },
          });
        } else {
          Modal.info({
            className: 'modal-constraints',
            title: t('SCHEDULE.CONSTRAINTS.TITLE'),
            icon: null,
            content: <Constraints message={message || []} />,
            okText: t('GLOBAL.OK'),
            okType: 'default',
            onOk: () => {
              setLoading(false);
            },
          });
        }
      } else {
        if (error?.response?.status === 422) {
          message.error(error?.response?.data?.message);
        }
        setLoading(false);
      }
      console.error(error);
    }
  };

  const onShiftTypeChange = (e: RadioChangeEvent) => {
    const shiftType = e.target.value;
    setShiftType(shiftType);
    if (shiftType === 'absence') {
      const unpaidPause = form.getFieldValue(['pause', 'unpaid']);
      if (unpaidPause) {
        const endDate = moment.unix(form.getFieldValue('end')).subtract(unpaidPause, 'seconds');
        form.setFieldsValue({
          end_time: endDate.format('HH:mm'),
        });
        changeEndDate({
          hour: endDate.hour(),
          minute: endDate.minute(),
        });
      }
    }
  };

  const onUserSave = (user: IUser) => {
    const usersCopy = [...users];
    usersCopy.push(user);
    const usersCopySorted = usersCopy.sort((a, b) => {
      if (a.lastname) {
        if (b.lastname) {
          if (a.lastname.toLowerCase() < b.lastname.toLowerCase()) {
            return -1;
          }
          if (a.lastname.toLowerCase() > b.lastname.toLowerCase()) {
            return 1;
          }
          return 0;
        }
        return 1;
      }
      return 0;
    });

    appContextDispatch({
      type: 'SET_USERS',
      payload: usersCopySorted,
    });
    onUsersParamsChange();
    form.setFieldsValue({
      userRecordId: user.recordId,
    });
  };

  const onSkillSave = (skill: ISkill) => {
    const skillsCopy = [...skills];
    skillsCopy.push(skill);
    const skillsCopySorted = skillsCopy.sort((a, b) => {
      if (a.name!.toLowerCase() < b.name!.toLowerCase()) {
        return -1;
      }
      if (a.name!.toLowerCase() > b.name!.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    appContextDispatch({
      type: 'SET_SKILLS',
      payload: skillsCopySorted,
    });
    onUsersParamsChange();
    const selectedSkills = form.getFieldValue('skills');
    form.setFieldsValue({
      skills: [...selectedSkills, skill.id],
    });
  };

  const onTaskSave = (task: ITask) => {
    const tasksCopy = [...tasks];
    tasksCopy.push(task);
    const tasksCopySorted = tasksCopy.sort((a, b) => {
      if (a.name!.toLowerCase() < b.name!.toLowerCase()) {
        return -1;
      }
      if (a.name!.toLowerCase() > b.name!.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    appContextDispatch({
      type: 'SET_TASKS',
      payload: tasksCopySorted,
    });
  };

  const onWantToDeleteShift = () => {
    setVisible(false);
    hoursDispatch({
      type: 'SET_SHIFT_IDS_TO_DELETE',
      payload: [shift!.id!],
    });
  };

  const onChangeDayoffType = (value: SelectValue) => {
    const dayoff = dayoffs.find((x) => x.id == value);
    if (dayoff) {
      form.setFieldsValue({
        hide: dayoff.not_counted,
      });
    }
  };

  return (
    <Drawer
      getContainer={false}
      forceRender={true}
      maskClosable={false}
      title={shift && shift.id ? t('SHIFTS.UPDATE_SHIFT') : t('SHIFTS.SHIFT_CREATION')}
      placement="right"
      visible={visible}
      destroyOnClose={true}
      onClose={() => {
        hoursDispatch({
          type: 'SET_SELECTED_SHIFT',
          payload: null,
        });
        setVisible(false);
      }}
      className={className}
      width={windowSize.innerWidth > 900 ? 530 : '75%'}
    >
      <ScrollToTop />
      <Form
        form={form}
        layout="vertical"
        size="large"
        onFinish={onFinish}
        initialValues={initialValues}
        scrollToFirstError={true}
        onValuesChange={onValuesChange}
        style={{ paddingBottom: constraintsHeight + 20 }}
      >
        <Form.Item name="type">
          <Radio.Group buttonStyle="solid" className="light block" onChange={onShiftTypeChange}>
            <Radio.Button value="shift">{t('GLOBAL.SHIFT')}</Radio.Button>
            {form.getFieldValue('userRecordId') !== 'open_shift' && (
              <Radio.Button value="absence">{t('GLOBAL.ABSENCE')}</Radio.Button>
            )}
          </Radio.Group>
        </Form.Item>
        {shiftType === 'shift' && (
          <React.Fragment>
            {isFeatureEnabled(features, FEATURES.SECTIONS) && !!sections?.length && (
              <Form.Item
                label={t('GLOBAL.SECTION')}
                shouldUpdate={(prevValues, curValues) => prevValues.userRecordId !== curValues.userRecordId}
              >
                {(form) => {
                  const userRecordId = form.getFieldValue('userRecordId');
                  let filteredSections = [...sections];
                  if (userRecordId && userRecordId !== 'open_shift') {
                    const user = users.find((user) => `${user.recordId}` === userRecordId);
                    if (user) {
                      filteredSections = [...user.sections];
                    }
                  }
                  return (
                    <Form.Item name="section" style={{ marginBottom: 0 }}>
                      <Select
                        getPopupContainer={(trigger) => trigger}
                        showSearch
                        placeholder={t('FORMS.SECTION_PLACEHOLDER')}
                        optionFilterProp="children"
                        loading={loadingSections || loadingUsers}
                        disabled={loadingSections || loadingUsers}
                        allowClear={true}
                        filterOption={(input, option) =>
                          option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option value={''}>{t('GLOBAL.NO_SECTION')}</Option>
                        {filteredSections.map((section) => (
                          <Option key={`section_${section.id}`} value={`${section.id}`}>
                            {section.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            )}
            <div style={{ display: 'flex' }}>
              <Form.Item
                label={t('GLOBAL.USER')}
                name="userRecordId"
                style={{ display: 'inline-block', flex: 1 }}
                rules={[{ required: isFeatureEnabled(features, FEATURES.FREESHIFTS) ? false : true }]}
              >
                <Select
                  allowClear={true}
                  getPopupContainer={(trigger) => trigger}
                  showSearch
                  placeholder={t('FORMS.USER_PLACEHOLDER')}
                  optionFilterProp="children"
                  loading={loadingUsers}
                  disabled={loadingUsers}
                  optionLabelProp="label"
                  filterOption={(input, option) => {
                    if (option && option.title) {
                      return (option.title! as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    return false;
                  }}
                >
                  {isFeatureEnabled(features, FEATURES.FREESHIFTS) && (
                    <Option value="open_shift" label={t('GLOBAL.FREE_SHIFT')}>
                      {t('GLOBAL.FREE_SHIFT')}
                    </Option>
                  )}
                  {users.map((user) => (
                    <Option
                      key={`user_${user.recordId}`}
                      value={`${user.recordId}`}
                      label={<UserSelectInfos user={user} displayName />}
                      disabled={
                        !!user?.constraints?.message?.length &&
                        user?.constraints?.message?.some((c) => c.type === 'not_available')
                      }
                      title={user.displayName}
                    >
                      <div className="select-user-option">
                        <div className="displayName">{user.displayName}</div>
                        <UserSelectInfos user={user} />
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {!shift?.id && (
                <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.section !== curValues.section} noStyle>
                  {(form) => {
                    const userRecordId = form.getFieldValue('userRecordId');
                    if (
                      (!userRecordId && isFeatureEnabled(features, FEATURES.FREESHIFTS)) ||
                      userRecordId === 'open_shift'
                    ) {
                      return (
                        <Form.Item
                          label={t('SHIFTS.NUMBER_OF_SHIFTS')}
                          name="count"
                          style={{ display: 'inline-block', marginLeft: 10, maxWidth: 60 }}
                        >
                          <InputNumber type="number" min={1} step={1} precision={0} style={{ width: 60 }} />
                        </Form.Item>
                      );
                    }
                  }}
                </Form.Item>
              )}
            </div>
            {/* <button
              type="button"
              className="green"
              style={{ marginTop: 8, marginBottom: 15, display: 'block' }}
              onClick={() => setDrawerUserIsVisible(!drawerUserIsVisible)}
            >
              + {t('USERS.CREATE_NEW_USER')}
            </button> */}
            {isFeatureEnabled(features, FEATURES.SKILLS) && !!skills?.length && (
              <Form.Item
                label={t('GLOBAL.SKILLS')}
                shouldUpdate={(prevValues, curValues) => prevValues.userRecordId !== curValues.userRecordId}
              >
                {(form) => {
                  const userRecordId = form.getFieldValue('userRecordId');
                  let filteredSkills = [...skills];
                  if (userRecordId && userRecordId !== 'open_shift') {
                    const user = users.find((user) => `${user.recordId}` === userRecordId);
                    if (user) {
                      filteredSkills = [...user.skills];
                    }
                  }
                  return (
                    <Form.Item name="skills" style={{ marginBottom: 0 }}>
                      <Select
                        id="shift_skills"
                        getPopupContainer={(trigger) => trigger}
                        showSearch
                        allowClear
                        mode="multiple"
                        maxTagCount={3}
                        placeholder={t('FORMS.SKILLS_PLACEHOLDER')}
                        optionFilterProp="children"
                        disabled={loadingSkills || loadingUsers}
                        loading={loadingSkills || loadingUsers}
                        filterOption={(input, option) =>
                          option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {filteredSkills.map((skill: any) => (
                          <Option value={skill.id!} key={`skill_${skill.id}`}>
                            {skill.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            )}
            {!!isFeatureEnabled(features, FEATURES.ATTRIBUTES) && !!resources?.length && (
              <Form.Item name="attributes" label={t('GLOBAL.ATTRIBUTES')}>
                <Select
                  mode="multiple"
                  placeholder={t('FORMS.ATTRIBUTES_PLACEHOLDER')}
                  maxTagCount={3}
                  suffixIcon={<i className="icon-search" />}
                  disabled={loadingResources}
                  loading={loadingResources}
                  filterOption={(input, option) => {
                    if (option) {
                      if (!option.label) {
                        let children = option.children;
                        if (Array.isArray(children)) {
                          children = children.join();
                        }
                        return valueForSearch(children).includes(valueForSearch(input));
                      }
                    }
                    return false;
                  }}
                >
                  {resources.map((resource = {}) => (
                    <OptGroup label={resource.name} key={`resource_${resource.id}`}>
                      {(resource.attributes || []).map((attribut) => (
                        <Option value={attribut.id!} key={`attribut_${attribut.id}`}>
                          {attribut.name}
                        </Option>
                      ))}
                    </OptGroup>
                  ))}
                </Select>
              </Form.Item>
            )}
            {!shift && picker == 'month' && (
              <Form.Item label={t('GLOBAL.DATE')} name="date">
                <DatePicker
                  style={{ width: '100%' }}
                  defaultValue={moment()}
                  disabledDate={(date) => moment().month() !== date.month()}
                  onChange={(value) => {
                    setMonthDate(value);
                  }}
                />
              </Form.Item>
            )}
            <Form.Item
              style={{ marginBottom: 0 }}
              shouldUpdate={(prevValues, curValues) => prevValues.userRecordId !== curValues.userRecordId}
            >
              {(form) => {
                const userRecordId = form.getFieldValue('userRecordId');
                const user = userRecordId && users.find((user) => `${user.recordId}` === userRecordId);
                if (!!user?.package) {
                  if (shift && shift.id) {
                    return (
                      <Form.Item label={t('GLOBAL.PACKAGES')} name="packageId">
                        <Select
                          getPopupContainer={(trigger) => trigger}
                          showSearch
                          placeholder={t('FORMS.PACKAGE_PLACEHOLDER')}
                          optionFilterProp="children"
                          loading={loadingPackages}
                          disabled={loadingPackages}
                          filterOption={(input, option) =>
                            option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {packages.map((pack) => (
                            <Option key={`package_${pack.id}`} value={`${pack.id}`}>
                              {pack.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    );
                  } else {
                    return (
                      <Spin spinning={loadingPackages}>
                        <Form.Item label={t('GLOBAL.PACKAGES')} name="packageIds" rules={[{ required: true }]}>
                          <Select
                            mode="multiple"
                            getPopupContainer={(trigger) => trigger}
                            showSearch
                            placeholder={t('FORMS.PACKAGE_PLACEHOLDER')}
                            optionFilterProp="children"
                            loading={loadingPackages}
                            disabled={loadingPackages}
                            filterOption={(input, option) =>
                              option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {packages.map((pack) => (
                              <Option key={`package_${pack.id}`} value={`${pack.id}`}>
                                {pack.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Spin>
                    );
                  }
                } else {
                  return (
                    <React.Fragment>
                      <Form.Item name="start" className="hidden">
                        <InputNumber disabled={true} />
                      </Form.Item>
                      <TimePickerFormItem
                        rules={[{ required: true, message: '' }]}
                        name="start_time"
                        label={t('GLOBAL.START')}
                        start={picker == 'month' ? monthDate.unix() : shiftDay?.unix()}
                        style={{ display: 'inline-block', width: '75px', marginRight: '10px' }}
                        changeTime={changeStartDate}
                        show24
                      />
                      <Form.Item name="end" className="hidden">
                        <InputNumber disabled={true} />
                      </Form.Item>
                      <TimePickerFormItem
                        rules={[{ required: true, message: '' }]}
                        name="end_time"
                        label={t('GLOBAL.END')}
                        displayDuration={true}
                        start={newStart || shiftDay?.unix()}
                        style={{ display: 'inline-block', width: '75px', marginRight: '10px' }}
                        changeTime={changeEndDate}
                      />
                      {shortcuts.length > 0 && (
                        <Form.Item label={t('GLOBAL.SHORTCUTS')} style={{ display: 'inline-block', width: '200px' }}>
                          <Select
                            getPopupContainer={(trigger) => trigger}
                            showSearch
                            placeholder={t('GLOBAL.SHORTCUTS')}
                            optionFilterProp="children"
                            loading={loadingShortcuts}
                            disabled={loadingShortcuts}
                            filterOption={(input, option) =>
                              option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={changeShortcut}
                          >
                            {shortcuts.map((shortcut) => (
                              <Option key={`shortcut_${shortcut.id}`} value={`${shortcut.id}`}>
                                {shortcut.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                      <br />
                      <Form.Item name={['pause', 'paid']} className="hidden">
                        <InputNumber disabled={true} />
                      </Form.Item>
                      <TimePickerFormItem
                        name={['pause', 'paid_time']}
                        label={t('GLOBAL.PAID_BREAK')}
                        style={{ display: 'inline-block', width: '150px', marginRight: '10px' }}
                        maxHours={7}
                        changeTime={changePaidPauseDate}
                      />
                      <Form.Item name={['pause', 'unpaid']} className="hidden">
                        <InputNumber disabled={true} />
                      </Form.Item>
                      <TimePickerFormItem
                        name={['pause', 'unpaid_time']}
                        label={t('GLOBAL.UNPAID_BREAK')}
                        style={{ display: 'inline-block', width: '150px', marginRight: '10px' }}
                        maxHours={7}
                        changeTime={changeUnpaidPauseDate}
                      />
                      {hrPartners.length > 0 && activeDepartment?.hr_codes && activeDepartment?.hr_codes.length > 0 && (
                        <Form.Item name="hr_code" label={t('GLOBAL.HR_CODE')}>
                          <Select
                            getPopupContainer={(trigger) => trigger}
                            showSearch
                            placeholder={t('GLOBAL.HR_CODE')}
                            optionFilterProp="children"
                            loading={loadingHrPartners}
                            filterOption={(input, option) =>
                              option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            allowClear
                          >
                            {activeDepartment?.hr_codes.map((hr_code) => (
                              <Option key={hr_code.id} value={`${hr_code.id}`}>
                                {hr_code.description}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                      <Form.Item name="hide" valuePropName="checked">
                        <Checkbox>
                          <span style={{ display: 'flex', alignItems: 'center' }}>{t('SHIFTS.NOT_COUNTED')}</span>
                        </Checkbox>
                      </Form.Item>
                      {isFeatureEnabled(features, FEATURES.TASKS) && !!tasks?.length && (
                        <React.Fragment>
                          <Divider orientation="left" plain>
                            {t('GLOBAL.TASKS')}
                          </Divider>
                          <Form.List name="tasks">
                            {(taskItems, { add, remove }) => {
                              return (
                                <div>
                                  {taskItems.map((taskItem) => {
                                    return (
                                      <div className="form-list" key={taskItem.key}>
                                        <Form.Item
                                          {...taskItem}
                                          name={[taskItem.name, 'taskTypeId']}
                                          style={{ display: 'inline-block', flex: '1', marginRight: '10px' }}
                                          fieldKey={[taskItem.fieldKey, 'taskTypeId']}
                                          rules={[{ required: true, message: '' }]}
                                        >
                                          <Select
                                            allowClear={true}
                                            getPopupContainer={(trigger) => trigger}
                                            showSearch
                                            placeholder={t('GLOBAL.TASK')}
                                            optionFilterProp="children"
                                            disabled={loadingTasks}
                                            loading={loadingTasks}
                                            filterOption={(input, option) =>
                                              option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                          >
                                            {(tasks || []).map((task) => (
                                              <Option key={`task_${task.id}`} value={task.id!}>
                                                {task.name}
                                              </Option>
                                            ))}
                                          </Select>
                                        </Form.Item>
                                        <Form.Item
                                          name={[taskItem.name, 'start']}
                                          fieldKey={[taskItem.fieldKey, 'start']}
                                          className="hidden"
                                        >
                                          <InputNumber disabled={true} />
                                        </Form.Item>
                                        <TimePickerFormItem
                                          placeholder={t('GLOBAL.START')}
                                          rules={[
                                            {
                                              required: true,
                                              message: '',
                                              validator: (_rule, value) => {
                                                if (!value) {
                                                  return Promise.reject();
                                                }
                                                const startShift = form.getFieldValue('start');
                                                const startTask = form.getFieldValue(['tasks', taskItem.name, 'start']);
                                                if (startTask < startShift) {
                                                  return Promise.reject();
                                                } else {
                                                  return Promise.resolve(value);
                                                }
                                              },
                                            },
                                          ]}
                                          name={[taskItem.name, 'start_time']}
                                          fieldKey={[taskItem.fieldKey, 'start_time']}
                                          start={lastTaskEndTimestamp || newStart || shiftDay?.unix()}
                                          style={{ display: 'inline-block', width: '75px', marginRight: '10px' }}
                                          changeTime={({ hour, minute }) =>
                                            changeTaskDate({ hour, minute, name: `start`, index: taskItem.name })
                                          }
                                        />
                                        <Form.Item
                                          name={[taskItem.name, 'end']}
                                          fieldKey={[taskItem.fieldKey, 'end']}
                                          className="hidden"
                                        >
                                          <InputNumber disabled={true} />
                                        </Form.Item>
                                        <TimePickerFormItem
                                          placeholder={t('GLOBAL.END')}
                                          rules={[
                                            {
                                              required: true,
                                              message: '',
                                              validator: (_rule, value) => {
                                                if (!value) {
                                                  return Promise.reject();
                                                }
                                                const endShift = form.getFieldValue('end');
                                                const endTask = form.getFieldValue(['tasks', taskItem.name, 'end']);
                                                if (endTask > endShift) {
                                                  return Promise.reject();
                                                } else {
                                                  return Promise.resolve(value);
                                                }
                                              },
                                            },
                                          ]}
                                          name={[taskItem.name, 'end_time']}
                                          fieldKey={[taskItem.fieldKey, 'end_time']}
                                          displayDuration={true}
                                          start={tasksStart[taskItem.fieldKey] || newStart || shiftDay?.unix()}
                                          style={{ display: 'inline-block', width: '75px', marginRight: '10px' }}
                                          changeTime={({ hour, minute }) =>
                                            changeTaskDate({ hour, minute, name: `end`, index: taskItem.name })
                                          }
                                        />
                                        <MinusCircleOutlined
                                          onClick={() => {
                                            remove(taskItem.name);
                                            const tasks = form.getFieldValue('tasks');
                                            const _lastTaskEndTimestamp = Math.max(
                                              ...(tasks?.map((task: { end?: number }) => task?.end || 0) || [0]),
                                              0,
                                            );
                                            setLastTaskEndTimestamp(_lastTaskEndTimestamp);
                                          }}
                                        />
                                      </div>
                                    );
                                  })}
                                  <Form.Item>
                                    <Button
                                      type="dashed"
                                      onClick={() => {
                                        add();
                                      }}
                                      block
                                    >
                                      <PlusOutlined /> {t('TASKS.ADD_TASK')}
                                    </Button>
                                  </Form.Item>
                                </div>
                              );
                            }}
                          </Form.List>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  );
                }
              }}
            </Form.Item>
            <Form.Item label={t('GLOBAL.COMMENT')} name="comment">
              <TextArea rows={4} />
            </Form.Item>
          </React.Fragment>
        )}
        {shiftType === 'absence' && (
          <React.Fragment>
            <Form.Item
              name={['dayoff', 'dayOffType']}
              label={t('FORMS.ABSENCE_TYPE')}
              rules={[{ required: true, message: '' }]}
            >
              <Select
                getPopupContainer={(trigger) => trigger}
                showSearch
                placeholder={t('FORMS.ABSENCE_TYPE_PLACEHOLDER')}
                optionFilterProp="children"
                loading={loadingDayoffs}
                disabled={loadingDayoffs}
                filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={onChangeDayoffType}
              >
                {dayoffs.map((dayoff) => (
                  <Option value={dayoff.id!} key={`dayoff_${dayoff.id}`}>
                    {dayoff.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('GLOBAL.USER')} name="userRecordId">
              <Select
                allowClear={true}
                getPopupContainer={(trigger) => trigger}
                showSearch
                placeholder={t('FORMS.USER_PLACEHOLDER')}
                optionFilterProp="children"
                loading={loadingUsers}
                disabled={loadingUsers}
                optionLabelProp="label"
                filterOption={(input, option) => {
                  if (option && option.label) {
                    return (option!.label! as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }
                  return false;
                }}
              >
                <Option value="open_shift" label={t('GLOBAL.FREE_SHIFT')}>
                  {t('GLOBAL.FREE_SHIFT')}
                </Option>
                {users.map((user) => (
                  <Option
                    key={`user_${user.recordId}`}
                    value={`${user.recordId}`}
                    label={user.displayName}
                    disabled={
                      !!user?.constraints?.message?.length &&
                      user?.constraints?.message?.some((c) => c.type === 'not_available')
                    }
                  >
                    <div className="select-user-option">
                      <div className="displayName">{user.displayName}</div>
                      <div className="infos">
                        {!!user?.constraints?.message?.length && (
                          <span>{t(`SCHEDULE.CONSTRAINTS.${user?.constraints?.message[0]?.title}_SHORT`)}</span>
                        )}
                        {(!!user.totalWeekMinutes || !!user.maxWeekMinutes) && (
                          <span
                            style={{
                              backgroundColor:
                                user.maxWeekMinutes &&
                                user.totalWeekMinutes &&
                                user.totalWeekMinutes > user.maxWeekMinutes
                                  ? colors.red
                                  : colors.green,
                            }}
                          >
                            {minutesToHours(user.totalWeekMinutes || 0)}
                            {user.maxWeekMinutes ? `/${minutesToHours(user.maxWeekMinutes)}` : ''}h
                          </span>
                        )}
                        {(!!user.totalTrimesterMinutes || !!user.maxTrimesterMinutes) && (
                          <span
                            style={{
                              backgroundColor:
                                user.maxTrimesterMinutes &&
                                user.totalTrimesterMinutes &&
                                user.totalTrimesterMinutes > user.maxTrimesterMinutes
                                  ? colors.red
                                  : colors.green,
                            }}
                          >
                            {minutesToHours(user.totalTrimesterMinutes || 0)}
                            {user.maxTrimesterMinutes ? `/${user.maxTrimesterMinutes}` : ''}m
                          </span>
                        )}
                      </div>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0, display: 'inline-block' }}>
              <Form.Item
                name={['dayoff', 'fullDay']}
                valuePropName="checked"
                style={{ marginBottom: 0, display: 'inline-block' }}
              >
                <Checkbox style={{ lineHeight: '32px' }}>{t('GLOBAL.FULL_DAY')}</Checkbox>
              </Form.Item>
              <Form.Item
                name={['dayoff', 'paid']}
                valuePropName="checked"
                style={{ marginBottom: 0, display: 'inline-block' }}
              >
                <Checkbox style={{ lineHeight: '32px' }}>{t('GLOBAL.PAID_ABSENCE')}</Checkbox>
              </Form.Item>
            </Form.Item>
            <Form.Item className="form-item-should-update" style={{ marginBottom: 0 }}>
              <Form.Item name="start" className="hidden">
                <InputNumber disabled={true} />
              </Form.Item>
              <TimePickerFormItem
                rules={[{ required: true, message: '' }]}
                name="start_time"
                label={t('GLOBAL.START')}
                start={shiftDay?.unix()}
                style={{ display: 'inline-block', width: '75px', marginRight: '10px' }}
                changeTime={changeStartDate}
              />
              <Form.Item name="end" className="hidden">
                <InputNumber disabled={true} />
              </Form.Item>
              <TimePickerFormItem
                rules={[{ required: true, message: '' }]}
                name="end_time"
                label={t('GLOBAL.END')}
                displayDuration={true}
                start={newStart || shiftDay?.unix()}
                style={{ display: 'inline-block', width: '75px', marginRight: '10px' }}
                changeTime={changeEndDate}
              />
              <Form.Item
                label={t('GLOBAL.SHORTCUTS')}
                style={{ display: 'inline-block', width: '200px' }}
                shouldUpdate={(prevValues, curValues) => prevValues.section !== curValues.section}
              >
                {(form) => {
                  const section = activeSection || form.getFieldValue('section');
                  let filteredShortcuts: IShortcut[];
                  if (section) {
                    filteredShortcuts = shortcuts.filter((x) => x.sectionId === section);
                  } else {
                    filteredShortcuts = shortcuts;
                  }
                  return (
                    <Form.Item>
                      <Select
                        getPopupContainer={(trigger) => trigger}
                        showSearch
                        placeholder={t('GLOBAL.SHORTCUTS')}
                        optionFilterProp="children"
                        loading={loadingShortcuts}
                        disabled={loadingShortcuts}
                        filterOption={(input, option) =>
                          option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={changeShortcut}
                      >
                        {filteredShortcuts.map((shortcut) => (
                          <Option key={`shortcut_${shortcut.id}`} value={`${shortcut.id}`}>
                            {shortcut.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Form.Item>
            <Form.Item name="hide" valuePropName="checked">
              <Checkbox>
                <span style={{ display: 'flex', alignItems: 'center' }}>{t('DAYOFFS.NOT_COUNTED')}</span>
              </Checkbox>
            </Form.Item>
            <Form.Item label={t('GLOBAL.COMMENT')} name="comment">
              <TextArea rows={4} />
            </Form.Item>
            {shift?.id && !shift.dayoff && (
              <Form.Item name="autoApprove" valuePropName="checked" style={{ marginBottom: 0 }}>
                <Checkbox>{t('SHIFTS.APPROVE_THIS_SHIFT')}</Checkbox>
              </Form.Item>
            )}
          </React.Fragment>
        )}
        {!shift?.id && (
          <React.Fragment>
            <Divider orientation="left" plain>
              {t('SHIFTS.MODAL_SHIFT_RECURRENCE.TITLE')}
            </Divider>
            {!recurrence && (
              <Button type="dashed" onClick={() => setModalShiftRecurrenceIsVisible(true)} style={{ width: '100%' }}>
                <PlusOutlined />
                {t('SHIFTS.MODAL_SHIFT_RECURRENCE.ADD_RECURRENCE')}
              </Button>
            )}
            {!!recurrence && (
              <div className="recurrence-container" onClick={() => setModalShiftRecurrenceIsVisible(true)}>
                {recurrence?.repeatOn === 'WEEK' && (
                  <span>
                    {t('SHIFTS.MODAL_SHIFT_RECURRENCE.WEEKLY_ON', {
                      weekdays: recurrence?.days
                        ?.sort()
                        ?.map((day: number) => moment.weekdays(true)[day - 1])
                        ?.join(', '),
                    })}
                  </span>
                )}
                {recurrence?.repeatOn === 'MONTH' && (
                  <span>
                    {recurrence?.day?.split('_')?.length > 1
                      ? t('SHIFTS.MODAL_SHIFT_RECURRENCE.MONTHLY_ON_ORDINAL', {
                          ordinal: t(`GLOBAL.${ORDINAL_NUMBERS[recurrence?.day?.split('_')[0]]}`),
                          weekday: moment.weekdays(true)[recurrence?.day?.split('_')[1] - 1],
                        })
                      : t('SHIFTS.MODAL_SHIFT_RECURRENCE.MONTHLY_ON_DATE', { number: recurrence?.day })}
                  </span>
                )}
                {recurrence?.repeatEnd === 'OCCURRENCE' && (
                  <span>
                    , {recurrence?.occurrenceCounter}{' '}
                    {t('SHIFTS.MODAL_SHIFT_RECURRENCE.OCCURRENCE(S)').toLocaleLowerCase()}
                  </span>
                )}
                {recurrence?.repeatEnd === 'DATE' && (
                  <span>, {t('SHIFTS.MODAL_SHIFT_RECURRENCE.UNTIL_DATE', { date: recurrence?.end?.format('L') })}</span>
                )}
                <CloseOutlined
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setRecurrence(null);
                  }}
                />
              </div>
            )}
          </React.Fragment>
        )}
        {shift?.recurring && (
          <React.Fragment>
            <Divider orientation="left" plain>
              {t('SHIFTS.MODAL_SHIFT_RECURRENCE.TITLE')}
            </Divider>
            <Form.Item
              shouldUpdate={(prevValues, curValues) => prevValues.recurringDetach !== curValues.recurringDetach}
              noStyle
            >
              {(form) => {
                const recurringDetach = form.getFieldValue('recurringDetach');
                return (
                  <Form.Item name="recurringUpdate" valuePropName="checked" style={{ marginBottom: 0 }}>
                    <Checkbox disabled={recurringDetach}>{t('SHIFTS.UPDATE_NEXT_OCCURRENCES')}</Checkbox>
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Form.Item
              shouldUpdate={(prevValues, curValues) => prevValues.recurringUpdate !== curValues.recurringUpdate}
              noStyle
            >
              {(form) => {
                const recurringUpdate = form.getFieldValue('recurringUpdate');
                return (
                  <Form.Item name="recurringDetach" valuePropName="checked">
                    <Checkbox disabled={recurringUpdate}>{t('SHIFTS.DETACH_FROM_OCCURRENCES')}</Checkbox>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </React.Fragment>
        )}
        <div className="actions-container">
          <div className="infos">
            <ReactHeight onHeightReady={(height: number) => setConstraintsHeight(height)}>
              <Constraints message={activeUser?.constraints?.message || []} />
            </ReactHeight>
          </div>
          <div className="actions">
            <Button
              type="primary"
              danger
              onClick={() => {
                setVisible(false);
              }}
            >
              {t('GLOBAL.CANCEL')}
            </Button>
            {!!shift?.id && (
              <Button
                type="default"
                danger
                onClick={() => {
                  onWantToDeleteShift();
                }}
              >
                {t('GLOBAL.REMOVE')}
              </Button>
            )}
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              disabled={activeUser?.constraints?.message?.some((c) => c.type === 'not_available')}
            >
              {shift && shift.id ? t('SHIFTS.UPDATE_SHIFT') : t('SHIFTS.CREATE_SHIFT')}
            </Button>
          </div>
        </div>
      </Form>
      <DrawerUser
        user={null}
        visible={drawerUserIsVisible}
        onClose={() => setDrawerUserIsVisible(false)}
        onSave={onUserSave}
      />
      {isFeatureEnabled(features, FEATURES.SKILLS) && (
        <DrawerSkill
          skill={null}
          visible={drawerSkillIsVisible}
          userRecordId={activeUser?.recordId || undefined}
          onClose={() => setDrawerSkillIsVisible(false)}
          onSave={onSkillSave}
        />
      )}
      {isFeatureEnabled(features, FEATURES.TASKS) && (
        <DrawerTask
          task={null}
          visible={drawerTaskIsVisible}
          onClose={() => setDrawerTaskIsVisible(false)}
          onSave={onTaskSave}
        />
      )}
      <ModalShiftRecurrence
        visible={modalShiftRecurrenceIsVisible}
        recurrence={recurrence}
        setRecurrence={setRecurrence}
        shiftDay={shiftDay}
        onClose={() => setModalShiftRecurrenceIsVisible(false)}
      />
    </Drawer>
  );
};

const DrawerShiftStyled = styled(DrawerShift)`
  .recurrence-container {
    display: inline-block;
    background-color: ${colors.greyLight};
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;

    .anticon-close {
      background: ${colors.red};
      padding: 1px;
      border-radius: 10px;
      margin-left: 5px;
      svg {
        transform: scale(0.8);
        fill: white;
      }
    }
  }

  .hidden {
    display: none;
  }

  .ant-form-item-explain {
    display: none;
  }

  .form-list {
    display: flex;
    align-items: center;
    margin-bottom: 10px !important;

    .ant-row.ant-form-item {
      margin-bottom: 0;
    }
  }

  .ant-select-item.ant-select-item-option.not-compatible:not(.ant-select-item-option-selected) {
    background: ${Colors.greyLight};
    color: ${Colors.grey};
  }

  .form-item-should-update {
    .ant-form-item-control-input {
      min-height: 0;
    }

    .ant-row.ant-form-item.min-height {
      .ant-form-item-control-input {
        min-height: 40px;
      }
    }
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-active {
    .ant-select-item-option-content {
      .select-user-option {
        .infos  {
          opacity: 0.8;
        }
      }
    }
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
    .ant-select-item-option-content {
      .select-user-option {
        .infos  {
          opacity: 1;
        }
      }
    }
  }
  .ant-select-item-option-content .select-user-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .displayName {
    }
    .infos {
      opacity: 0.3;
      transition: opacity 0.3s;
      span {
        background-color: ${colors.bluePastel};
        color: white;
        border-radius: 3px;
        margin-left: 4px;
        padding: 1px 5px;
      }
    }
  }
`;

export default DrawerShiftStyled;

function removeUnnecessaryProperties(object: any): any {
  delete object.end_time;
  delete object.start_time;
  delete object.type;

  if (object.pause) {
    delete object.pause.paid_time;
    delete object.pause.unpaid_time;
  }

  const tasksLength = object.tasks?.length;
  if (tasksLength) {
    for (let i = 0; i < tasksLength; i++) {
      delete object.tasks[i].end_time;
      delete object.tasks[i].start_time;
    }
  }

  if (object.end && object.start) {
    if (object.end < object.start) {
      object.end = object.end + 86400;
    }
  }

  return object;
}

const initialValues = {
  section: null,
  type: 'shift',
  tasks: [],
  pause: {
    paid: 0,
    paid_time: null,
    unpaid: 0,
    unpaid_time: null,
  },
  dayoff: {
    paid: true,
  },
  count: 1,
};
