import AppContext from '@/pages/app/context';
import { ITurnoverTemplate } from '@/types/insights/turnover-template.model';
import { WEEKDAYS, createNumberRangeArray, handleError } from '@/utils';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Table, Upload } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ColumnsType } from 'antd/es/table/interface';
import { RcFile } from 'antd/es/upload/interface';
import axios from 'axios';
import { uniqueId } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import TurnoverTemplateBreadcrumb from './TurnoverTemplateBreadcrumb';

interface Props {
  className?: string;
  activeTemplate: ITurnoverTemplate | null;
}

const TurnoverTemplate: React.FC<Props> = ({ className, activeTemplate }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, activeDepartmentId, turnoverTemplates },
    dispatch,
  } = useContext(AppContext);
  const [form] = useForm();
  const [columns, setColumns] = useState<ColumnsType<any[]>>();
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string | null>(null);
  const [file, setFile] = useState<RcFile | null>(null);
  const history = useHistory();

  const hours = createNumberRangeArray(0, 23);

  useEffect(() => {
    let result: ColumnsType<any[]> = [];

    hours.forEach((hour, index) => {
      const fullHour = `${hour <= 9 ? `0${hour}` : hour}:00`;
      result.push({
        className: 'input-cell',
        key: uniqueId(),
        title: `${fullHour.split(':')[0]}${t('GLOBAL.HOURS_SHORT')}`,
        render(value, record, index) {
          return (
            <Form.Item name={['data', `${index}`, `${hour}`]} style={{ width: 50 }}>
              <InputNumber min={0} />
            </Form.Item>
          );
        },
      });
    });

    setColumns([
      ...[
        {
          key: 'day',
          dataIndex: 'day',
        },
      ],
      ...result,
    ]);
  }, [activeKey, turnoverTemplates]);

  useEffect(() => {
    const data = WEEKDAYS.map((day) => {
      return {
        id: day,
        day: t(`GLOBAL.${day.toUpperCase()}`),
      };
    });
    setData(data);
  }, []);

  useEffect(() => {
    if (!activeTemplate) return;

    form.setFieldsValue({
      ...activeTemplate,
    });

    activeTemplate.turnovers.forEach(({ day, hours }) => {
      hours.forEach(({ hour, provisional }) => {
        const dayNumber = day - 2 < 0 ? day - 2 + 7 : day - 2;
        form.setFieldsValue({
          data: {
            [`${dayNumber}`]: {
              [`${hour}`]: provisional,
            },
          },
        });
      });
    });
  }, [activeTemplate]);

  const onFinish = (values: any) => {
    if (!activeTemplate) return;

    if (file) {
      uploadFile(values);
      return;
    }

    Object.keys(values.data).forEach((key) => {
      const nestedObj = values.data[key];
      const dayNumber = Number(key) + 2 > 7 ? (Number(key) + 2) % 7 : Number(key) + 2;
      Object.keys(nestedObj).forEach((nestedKey) => {
        const found = activeTemplate.turnovers.find((element) => element.day == dayNumber);
        if (found) {
          const hour = found.hours.find((hour) => hour.hour == Number(nestedKey));
          if (hour) {
            if (nestedObj[nestedKey] == hour.provisional) {
              delete nestedObj[nestedKey];
            }
            if (Object.values(nestedObj).length == 0) {
              delete values.data[key];
            }
          }
        }
      });
    });

    const data = {
      name: values.name,
      turnovers: Object.entries(values.data).map(([index, hours]) => ({
        day: Number(index) + 2 > 7 ? (Number(index) + 2) % 7 : Number(index) + 2,
        hours: Object.entries(Object(hours)).map(([hour, value]) => ({
          hour,
          provisional: value,
        })),
      })),
    };
    setLoading(true);
    editTemplate(data);
  };

  const uploadFile = (file: RcFile) => {
    if (!activeTemplate) return;
    setLoading(true);
    const formData = new FormData();
    formData.append('name', form.getFieldValue('name'));
    formData.append('file', file);

    axios({
      url: `${process.env.REACT_APP_API_URL}/v3/insights/turnover/provisional/${activeTemplate.id}/${
        file ? 'import' : ''
      }`,
      method: 'POST',
      data: formData,
      params: {
        departmentId: activeDepartmentId,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        dispatch({
          type: 'SET_TURNOVER_TEMPLATES',
          payload: [...turnoverTemplates, data.template],
        });
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
    return false;
  };

  const editTemplate = (values: any) => {
    if (!activeTemplate) return;
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/insights/turnover/provisional/${activeTemplate.id}`,
        {
          ...values,
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then(({ data }) => {
        history.push(`/app/settings/insights/turnover-templates`);
        dispatch({
          type: 'SET_TURNOVER_TEMPLATES',
          payload: [...turnoverTemplates.filter((template) => template.id !== data.template.id), { ...data.template }],
        });
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const beforeUpload = (file: RcFile) => {
    setFile(file);
    return false;
  };

  const onRemoveFile = () => {
    setFile(null);
  };

  return (
    <div className={className}>
      <TurnoverTemplateBreadcrumb activeTemplate={activeTemplate} />
      <div className="container">
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item className="name-container" name="name" label={t('GLOBAL.NAME')} rules={[{ required: true }]}>
            <Input placeholder={t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.TEMPLATE_NAME')} size="large" allowClear />
          </Form.Item>
          <Form.Item name="file" label={t('GLOBAL.FILE')}>
            <Upload
              beforeUpload={uploadFile}
              maxCount={1}
              multiple={false}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onRemove={onRemoveFile}
            >
              <Button icon={<UploadOutlined />} size="large">
                Click to import
              </Button>
            </Upload>
          </Form.Item>
          <Table columns={columns} dataSource={data} rowKey="id" pagination={false} className="table" />
          <Button
            className="submit-btn"
            htmlType="submit"
            size="large"
            type="primary"
            style={{ marginBottom: 16 }}
            loading={loading}
            disabled={loading}
          >
            {activeTemplate
              ? t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.SAVE_TEMPLATE')
              : t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.CREATE_TEMPLATE')}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default styled(TurnoverTemplate)`
  .container {
    margin-top: 25px;

    .input-cell {
      width: 50px;
      text-align: center;
      padding: 0;

      .ant-input-number {
        width: 100%;
      }

      .ant-input-number-handler-wrap {
        display: none;
      }

      .ant-input-number-input-wrap {
        input {
          text-align: center;
          padding: 0 !important;
        }
      }
    }

    .submit-btn {
      margin-top: 25px;
    }

    .table {
      .ant-table-thead {
        .ant-table-cell {
          padding: 10px 0;
        }
      }
      .ant-table-tbody {
        .ant-table-cell {
          padding: 5px;
        }
      }
      .ant-form-item {
        margin: 0;
      }
    }
  }
`;
