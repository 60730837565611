import NewBadge from '@/pages/app/components/NewBadge';
import AppContext from '@/pages/app/context';
import { IDepartment } from '@/types/department.model';
import { DIMONA_WORK_TYPES } from '@/types/dimona.model';
import { FEATURES } from '@/types/features.model';
import { IUserParams } from '@/types/user-params.model';
import { USER_ROLES } from '@/types/user-role.model';
import { IUser } from '@/types/user.model';
import { isClockingAccount, isFeatureEnabled } from '@/utils';
import { Checkbox, Col, Form, InputNumber, Row, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FormInstance } from 'antd/es/form/Form';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Option = Select.Option;

interface Props {
  className?: string;
  department?: IDepartment;
  params: IUserParams | null;
  user?: IUser;
  form?: FormInstance<any>;
  activeRole: string | null;
  saveAyValue: boolean;
  setSaveAyValue: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdditionalInformation: React.FC<Props> = ({
  className,
  form,
  user,
  params,
  department,
  activeRole,
  saveAyValue,
  setSaveAyValue,
}) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [checkboxes, setCheckboxes] = useState<any[]>([]);
  const {
    state: { features },
  } = useContext(AppContext);

  useEffect(() => {
    setCheckboxes([
      ...(isFeatureEnabled(features, FEATURES.SCHEDULE)
        ? [{ name: ['params', 'show_coworkers'], title: t('USERS.USER.SETTINGS.SHOW_COWORKER_SCHEDULES') }]
        : []),
      ...(!isClockingAccount(features) &&
      ['ADMIN'].includes(department?.role || '') &&
      department?.enablePackages &&
      !saveAyValue
        ? [{ name: ['params', 'package'], title: t('USERS.USER.SETTINGS.PACKAGE') }]
        : []),
      ...(isFeatureEnabled(features, FEATURES.DIMONA) && !saveAyValue
        ? [{ name: ['params', 'dimona'], title: t('USERS.USER.SETTINGS.DIMONA') }]
        : []),
      ...(isFeatureEnabled(features, FEATURES.FREESHIFTS) && !saveAyValue
        ? [{ name: ['params', 'freeshifts'], title: t('USERS.USER.SETTINGS.FREE_SHIFT') }]
        : []),
      ...(!isClockingAccount(features) && department?.params?.enableAvailability && !saveAyValue
        ? [{ name: ['params', 'enable_availability'], title: t('USERS.USER.SETTINGS.AVAILABILITY') }]
        : []),
      ...(isFeatureEnabled(features, FEATURES.LEAVE_MANAGEMENT) && department?.vacationRequests && !saveAyValue
        ? [{ name: ['params', 'vacation_requests'], title: t('SETTINGS.ACCOUNT.GENERAL.VACATION_REQUESTS') }]
        : []),
      ...(department?.homeworking && params?.params && !saveAyValue
        ? [{ name: ['params', 'homeworking'], title: t('SETTINGS.ACCOUNT.GENERAL.HOMEWORKING') }]
        : []),
      ...(isFeatureEnabled(features, FEATURES.SCHEDULE)
        ? [{ name: ['params', 'global_schedule'], title: t('USERS.USER.SETTINGS.GLOBAL_SCHEDULE') }]
        : []),
      ...(department?.params?.showExtraHours !== 0 && params?.params && !saveAyValue
        ? [{ name: ['params', 'display_extra_hours'], title: t('SETTINGS.ACCOUNT.GENERAL.SHOW_OVERTIME') }]
        : []),
      { name: ['params', 'contact'], title: t('USERS.CONTACT_PERSON') },
      ...(isFeatureEnabled(features, FEATURES.DIMONA) &&
      department?.params?.dimonaShyftClockingCorrection &&
      !saveAyValue
        ? [
            {
              name: ['params', 'dimona_shyft_clocking_correction'],
              title: t('USERS.USER.SETTINGS.USE_CLOCKING_DATA_IN_DIMONA'),
            },
          ]
        : []),
      ...(isFeatureEnabled(features, FEATURES.DIMONA) &&
      department?.params?.dimona_free_clocking_registration_department &&
      !saveAyValue
        ? [
            {
              name: ['params', 'dimona_free_clocking_registration'],
              title: t('USERS.USER.SETTINGS.CREATE_DIMONA_FROM_FREE_CLOCKING'),
            },
          ]
        : []),
      ...(department?.scheduleParams?.swap_shift_enabled && isFeatureEnabled(features, FEATURES.SCHEDULE)
        ? [{ name: ['params', 'swap_shift'], title: t('USERS.ALLOW_SWAP_SHIFT') }]
        : []),
      ...(department?.params?.dimona_free_clocking_registration && params?.params && !saveAyValue
        ? [{ name: ['params', 'dimona_free_clocking_registration'], title: t('USERS.AUTO_CLOCKING_DIMONA') }]
        : []),
      ...(isFeatureEnabled(features, FEATURES.CLOCKING) &&
      params?.access?.clockings &&
      (activeRole === USER_ROLES.PLANNING || activeRole === USER_ROLES.HR) &&
      params?.params &&
      !saveAyValue
        ? [
            {
              name: ['params', 'allow_edit_own_clockings'],
              title: t('USERS.ALLOW_EDIT_OWN_CLOCKINGS'),
              disabled:
                (activeRole === USER_ROLES.PLANNING || activeRole === USER_ROLES.HR) && department?.role !== 'ADMIN',
            },
          ]
        : []),
      ...(department?.ay_core ? [{ name: ['params', 'ay_sync'], title: t('USERS.AY_SYNC') }] : []),
      ...(department?.clockinParams?.enableMobileClockin && isFeatureEnabled(features, FEATURES.CLOCKING)
        ? [{ name: ['params', 'mobile_access'], title: t('USERS.CAN_ACCESS_MOBILE'), newBadge: '2024-08-01' }]
        : []),
      ...(isFeatureEnabled(features, FEATURES.CLOCKING)
        ? [{ name: ['params', 'pos_access'], title: t('USERS.CAN_ACCESS_POS'), newBadge: '2024-08-01' }]
        : []),
    ]);
  }, [params, activeRole, saveAyValue]);

  const onHideDelayChange = (value: number) => {
    if (value == 0) {
      form?.setFieldsValue({
        params: {
          hides_delay: t('GLOBAL.NEVER'),
        },
      });
    }
  };

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.target && e.target.id == 'params_ay_sync') {
      setSaveAyValue(e.target.checked);
    }
  };

  return (
    <React.Fragment>
      <div className={className}>
        <h4>{t('USERS.USER.SETTINGS.ADDITIONAL_INFORMATION')}</h4>
        <Row style={{ margin: '10px 0 0' }}>
          {!!params?.params?.dimona && !department?.params?.automaticDimona && (
            <Col span={24}>
              <div style={{ fontSize: '0.9em', opacity: 0.65, marginBottom: 5 }}>{t('DIMONA.DIMONA_STATUS')}</div>
              <Form.Item name={['params', 'workerType']} className="one-line dimona-status">
                <Select
                  allowClear={true}
                  getPopupContainer={(trigger) => trigger}
                  placeholder={t('FORMS.WORKER_TYPE_PLACEHOLDER')}
                >
                  {DIMONA_WORK_TYPES.map((workType) => {
                    const { id } = workType;

                    return (
                      <Option key={`workType_${id}`} value={id!}>
                        {t(`DIMONA.WORKTYPES.${id}`)}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row>
          {checkboxes?.map(({ name, title, disabled, newBadge }) => (
            <Col key={name.join('.')} className="checkboxes">
              <Form.Item name={name} className="one-line" valuePropName="checked" style={{ margin: 0 }}>
                <Checkbox disabled={disabled} onChange={(e) => onCheckboxChange(e)}>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <NewBadge end={newBadge} noTooltip /> {title}
                  </span>
                </Checkbox>
              </Form.Item>
            </Col>
          ))}
        </Row>
        {isFeatureEnabled(features, FEATURES.SCHEDULE) && (
          <Row style={{ marginTop: 15 }}>
            <Col span={24}>
              <span style={{ fontSize: '0.9em', opacity: 0.65, marginBottom: 5 }}>{`${t(
                'USERS.USER.SETTINGS.SHIFT_DISPLAY_DELAY',
              )} :`}</span>
              <Form.Item name={['params', 'shift_delay']} className="one-line shift-delay" style={{ marginBottom: 10 }}>
                <Select getPopupContainer={(trigger) => trigger}>
                  <Option value={0}>{t('GLOBAL.NO_DELAY')}</Option>
                  <Option value={30}>30 min</Option>
                  <Option value={60}>1 {t('GLOBAL.HOUR').toLocaleLowerCase()}</Option>
                  <Option value={120}>2 {t('GLOBAL.HOURS').toLocaleLowerCase()}</Option>
                  <Option value={180}>3 {t('GLOBAL.HOURS').toLocaleLowerCase()}</Option>
                  <Option value={300}>5 {t('GLOBAL.HOURS').toLocaleLowerCase()}</Option>
                  <Option value={480}>8 {t('GLOBAL.HOURS').toLocaleLowerCase()}</Option>
                  <Option value={1440}>1 {t('GLOBAL.DAY').toLocaleLowerCase()}</Option>
                  <Option value={2880}>2 {t('GLOBAL.DAYS').toLocaleLowerCase()}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <span style={{ fontSize: '0.9em', opacity: 0.65, marginBottom: 5 }}>{`${t(
                'USERS.USER.SETTINGS.HIDE_SHIFT_DELAY',
              )}:`}</span>
              <Form.Item name={['params', 'hides_delay']} className="one-line">
                <InputNumber min={0} step={1} className="hide-delay" onChange={onHideDelayChange} />
              </Form.Item>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

export default styled(AdditionalInformation)`
  .ant-row.ant-form-item.one-line {
    .ant-col.ant-form-item-label {
      label {
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }

  .checkboxes {
    width: 50%;
  }

  .shift-delay,
  .hide-delay {
    width: 50%;
  }

  .dimona-status {
    width: 50%;
  }

  @media screen and (max-width: 900px) {
    .checkboxes {
      width: 100%;
    }

    .shift-delay,
    .hide-delay {
      width: 100%;
    }

    .dimona-status {
      width: 100%;
    }
  }
`;
