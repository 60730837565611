import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { ISection } from '@/types/section.model';
import colors from '@/styles/colors';

interface Props {
  className?: string;
  section?: ISection;
  hasValues: boolean;
  activeKey: string | null;
}

const SectionTab: React.FC<Props> = ({ className, section, hasValues, activeKey }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);

  return (
    <div className={className}>
      <span className="name" style={{ color: section && activeKey == section.id ? section.background : '#000' }}>
        {section ? section.name : t('GLOBAL.ALL')}
      </span>
      {!hasValues && <div className="no-values"></div>}
    </div>
  );
};

export default styled(SectionTab)`
  display: flex;
  align-items: center;
  gap: 10px;

  .color-bullet {
    width: 10px;
    height: 10px;
    border-radius: 50px;
  }

  .no-values {
    width: 7px;
    height: 7px;
    border-radius: 10px;
    background-color: ${colors.red};
    position: absolute;
    right: -7px;
    top: 7px;
  }
`;
