import AuthContext from '@/context';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  setupLoading: boolean;
  style?: any;
  return_url?: string;
}

const CheckoutForm: React.FC<Props> = ({ className, setupLoading, style = {}, return_url }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, departments },
    dispatch,
  } = useContext(AppContext);
  const { dispatch: authContextDispatch } = useContext(AuthContext);
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_APP_URL}${return_url || '/app/settings/billing/payment-details-new'}`,
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message || null);
    } else {
      setMessage('An unexpected error occured.');
    }

    setLoading(false);
  };

  return (
    <div style={{ width: '50%', backgroundColor: '#FFF', padding: 25, borderRadius: 8, ...style }}>
      {message && (
        <div id="payment-message" style={{ marginBottom: 15, color: colors.red, fontSize: 14 }}>
          {message}
        </div>
      )}
      {!setupLoading && (
        <>
          <PaymentElement />
          <Button type="primary" block size="large" style={{ marginTop: 25 }} onClick={onClick}>
            {loading ? t('GLOBAL.LOADING') : t('SETTINGS.BILLING.PAYMENT_DETAILS.ADD_PAYMENT_METHOD')}
          </Button>
        </>
      )}
    </div>
  );
};

export default styled(CheckoutForm)``;
