import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { IScheduleClocking } from '@/types/schedule-clocking.model';
import { Avatar } from 'antd';
import { WEEKDAYS } from '@/utils';
import { IUser } from '@/types/user.model';
import moment, { Moment } from 'moment';
import Clocking from './Clocking';
import CreateClockingPlaceholder from './CreateClockingPlaceholder';
import { INewClockingParams } from '../../reports/timesheets';

interface Props {
  className?: string;
  user: IUser;
  startDate: Moment;
  setActiveClocking: React.Dispatch<React.SetStateAction<IScheduleClocking | null>>;
  setNewClockingParams: React.Dispatch<React.SetStateAction<INewClockingParams>>;
  setDrawerClockingVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserClockingsRow: React.FC<Props> = ({
  className,
  user,
  startDate,
  setActiveClocking,
  setNewClockingParams,
  setDrawerClockingVisible,
}) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, users },
    dispatch,
  } = useContext(AppContext);
  const [activeDayPlaceholder, setActiveDayPlaceholder] = useState<string | null>(null);

  const onMouseEnter = (key: string) => {
    setActiveDayPlaceholder(key);
  };

  const onMouseLeave = () => {
    setActiveDayPlaceholder(null);
  };

  return (
    <div className={className}>
      <div className="left">
        <Avatar src={user.picture}>{user.initials}</Avatar>
        <span>{user.displayName}</span>
      </div>
      <div className="right">
        {WEEKDAYS.map((day, index) => {
          const date = startDate.clone().add(index, 'day');
          const today = date.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD');
          const key = `${user.recordId}.${date.format('YYYY-MM-DD')}`;
          const clockings = user.clockings?.filter((clocking) => moment(clocking.startdate).isoWeekday() == index + 1);
          return (
            <div key={day} className="clockings" onMouseEnter={() => onMouseEnter(key)} onMouseLeave={onMouseLeave}>
              {clockings?.map((clocking) => (
                <Clocking key={clocking.id} clocking={clocking} setActiveClocking={setActiveClocking} />
              ))}
              {activeDayPlaceholder == key && (
                <CreateClockingPlaceholder
                  today={today}
                  newClockingParams={{
                    start: date.startOf('day').unix(),
                    userRecordId: user.recordId!,
                  }}
                  setDrawerClockingVisible={setDrawerClockingVisible}
                  setNewClockingParams={setNewClockingParams}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default styled(UserClockingsRow)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(226, 230, 239);

  .left {
    width: 210px;
    padding: 15px;
    border-right: 1px solid rgb(226, 230, 239);
    align-self: stretch;
    display: flex;
    align-items: center;
  }

  .right {
    flex: 1;
    display: flex;
    align-self: stretch;

    .clockings {
      display: flex;
      flex-direction: column;
      gap: 7px;
      padding: 7px;
      flex: 1;
      border-right: 1px solid rgb(226, 230, 239);
      position: relative;
    }
  }
`;
