import ContainerView from '@/layouts/ContainerView';
import AppContext from '@/pages/app/context';
import { IScheduleClocking } from '@/types/schedule-clocking.model';
import { IUser } from '@/types/user.model';
import { WEEKDAYS, handleError } from '@/utils';
import { Button, Input, Spin } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import DateSelectorStyled from '../components/DateSelector';
import { INewClockingParams } from '../reports/timesheets';
import ModalCreateClocking from '../reports/timesheets/ModalCreateClocking';
import DrawerClocking from '../reports/timesheets/default/DrawerClocking';
import CreateClockingPlaceholder from './components/CreateClockingPlaceholder';
import UserClockingsRow from './components/UserClockingsRow';

interface Props {
  className?: string;
}

const Clockings: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, users, activeSection },
    dispatch,
  } = useContext(AppContext);
  const history = useHistory();
  const { start } = useParams() as any;
  const [loading, setLoading] = useState<boolean>(false);
  const [activeClocking, setActiveClocking] = useState<IScheduleClocking | null>(null);
  const [usersWithClockings, setUsersWithClockings] = useState<IUser[]>([]);
  const [filteredUsersWithClockings, setFilteredUsersWithClockings] = useState<IUser[]>([]);
  const [drawerClockingVisible, setDrawerClockingVisible] = useState<boolean>(false);
  const [activeDayPlaceholder, setActiveDayPlaceholder] = useState<string | null>(null);
  const [newClockingParams, setNewClockingParams] = useState<INewClockingParams>({});

  const startDate = moment(start).startOf('week');
  const endDate = moment(startDate).endOf('week');

  useEffect(() => {
    setLoading(true);
    if (!startDate || users.length == 0) return;

    getClockings();
  }, [start, users, activeSection]);

  const getClockings = () => {
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/get-clockings`, {
        params: {
          departmentId: activeDepartmentId,
          start: startDate.unix(),
          end: endDate.unix(),
          sectionId: activeSection,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then(({ data }) => {
        const filteredClockings = (data as IScheduleClocking[]).filter((clocking) => {
          if (clocking.shyft && clocking.shyft.hide) {
            return false;
          }
          return true;
        });
        const filtered = users
          .map((user) => {
            const userClockings = filteredClockings.filter(
              (clocking) => user.recordId == String(clocking.ressource_record_id),
            );
            return {
              ...user,
              clockings: userClockings,
            };
          })
          .filter((user) =>
            activeSection
              ? user.sections &&
                typeof user.sections == 'string' &&
                (user.sections as string).split(',').includes(activeSection)
              : true,
          )
          .sort(sortAlphabetiallyClockingsFirst);
        setUsersWithClockings(filtered);
        setFilteredUsersWithClockings(filtered);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onArrowClick = (value: number) => {
    const date = startDate.add(value, 'week');
    const formattedDate = date.format('YYYY-MM-DD');
    history.push(`/app/clockings/${formattedDate}`);
  };

  const onDatePickerChange = (date: moment.Moment | null) => {
    if (!date) return;
    history.push(`/app/clockings/${date.startOf('week').format('YYYY-MM-DD')}`);
  };

  const onSave = (data: any = null) => {
    if (!activeClocking && !data) return;
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/get-clockings/${activeClocking ? activeClocking.id : data}`, {
        params: {
          departmentId: activeDepartmentId,
          sectionId: activeSection,
        },
      })
      .then(({ data }: { data: IScheduleClocking }) => {
        setUsersWithClockings(
          usersWithClockings
            .map((user) => {
              if (user.recordId == String(data.ressource_record_id)) {
                let newUser = { ...user };
                const userClockings = user.clockings;
                if (userClockings) {
                  newUser = {
                    ...newUser,
                    clockings:
                      data.active == 0
                        ? [...userClockings.filter((clocking) => clocking.id !== data.id)]
                        : [...userClockings.filter((clocking) => clocking.id !== data.id), data],
                  };
                  return newUser;
                }
              }
              return user;
            })
            .sort(sortAlphabetiallyClockingsFirst),
        );
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setDrawerClockingVisible(false);
        setActiveClocking(null);
      });
  };

  const onCreate = () => {
    setDrawerClockingVisible(true);
  };

  const onDayEnter = (day: string) => {
    setActiveDayPlaceholder(day);
  };

  const onDayLeave = () => {
    setActiveDayPlaceholder(null);
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!value || value == '') {
      setFilteredUsersWithClockings([...usersWithClockings]);
      return;
    }
    setFilteredUsersWithClockings(
      [...usersWithClockings].filter((user) => user.displayName?.toLowerCase().includes(value.toLowerCase())),
    );
  };

  return (
    <ContainerView className={className}>
      <div className="header">
        <div className="left">
          <h2 className="title">{t('GLOBAL.YOUR_CLOCKINGS')}</h2>
        </div>
        <div className="center">
          <DateSelectorStyled
            picker="week"
            startDate={startDate}
            endDate={endDate}
            onArrowClick={onArrowClick}
            onDatePickerChange={onDatePickerChange}
          />
        </div>
        <div className="right">
          <Button type="primary" onClick={onCreate}>
            {t('CLOCKINGS.CREATE_CLOCKING')}
          </Button>
        </div>
      </div>
      <div className="subheader">
        <div className="search">
          <Input placeholder={t('GLOBAL.SEARCH')} onChange={onSearch} allowClear />
        </div>
        <div className="days">
          {WEEKDAYS.map((day, index) => {
            const date = startDate.clone().add(index, 'day');
            const today = date.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD');
            return (
              <div
                key={day}
                className={`day ${today ? 'today' : ''}`}
                onMouseEnter={() => onDayEnter(day)}
                onMouseLeave={onDayLeave}
              >
                {t(`GLOBAL.${day.toUpperCase()}_SHORT`)}. {date.format('DD')}
                {day == activeDayPlaceholder ? (
                  <CreateClockingPlaceholder
                    absolute
                    today={today}
                    newClockingParams={{
                      start: date.startOf('day').unix(),
                    }}
                    setDrawerClockingVisible={setDrawerClockingVisible}
                    setNewClockingParams={setNewClockingParams}
                  />
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
      <>
        {loading ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : (
          <>
            {filteredUsersWithClockings.map((user) => (
              <UserClockingsRow
                startDate={startDate}
                key={user.recordId}
                user={user}
                setActiveClocking={setActiveClocking}
                setDrawerClockingVisible={setDrawerClockingVisible}
                setNewClockingParams={setNewClockingParams}
              />
            ))}
          </>
        )}
      </>
      <DrawerClocking
        clockingId={activeClocking ? String(activeClocking.id) : null}
        visible={!!activeClocking}
        onClose={() => {
          setDrawerClockingVisible(false);
          setActiveClocking(null);
          getClockings();
        }}
        onSave={onSave}
      />
      <ModalCreateClocking
        visible={drawerClockingVisible}
        params={newClockingParams}
        onClose={() => {
          setDrawerClockingVisible(false);
          getClockings();
        }}
        onCreated={onSave}
      />
    </ContainerView>
  );
};

export default styled(Clockings)`
  .spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0;
  }

  .header {
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    .left,
    .center,
    .right {
      flex: 1;
    }

    .left {
      .title {
        font-weight: bold;
        font-size: 24px;
        text-transform: uppercase;
      }
    }

    .center {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    h2 {
      margin-bottom: 0;
    }
  }

  .subheader {
    display: flex;
    align-items: center;
    height: 50px;
    background-color: rgb(247, 249, 255);
    border-bottom: 1px solid rgb(226, 230, 239);
    border-top: 1px solid rgb(226, 230, 239);

    .search {
      height: 100%;
      width: 210px;
      border-left: 1px solid rgb(226, 230, 239);
      border-right: 1px solid rgb(226, 230, 239);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
    }

    .days {
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;

      .day {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex: 1;
        text-align: center;
        border-right: 1px solid rgb(226, 230, 239);
        font-weight: bold;
        font-size: 13px;
        position: relative;

        &.today {
          background-color: rgb(226, 230, 239);
        }
      }
    }
  }
`;

const sortAlphabetiallyClockingsFirst = (a: IUser, b: IUser) => {
  const hasClockingsA = a.clockings && a.clockings.length > 0;
  const hasClockingsB = b.clockings && b.clockings.length > 0;
  if (hasClockingsA && !hasClockingsB) {
    return -1;
  } else if (!hasClockingsA && hasClockingsB) {
    return 1;
  }
  return a.displayName!.toLowerCase().localeCompare(b.displayName!.toLowerCase());
};
