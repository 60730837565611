import AppContext from '@/pages/app/context';
import { getWindowSize, handleError } from '@/utils';
import { InboxOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Input, Upload } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { RcFile } from 'antd/lib/upload';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const DrawerTurnover: React.FC<Props> = ({ className, visible, setVisible }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, turnoverTemplates },
    dispatch,
  } = useContext(AppContext);
  const [form] = useForm();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [file, setFile] = useState<RcFile | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (visible) return;
    form.resetFields();
  }, [visible]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onClose = () => {
    setVisible(false);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('name', values.name);
    if (file) {
      formData.append('file', file);
    }

    axios({
      url: `${process.env.REACT_APP_API_URL}/v3/insights/turnover/provisional/${file ? 'import' : ''}`,
      method: 'POST',
      data: formData,
      params: {
        departmentId: activeDepartmentId,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        history.push(`/app/settings/insights/turnover-templates/${data.template.id}`);
        dispatch({
          type: 'SET_TURNOVER_TEMPLATES',
          payload: [...turnoverTemplates, data.template],
        });
        setVisible(false);
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const beforeUpload = (file: RcFile) => {
    setFile(file);
    return false;
  };

  const onRemoveFile = () => {
    setFile(null);
  };

  return (
    <Drawer
      title={t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.CREATE_TEMPLATE')}
      placement="right"
      visible={visible}
      onClose={onClose}
      className={className}
      width={windowSize.innerWidth > 900 ? 400 : '75%'}
    >
      <Form form={form} layout="vertical" size="large" onFinish={onFinish} scrollToFirstError={true}>
        <Form.Item name="name" label={t('GLOBAL.NAME')} rules={[{ required: true }]}>
          <Input placeholder={t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.TEMPLATE_NAME')} size="large" allowClear />
        </Form.Item>
        <Form.Item name="file" label={t('GLOBAL.FILE')}>
          <Upload.Dragger
            beforeUpload={beforeUpload}
            maxCount={1}
            multiple={false}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onRemove={onRemoveFile}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t('UPLOAD.DRAGGER.TEXT')}</p>
          </Upload.Dragger>
        </Form.Item>
        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              {t('GLOBAL.SAVE')}
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default styled(DrawerTurnover)``;
