import colors from '@/styles/colors';
import { Button, Form, Input, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { MessageType } from 'antd/lib/message';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';
import { countryCodeEmoji } from 'country-code-emoji';
import translatedCountries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import fr from 'i18n-iso-countries/langs/fr.json';
import nl from 'i18n-iso-countries/langs/nl.json';
import i18next from 'i18next';
import { getWindowSize } from '@/utils';

translatedCountries.registerLocale(en);
translatedCountries.registerLocale(fr);
translatedCountries.registerLocale(nl);

interface Props {
  className?: string;
  form: FormInstance<any>;
  loading: boolean;
  onFinish: (values: any) => MessageType | undefined;
}

const DetailsForm: React.FC<Props> = ({ className, form, loading, onFinish }) => {
  const { i18n, t } = useTranslation();
  const [countriesTranslated, setCountriesTranslated] = useState<[string, string][]>();
  const [countriesEmojis, setCountriesEmojis] = useState<string[]>([]);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const countr = translatedCountries.getNames(i18next.language);
    const sorted = Object.entries(countr).sort((a, b) => (a[1].toLowerCase() < b[1].toLowerCase() ? -1 : 1));
    setCountriesEmojis(sorted.map((el) => el[0]).map(countryCodeEmoji));
    setCountriesTranslated(sorted);
  }, []);

  return (
    <div className={className}>
      <Form form={form} layout={'vertical'} className="form" onFinish={onFinish}>
        <div>
          <h4>{t('CONFIRM_EMAIL.FORM_TITLES.CREATE_PASSWORD')}</h4>
          <div className="password-container">
            <Form.Item
              className="password-input"
              label={t('GLOBAL.PASSWORD')}
              name="password"
              rules={[{ required: true }]}
            >
              <Input size="large" type="password" placeholder={t('GLOBAL.PASSWORD')} />
            </Form.Item>
            <Form.Item
              className="password-input"
              label={t('CONFIRM_EMAIL.CONFIRM_PASSWORD')}
              name="password2"
              rules={[{ required: true }]}
            >
              <Input size="large" type="password" placeholder={t('CONFIRM_EMAIL.CONFIRM_PASSWORD')} />
            </Form.Item>
          </div>
        </div>
        <div>
          <h4>{t('CONFIRM_EMAIL.FORM_TITLES.DATA_CONTROL')}</h4>
          <div className="data-control">
            <div>
              <Form.Item className="" label={t('GLOBAL.COMPANY')} name="companyName" rules={[{ required: true }]}>
                <Input size="large" placeholder="Young & Free" />
              </Form.Item>
              <Form.Item label={t('GLOBAL.ADDRESS')} name="address" rules={[{ required: true }]}>
                <Input size="large" placeholder="Rue des palais 44, Bruxelles, Belgique" />
              </Form.Item>
              <Form.Item label={t('GLOBAL.ZIP')} name="zip" rules={[{ required: true }]}>
                <Input size="large" placeholder="1000" />
              </Form.Item>
              <Form.Item label={t('GLOBAL.LASTNAME')} name="lastName" rules={[{ required: true }]}>
                <Input size="large" placeholder="Tyson" />
              </Form.Item>
              <Form.Item label={t('GLOBAL.EMAIL')} name="email" rules={[{ required: true }]}>
                <Input size="large" placeholder="mike.tyson@gmail.com" />
              </Form.Item>
            </div>
            <div>
              <Form.Item label={t('CONFIRM_EMAIL.COMPANY_NUMBER')} name="vatNumber">
                <Input size="large" placeholder={t('CONFIRM_EMAIL.COMPANY_NUMBER')} />
              </Form.Item>
              <Form.Item label={t('GLOBAL.CITY')} name="city" rules={[{ required: true }]}>
                <Input size="large" placeholder="Bruxelles" />
              </Form.Item>
              <Form.Item label={t('GLOBAL.COUNTRY')} name="country" rules={[{ required: true }]}>
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Belgique"
                  filterOption={(input, option) => {
                    const label = option!.label! as string;
                    return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  allowClear
                  size="large"
                >
                  {countriesTranslated?.map((key, i) => {
                    return (
                      <Select.Option value={key[0]} key={key[0]} label={key[1]}>
                        <div className="demo-option-label-item">
                          <span role="img" aria-label={key[1]}>
                            {countriesEmojis[i]}{' '}
                          </span>
                          {key[1]}
                        </div>
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item label={t('GLOBAL.FIRSTNAME')} name="firstName" rules={[{ required: true }]}>
                <Input size="large" placeholder="Mike" />
              </Form.Item>
              <Form.Item label={t('GLOBAL.PHONE')} name="phone" rules={[{ required: true }]}>
                <PhoneInput />
              </Form.Item>
            </div>
          </div>
        </div>
        <Button type="primary" htmlType="submit" size="large" className="submit-btn" loading={loading}>
          {t('CONFIRM_EMAIL.SUBMIT_BUTTON')}
        </Button>
      </Form>
    </div>
  );
};

export default styled(DetailsForm)`
  .form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 25px;
  }

  h4 {
    font-size: 16px;
    color: ${colors.green};
    font-weight: 800;
  }

  .password-container {
    display: flex;
    gap: 25px;

    .ant-form-item {
      flex: 1;
    }
  }

  .data-control {
    display: flex;
    gap: 25px;

    div {
      flex: 1;
    }

    .react-tel-input .form-control {
      width: 100%;
      height: 40px;
    }
  }

  .submit-btn {
    align-self: flex-start;
  }

  @media screen and (max-width: 1024px) {
    .password-container,
    .data-control {
      flex-direction: column;
      gap: 0;
    }
  }
`;
