import AppContext from '@/pages/app/context';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { FEATURES } from '@/types/features.model';
import { ISettingsListElement } from '@/types/settings/list-element.model';
import { getWindowSize, handleError, isClockingAccount, isCountry, isEmptyAccount, isFeatureEnabled } from '@/utils';
import { Form, InputNumber, Modal, Select, Switch } from 'antd';
import { SelectValue } from 'antd/es/select';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SettingsForm from '../../components/SettingsForm';

interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings | null;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
}

interface Code {
  id: string;
  name: string;
}

const HrSettings: React.FC<Props> = ({ className, currentSettings, updateCurrentSettings }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, hrPartners, features },
    dispatch,
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showWarningCounter, setShowWarningCounter] = useState<boolean>(false);
  const [code, setCode] = useState<string>();
  const [currentCode, setCurrentCode] = useState<Code | null>();
  const [customHrCodes, setCustomHrCodes] = useState<any[]>([]);
  const [customHrCodesEdited, setCustomHrCodesEdited] = useState<any[]>(customHrCodes);
  const [hrPartnersLoading, setHrPartnersLoading] = useState<boolean>(true);
  const [specialCodeChecked, setSpecialCodeChecked] = useState<boolean>(false);
  const [customHrDescription, setCustomHrDescription] = useState<string>('');
  const [customHrCode, setCustomHrCode] = useState<string>('');
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    onReset();
  }, [currentSettings]);

  const changeSpecialCode = () => {
    setSpecialCodeChecked(!specialCodeChecked);
    if (specialCodeChecked) {
      form.setFieldsValue({
        hr: {
          holiday_special_code: null,
        },
      });
    }
  };

  const onCollectiveChange = (value: SelectValue) => {
    Modal.confirm({
      className: 'modal-danger',
      title: t('GLOBAL.WARNING'),
      content: (
        <>
          <p>{t('SETTINGS.HR.SETTINGS.SELECT_CP.MODAL_UPDATE_CP_WARNING.CONTENT')}</p>
          <p>{t('SETTINGS.HR.SETTINGS.SELECT_CP.MODAL_UPDATE_CP_WARNING.CONFIRM_TEXT')}</p>
        </>
      ),
      okText: t('GLOBAL.YES'),
      cancelText: t('GLOBAL.NO'),
      onOk: () => {},
      onCancel: () => {
        form.setFieldsValue({
          department: { ...currentSettings?.department, constraintsId: currentSettings?.department?.constraintsId },
        });
      },
      closable: true,
    });
  };

  const elements = [
    {
      title: t('GLOBAL.GENERAL'),
      visible: true,
    },
    {
      title: t('SETTINGS.HR.SETTINGS.TITLES.CP'),
      visible: true,
    },
  ];
  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('SETTINGS.HR.SETTINGS.DEFAULT_COMPUTE_TYPE.LABEL'),
          description: t('SETTINGS.HR.SETTINGS.DEFAULT_COMPUTE_TYPE.DESCRIPTION'),
          name: ['general', 'hr_timesheet_view_mode'],
          style: { margin: 0, minWidth: 200 },
          element: (
            <Select
              style={{ width: '100%' }}
              placeholder={t('GLOBAL.SELECT')}
              getPopupContainer={(trigger: any) => trigger}
            >
              {isFeatureEnabled(features, FEATURES.SCHEDULE) && (
                <Select.Option value={1}>{t('GLOBAL.PLANNED_HOURS')}</Select.Option>
              )}
              {isFeatureEnabled(features, FEATURES.CLOCKING) && (
                <Select.Option value={2}>{t('GLOBAL.CLOCKING_HOURS')}</Select.Option>
              )}
            </Select>
          ),
          show: !isClockingAccount(features) && !isEmptyAccount(features),
          elementBelow: windowSize.innerWidth <= 900,
        },
        {
          label: t('SETTINGS.HR.SETTINGS.DEFAULT_DAYS_HOURS.LABEL'),
          description: t('SETTINGS.HR.SETTINGS.DEFAULT_DAYS_HOURS.DESCRIPTION'),
          name: ['general', 'default_fulldayhours'],
          style: { margin: 0 },
          element: (
            <InputNumber
              type="number"
              min={0}
              step={0.1}
              precision={1}
              parser={(value) => {
                return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
              }}
            />
          ),
          elementAddon: <span style={{ marginLeft: 15 }}>{t('GLOBAL.HOURS')}</span>,
          show: true,
          elementBelow: windowSize.innerWidth <= 900,
        },
        {
          label: t('SETTINGS.HR.SETTINGS.ACTIVATE_EXTRA_HOURS_COUNTERS.LABEL'),
          description: t('SETTINGS.HR.SETTINGS.ACTIVATE_EXTRA_HOURS_COUNTERS.DESCRIPTION'),
          name: ['general', 'extrahours_enabled'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.general?.extrahours_enabled} />,
          show: true,
        },
        {
          label: t('SETTINGS.HR.SETTINGS.DISPLAY_ABSENCE_COUNTERS.LABEL'),
          description: t('SETTINGS.HR.SETTINGS.DISPLAY_ABSENCE_COUNTERS.DESCRIPTION'),
          name: ['general', 'counters_values'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: (
            <Select
              style={{ width: windowSize.innerWidth <= 900 ? '100%' : 160 }}
              placeholder={t('GLOBAL.SELECT')}
              getPopupContainer={(trigger: any) => trigger}
              onChange={() => {
                setShowWarningCounter(true);
              }}
            >
              <Select.Option value={'HOURS'}>{t('GLOBAL.HOURS')}</Select.Option>
              <Select.Option value={'DAYS'}>{t('GLOBAL.DAYS')}</Select.Option>
            </Select>
          ),
          show: true,
          elementBelow: windowSize.innerWidth <= 900,
        },
        {
          label: t('SETTINGS.HR.SETTINGS.FULL_DAY_DAYOFF.LABEL'),
          description: t('SETTINGS.HR.SETTINGS.FULL_DAY_DAYOFF.DESCRIPTION'),
          name: ['general', 'treshold_halfday'],
          style: { margin: 0 },
          element: (
            <InputNumber
              type="number"
              min={0}
              step={0.1}
              precision={1}
              parser={(value) => {
                return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
              }}
            />
          ),
          elementAddon: <span style={{ marginLeft: 15 }}>{t('GLOBAL.HOURS')}</span>,
          show: isFeatureEnabled(features, FEATURES.LEAVE_MANAGEMENT),
          shouldUpdate: (prevValues, curValues) =>
            prevValues.general?.couters_values !== curValues.general?.counters_values,
          elementBelow: windowSize.innerWidth <= 900,
        },
        {
          label: t('SETTINGS.HR.SETTINGS.EMPLOYEE_CALCULATION.LABEL'),
          description: t('SETTINGS.HR.SETTINGS.EMPLOYEE_CALCULATION.DESCRIPTION'),
          name: ['insights', 'cost_calculation_base'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: (
            <Select
              getPopupContainer={(trigger) => trigger.parentNode}
              style={{ width: windowSize.innerWidth > 900 ? 200 : '100%' }}
            >
              <Select.Option value="gross">{t('GLOBAL.EMPLOYER_COST')}</Select.Option>
              <Select.Option value="net">{t('GLOBAL.EMPLOYEE_COST')}</Select.Option>
            </Select>
          ),
          show: true,
          newBadge: '2024-05-01',
        },
      ],
    ],
    [
      [
        {
          label: t('SETTINGS.HR.SETTINGS.SELECT_CP.LABEL'),
          description: t('SETTINGS.HR.SETTINGS.SELECT_CP.DESCRIPTION'),
          name: ['department', 'constraintsId'],
          element: (
            <Select
              allowClear
              showSearch
              style={{ width: 350 }}
              placeholder={t('SETTINGS.ACCOUNT.SCHEDULE.COLLECTIVE_LABOR_AGREEMENT')}
              filterOption={(input, option: any) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={isCountry(activeDepartment!, ['FR']) ? onCollectiveChange : undefined}
            >
              {currentSettings?.datas?.constraints?.map((constraint) => (
                <Select.Option key={`constraint_${constraint.id}`} value={constraint.id}>
                  {constraint.name}
                </Select.Option>
              ))}
            </Select>
          ),
          show: true,
          elementBelow: windowSize.innerWidth <= 900,
        },
      ],
    ],
  ];

  const onReset = () => {
    setFormHasChanged(false);
    setSpecialCodeChecked(false);
    form.resetFields();
    let fieldsValue: any = {};
    let found;
    const codes = hrPartners.map((hr: any) => hr.codes);
    for (let i = 0; i < codes.length; i++) {
      const arr = codes[i];
      found = arr.find((el: any) => el.id === currentSettings?.hr?.holiday_special_code?.toString());
    }

    if (currentSettings) {
      setSpecialCodeChecked(currentSettings?.hr?.holiday_special_code_enabled ? true : false);

      const _extrahours_endtime = currentSettings?.general?.extrahours_endtime;
      const _extrahours_starttime = currentSettings?.general?.extrahours_starttime;
      const _nightshift_start = currentSettings?.general?.nightshift_start;
      const _nightshift_end = currentSettings?.general?.nightshift_end;
      const _holiday_special_code = currentSettings?.hr?.holiday_special_code;
      const _nightshift_code_id = currentSettings?.general?.nightshift_code_id;

      fieldsValue = {
        ...fieldsValue,
        ...currentSettings,
        general: {
          ...currentSettings.general,
          extrahours_starttime: _extrahours_starttime ? moment(`1970-01-01T${_extrahours_starttime}`) : undefined,
          extrahours_endtime: _extrahours_endtime ? moment(`1970-01-01T${_extrahours_endtime}`) : undefined,
          nightshift_start: _nightshift_start ? moment(`1970-01-01T${_nightshift_start}`).format('HH:mm') : undefined,
          nightshift_end: _nightshift_end ? moment(`1970-01-01T${_nightshift_end}`).format('HH:mm') : undefined,
          nightshift_code_id: _nightshift_code_id?.toString(),
        },
        hr: {
          ...currentSettings.hr,
          holiday_special_code: _holiday_special_code?.toString(),
          custom_hr_codes: currentSettings?.hr?.custom_hr_codes,
        },
      };
    }

    form.setFieldsValue(fieldsValue);
    if (currentSettings?.hr?.custom_hr_codes) {
      setCustomHrCodes(currentSettings?.hr?.custom_hr_codes as any);
      setCustomHrCodesEdited(currentSettings?.hr?.custom_hr_codes as any);
    }
  };

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    const _extrahours_endtime = values?.general?.extrahours_endtime;
    const _extrahours_starttime = values?.general?.extrahours_starttime;

    const newValues = {
      ...values,
      general: {
        ...values?.general,
        extrahours_starttime: _extrahours_starttime ? _extrahours_starttime.format('HH:mm:ss') : undefined,
        extrahours_endtime: _extrahours_endtime ? _extrahours_endtime.format('HH:mm:ss') : undefined,
        nightshift_code_id: code === undefined ? null : code ? code : currentCode?.id,
      },
      hr: {
        ...values?.hr,
        custom_hr_codes:
          customHrCodesEdited.length > 0
            ? customHrDescription && customHrCode
              ? [...customHrCodesEdited, { code: customHrCode, description: customHrDescription }]
              : customHrCodesEdited
            : customHrDescription && customHrCode
            ? [
                {
                  code: customHrCode,
                  description: customHrDescription,
                },
              ]
            : [],
      },
    };

    updateCurrentSettings(newValues)
      .then(() => {
        setLoading(false);
        setCurrentCode(null);
        setCustomHrDescription('');
        setCustomHrCode('');
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.HR.SETTINGS.TITLE')}</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
    </div>
  );
};

export default styled(HrSettings)``;
