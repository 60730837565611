import DateSelector from '@/pages/app/components/DateSelector';
import ModalEditQuotaTemplate from '@/pages/app/components/ModalEditQuotaTemplate';
import Options from '@/pages/app/components/Options';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { FEATURES } from '@/types/features.model';
import { IShift } from '@/types/shift.model';
import { getDatesBetweenDates, getWindowSize, handleError, isFeatureEnabled, lioWeekdays } from '@/utils';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Divider, Dropdown, Menu, Popover, Radio, Select, Tooltip, message, notification } from 'antd';
import axios from 'axios';
import { isNaN } from 'lodash';
import moment from 'moment';
import React, { Dispatch, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import OperationalViewFilters from '../operational/components/Filters';
import { ActionType, copyShifts, updateShiftsAndContextWithShiftResponse } from '../redux/actions';
import { InitialStateType } from '../redux/store';
import HeaderOptionDetails from './HeaderOptionDetails';
import ModalAddOccurrences from './ModalAddOccurrences';
import ModalAssignShift from './ModalAssignShift';
import ModalChooseShiftTemplate from './ModalChooseShiftTemplate';
import ModalCreateShiftTemplate from './ModalCreateShiftTemplate';
import ModalDeleteShiftConfirmation from './ModalDeleteShiftConfirmation';
import ModalDeleteView from './ModalDeleteView';
import ModalGeneratingSchedule from './ModalGeneratingSchedule';
import ModalImportWeek from './ModalImportWeek';
import ModalPasteMultipleFreeShifts from './ModalPasteMultipleFreeShifts';
import ModalPublishShifts from './ModalPublishShifts';
import ModalSaveView from './ModalSaveView';
import ModalTransferShifts from './ModalTransferShifts';
import GeneratePdf from './actions-old/GeneratePdf';
import GenerateScheduleModels from './actions-old/GenerateScheduleModels';
import PublishShifts from './actions-old/PublishShifts';
import AySync from './actions/AySync';
import CopyDay from './actions/CopyDay';
import CopyShifts from './actions/CopyShifts';
import DeleteDay from './actions/DeleteDay';
import DeleteMonth from './actions/DeleteMonth';
import DeleteShifts from './actions/DeleteShifts';
import DuplicatePreviousWeek from './actions/DuplicatePreviousWeek';
import EditQuotaTemplate from './actions/EditQuotaTemplate';
import ImportWeek from './actions/ImportWeek';
import PasteDay from './actions/PasteDay';
import PasteShifts from './actions/PasteShifts';
import PrintSchedules from './actions/PrintSchedules';
import SaveWeek from './actions/SaveWeek';
import SelectAllShifts from './actions/SelectAllShifts';
import HeaderSmallDrawer from './drawers/HeaderSmallDrawer';

interface Props {
  className?: string;
  isLoading: boolean;
  shiftsToApprove: string[];
  setShiftsToApprove: React.Dispatch<React.SetStateAction<string[]>>;
  shiftNotApprovedIds: string[];
  setShiftNotApprovedIds: React.Dispatch<React.SetStateAction<string[]>>;
  showStats: boolean;
  setShowStats: React.Dispatch<React.SetStateAction<boolean>>;
  turnover?: any;
}

const Header: React.FC<Props> = ({
  className,
  isLoading,
  shiftsToApprove,
  setShiftsToApprove,
  shiftNotApprovedIds,
  setShiftNotApprovedIds,
  showStats,
  setShowStats,
  turnover,
}) => {
  const {
    state: {
      activeDepartment,
      activeSection,
      tasks,
      sections,
      loadingWeeklyViews,
      weeklyViews,
      quotaTemplates,
      skills,
      userCategories,
      features,
    },
  } = useContext(AppContext);
  const hoursDispatch: Dispatch<ActionType> = useDispatch();

  const {
    picker,
    selectedDate,
    startDate,
    endDate,
    filteredShiftsByUsers,
    clipboardHistory,
    shiftIdsToDelete,
    shiftsMap,
    showOpenShifts,
    selectedShifts,
    shiftIdsToPublish,
    shiftsToTransfer,
    comparisonTemplate,
    cycleNumber,
    scheduleModel,
    taskScheduler,
    productivityIndex,
    productivityIndexReal,
    showProductivityIndex,
    pasteMultipleFreeShiftsVisible,
    assignModalVisible,
    addOccurrencesModalVisible,
    monthly_selected_elements,
    monthly_selecting_elements,
    selected_shifts_on_shift_key,
    filterOptions,
    active_weekly_view,
    shiftsByUsers,
    showAbsences,
    usersMap,
  } = useSelector(
    ({
      picker,
      selectedDate,
      startDate,
      endDate,
      filteredShiftsByUsers,
      clipboardHistory,
      shiftIdsToDelete,
      shiftsMap,
      showOpenShifts,
      selectedShifts,
      shiftIdsToPublish,
      shiftsToTransfer,
      comparisonTemplate,
      cycleNumber,
      scheduleModel,
      taskScheduler,
      productivityIndex,
      productivityIndexReal,
      showProductivityIndex,
      pasteMultipleFreeShiftsVisible,
      assignModalVisible,
      addOccurrencesModalVisible,
      selectedShift,
      monthly_selected_elements,
      monthly_selecting_elements,
      selected_shifts_on_shift_key,
      filterOptions,
      active_weekly_view,
      shiftsByUsers,
      showAbsences,
      usersMap,
    }: InitialStateType) => ({
      picker,
      selectedDate,
      startDate,
      endDate,
      filteredShiftsByUsers,
      clipboardHistory,
      shiftIdsToDelete,
      shiftsMap,
      showOpenShifts,
      selectedShifts,
      shiftIdsToPublish,
      shiftsToTransfer,
      comparisonTemplate,
      cycleNumber,
      scheduleModel,
      taskScheduler,
      productivityIndex,
      productivityIndexReal,
      showProductivityIndex,
      pasteMultipleFreeShiftsVisible,
      assignModalVisible,
      addOccurrencesModalVisible,
      selectedShift,
      monthly_selected_elements,
      monthly_selecting_elements,
      selected_shifts_on_shift_key,
      filterOptions,
      active_weekly_view,
      shiftsByUsers,
      showAbsences,
      usersMap,
    }),
    shallowEqual,
  );

  const [allShiftsSelected, setAllShiftsSelected] = useState<boolean>(false);
  const [shiftsToApproveCounter, setShiftsToApproveCounter] = useState<number>(0);
  const [shiftIds, setShiftIds] = useState<string[]>([]);
  const [showModalChooseShiftTemplate, setShowModalChooseShiftTemplate] = useState<'import' | 'compare' | null>(null);
  const [showModalCreateShiftTemplate, setShowModalCreateShiftTemplate] = useState<boolean>(false);
  const [isGeneratinPdf, setIsGeneratingPdf] = useState<boolean>(false);
  const [isGeneratingSchedule, setIsGeneratingSchedule] = useState<boolean>(false);
  const [saveViewModalVisible, setSaveViewModalVisible] = useState<boolean>(false);
  const [importWeekVisible, setImportWeekVisible] = useState<boolean>(false);
  const [deleteViewModalVisible, setDeleteViewModalVisible] = useState<boolean>(false);
  const [headerSmallDrawerVisible, setHeaderSmallDrawerVisible] = useState<boolean>(false);
  const [modalEditQuotaTemplateVisible, setModalEditQuotaTemplateVisible] = useState<boolean>(false);
  const [moreDropdownVisible, setMoreDropdownVisible] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const history = useHistory();

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
  }, [i18n.language]);

  useEffect(() => {
    const shiftNotApprovedIds = [];
    const shiftIds = [];

    hoursDispatch({
      type: 'SET_SELECTED_SHIFTS',
      payload: [],
    });

    const filteredShiftsByUsersLenght = filteredShiftsByUsers.length;
    for (let i = 0; i < filteredShiftsByUsersLenght; i++) {
      const filterdShiftsByUser = filteredShiftsByUsers[i].shifts;
      const filterdShiftsByUserLength = filterdShiftsByUser.length;
      for (let j = 0; j < filterdShiftsByUserLength; j++) {
        const shift = filterdShiftsByUser[j];
        if (shift.shyftType === 3 || (!showOpenShifts && !shift.userRecordId)) {
          continue;
        }
        shiftIds.push(shift.id!);
        if (!shift.approved) {
          shiftNotApprovedIds.push(shift.id!);
        }
      }
    }

    setShiftIds(shiftIds);
    setShiftNotApprovedIds(shiftNotApprovedIds);
    setShiftsToApprove(shiftNotApprovedIds);
    // eslint-disable-next-line
  }, [filteredShiftsByUsers, showOpenShifts]);

  useEffect(() => {
    const shiftsToApprove: string[] = [];
    const selectedShiftsLength = selectedShifts.size;
    if (selectedShiftsLength) {
      for (const id of selectedShifts) {
        const shift = shiftsMap.get(id);
        if (shift && !shift.approved) {
          shiftsToApprove.push(shift.id!);
        }
      }
      setShiftsToApprove(shiftsToApprove);
      if (selectedShiftsLength !== shiftIds.length && !activeSection) {
        setAllShiftsSelected(false);
      } else {
        setAllShiftsSelected(true);
      }
    } else {
      setAllShiftsSelected(false);
      setShiftsToApprove(shiftNotApprovedIds);
    }
  }, [selectedShifts]);
  // Handlers

  useEffect(() => {
    let counter = 0;
    for (const shiftId of shiftsToApprove) {
      const count = shiftsMap.get(shiftId)?.count || 1;
      counter += count;
    }
    setShiftsToApproveCounter(counter);
  }, [shiftsToApprove]);

  const onDatePickerChange = (date: moment.Moment | null) => {
    if (date) {
      history.push(`/app/hours/manage/${picker}/${date.format('YYYY-MM-DD')}`);
      hoursDispatch({
        type: 'SET_MONTHLY_SELECTED_ELEMENTS',
        payload: [],
      });
      hoursDispatch({
        type: 'SET_MONTHLY_SELECTING_ELEMENTS',
        payload: false,
      });
      hoursDispatch({
        type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
        payload: null,
      });
    }
  };

  const section = useMemo(() => {
    return sections.find((x) => x.id === activeSection);
  }, [activeSection, sections]);

  const onArrowClick = (value: number) => {
    const date = moment(selectedDate).add(value, picker == 'operational' ? 'day' : picker);
    if (
      (picker === 'day' || picker == 'operational') &&
      !(activeDepartment?.scheduleParams as any)?.[lioWeekdays[date.weekday()]]
    ) {
      if (value > 0) {
        onArrowClick(value + 1);
      } else {
        onArrowClick(value - 1);
      }
      return;
    }
    const formattedDate = date.format('YYYY-MM-DD');
    history.push(`/app/hours/manage/${picker}/${formattedDate}`);
  };

  const handleAllSelectedShifts = () => {
    let shifts = shiftIds;
    if (!['ADMIN', 'SUPER-ADMIN'].includes(activeDepartment!.role!)) {
      shifts = shifts.filter((id) => shiftsMap.get(id)?.locked == false);
    }
    if (picker !== 'month') {
      if (selectedShifts.size === 0 || !allShiftsSelected) {
        if (activeSection) {
          hoursDispatch({
            type: 'SET_SELECTED_SHIFTS',
            payload: shifts.filter((id) => shiftsMap.get(id)?.section?.id === activeSection),
          });
        } else {
          hoursDispatch({
            type: 'SET_SELECTED_SHIFTS',
            payload: [...shifts],
          });
        }
      } else {
        hoursDispatch({
          type: 'SET_SELECTED_SHIFTS',
          payload: [],
        });
      }
    } else {
      setAllShiftsSelected(!allShiftsSelected);
      if (!allShiftsSelected) {
        hoursDispatch({
          type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
          payload: shifts.map((shift) => {
            return { id: shift };
          }),
        });
      } else {
        hoursDispatch({
          type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
          payload: null,
        });
      }
    }
  };

  const handleDeleteShifts = () => {
    hoursDispatch({
      type: 'SET_SHIFT_IDS_TO_DELETE',
      payload: [...selectedShifts],
    });
  };

  const handleCopyShifts = () => {
    copyShifts(hoursDispatch, {
      ids: [...selectedShifts],
    });
  };

  const handlePasteShifts = () => {
    hoursDispatch({
      type: 'SET_IS_LOADING',
      payload: true,
    });

    const shifts: IShift[] = [];
    const shiftsToCopy = clipboardHistory?.shifts || [];
    const difference = startDate.unix() - clipboardHistory!.startDate.unix();
    for (const shift of shiftsToCopy) {
      shifts.push({
        id: shift.id,
        start: shift.start! + difference,
        end: shift.end! + difference,
      });
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/shifts`,
        {
          shifts,
        },
        {
          params: {
            departmentId: activeDepartment?.id,
            sectionId: activeSection,
            picker,
            startDate: startDate.unix(),
            endDate: endDate.unix(),
          },
        },
      )
      .then((response) => {
        updateShiftsAndContextWithShiftResponse(hoursDispatch, response, {
          department: activeDepartment!,
          picker,
          startDate,
          endDate,
          activeSection,
          skills,
          userStatus: userCategories,
        });
        const { totalErrors = 0 } = response.data.message;
        if (totalErrors) {
          if (totalErrors > 1) {
            message.error(t('SCHEDULE.PASTE_SHIFTS_ERROR', { count: totalErrors }));
          } else {
            message.error(t('SCHEDULE.PASTE_SHIFT_ERROR'));
          }
        }
      })
      .catch((error) => {})
      .finally(() => {
        hoursDispatch({
          type: 'SET_IS_LOADING',
          payload: false,
        });
      });
    setMoreDropdownVisible(false);
  };

  const onGeneratePdf = () => {
    setIsGeneratingPdf(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/v3/operations/publish-shifts`, {
        sectionId: activeSection,
        departmentId: activeDepartment?.id,
        generate: true,
        start: startDate.unix(),
      })
      .then(() => {
        setIsGeneratingPdf(false);
        notification.open({
          message: t('SHIFTS.GENERATE_SCHEDULE.MESSAGE'),
          type: 'info',
        });
      })
      .catch(() => {
        setIsGeneratingPdf(false);
        notification.open({
          message: t('SHIFTS.GENERATE_SCHEDULE.ERROR'),
          type: 'error',
        });
      });
  };

  const onRemoveComparison = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/operations/week-comparison`,
        {
          startDate: startDate.unix(),
          shiftTemplateId: null,
          departmentId: activeDepartment?.id,
          sectionId: activeSection,
        },
        {
          params: {
            endDate: endDate.unix(),
            picker,
          },
        },
      )
      .then((response) => {
        updateShiftsAndContextWithShiftResponse(hoursDispatch, response, {
          department: activeDepartment!,
          picker,
          startDate,
          endDate,
          activeSection,
          skills,
          userStatus: userCategories,
        });
      })
      .catch((error) => {
        notification.open({
          message: t('GLOBAL.AN_ERROR_OCCURRED'),
          type: 'error',
        });
      });
  };

  const cancelMonthlySelectingElements = () => {
    hoursDispatch({
      type: 'SET_MONTHLY_SELECTING_ELEMENTS',
      payload: false,
    });
    monthly_selected_elements.map((el: HTMLElement) => el.classList.remove('highlight'));
    hoursDispatch({
      type: 'SET_MONTHLY_SELECTED_ELEMENTS',
      payload: [],
    });
  };

  const cancelSelectedOnShift = () => {
    setAllShiftsSelected(false);
    hoursDispatch({
      type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
      payload: null,
    });
  };

  const deleteSelectedShifts = () => {
    if (selected_shifts_on_shift_key) {
      hoursDispatch({
        type: 'SET_SHIFT_IDS_TO_DELETE',
        payload: selected_shifts_on_shift_key.map((shift) => shift.id) as any,
      });
    }
  };

  const onWeeklyViewChange = (value: string) => {
    const weekly_view = weeklyViews.find((wv) => wv.id == value);
    if (value && weekly_view) {
      hoursDispatch({
        type: 'SET_ACTIVE_WEEKLY_VIEW',
        payload: weekly_view,
      });
    } else {
      hoursDispatch({
        type: 'SET_ACTIVE_WEEKLY_VIEW',
        payload: null,
      });
    }
    let newSections: string[] = [];
    let newSkills: string[] = [];
    let newUsers: string[] = [];
    let newStatuses: string[] = [];
    let newAttributes: string[] = [];
    if (weekly_view?.sections) {
      newSections = weekly_view.sections.map((el) => `section.${el}`);
    }
    if (weekly_view?.skills) {
      newSkills = weekly_view.skills.map((el) => `skill.${el}`);
    }
    if (weekly_view?.users) {
      newUsers = weekly_view.users.map((el) => `user.${el}`);
    }
    if (weekly_view?.statuses) {
      newStatuses = weekly_view.statuses.map((el) => `userCategory.${el}`);
    }
    if (weekly_view?.attributes) {
      newAttributes = weekly_view.attributes.map((el) => `attribut.${el}`);
    }
    const res = [...newSections, ...newSkills, ...newUsers, ...newStatuses, ...newAttributes];
    batch(() => {
      hoursDispatch({
        type: 'SET_FILTER_OPTIONS',
        payload: res,
      });
      hoursDispatch({
        type: 'UPDATE_FILTERED_SHIFTS',
        payload: { department: activeDepartment },
      });
    });
  };

  const toggleActionsDrawer = () => {
    setHeaderSmallDrawerVisible(!headerSmallDrawerVisible);
  };

  let shyftTypes = [1];
  if (showAbsences) {
    shyftTypes = [1, 2];
  } else {
    shyftTypes = [1];
  }

  const shiftsByUsersWithoutFreeShifts = shiftsByUsers.map(({ user, shifts }) => ({
    user,
    shifts: shifts
      .filter(
        (shift) =>
          (!activeDepartment?.scheduleParams?.count_free_shift_in_head_count &&
            shift.shyftType &&
            shyftTypes.includes(shift.shyftType) &&
            shift.userRecordId !== null) ||
          (activeDepartment?.scheduleParams?.count_free_shift_in_head_count &&
            shift.shyftType &&
            shyftTypes.includes(shift.shyftType)) ||
          shift.userRecordId === null,
      )
      .filter((shift) => (shift.shyftType !== 2 ? true : shift.dayoff && shift.dayoff.paid == true ? true : false))
      .filter((shift) => shift.hide == false),
  }));

  const weekDates = getDatesBetweenDates(startDate, startDate);
  const valuesForSameDay = turnover
    ? weekDates
        .map((dateString) => {
          const timestamp = moment(dateString).unix();
          if (turnover.hasOwnProperty(timestamp)) {
            return turnover[timestamp] || null;
          } else {
            return null;
          }
        })
        .filter((value) => value !== null)
    : [];

  const totalHours = shiftsByUsersWithoutFreeShifts.reduce((result: any, item) => {
    item.shifts.forEach((shift) => {
      let shiftStart = moment.unix(shift.start!);
      let shiftEnd = moment.unix(shift.end!);
      const dayKey = shiftStart.format('YYYY-MM-DD');
      const unpaidPause = shift.pause?.unpaid;
      const paidPause = shift.pause?.paid;
      const durationInHours = shiftEnd.diff(shiftStart, 'hours', true);

      const turnoverForDay = turnover ? turnover[moment(dayKey).startOf('day').unix()] : null;

      if (turnoverForDay && turnoverForDay.type == 'real') {
        const workTime = shift.clocking
          ? shift.clocking.reduce((value, clocking) => {
              if (!clocking.end) {
                return value + 0;
              }
              return value + (clocking.worktime ? clocking.worktime / 60 : 0 || 0);
            }, 0)
          : shift.shyftType == 1
          ? durationInHours - (unpaidPause ? unpaidPause / 3600 : 0) + (paidPause ? paidPause / 3600 : 0)
          : durationInHours - (unpaidPause ? unpaidPause / 3600 : 0) + (paidPause ? paidPause / 3600 : 0) * 60;

        if (turnoverForDay && turnoverForDay.type == 'real' && !shift.approved) {
          result[dayKey] = (result[dayKey] || 0) + 0;
        } else {
          result[dayKey] = (result[dayKey] || 0) + workTime;
        }
      }

      if (shiftStart.isSame(startDate, 'day') && turnoverForDay && turnoverForDay.type != 'real') {
        result[dayKey] =
          (result[dayKey] || 0) +
          durationInHours -
          (unpaidPause ? unpaidPause / 3600 : 0) +
          (paidPause ? paidPause / 3600 : 0);
      }
    });

    weekDates.forEach((day) => {
      if (!result[day]) {
        result[day] = 0;
      }
    });

    return Object.keys(result)
      .sort()
      .reduce((sortedResult: any, key) => {
        sortedResult[key] = result[key];
        return sortedResult;
      }, {});
  }, {});

  const shiftsCost = shiftsByUsersWithoutFreeShifts.reduce((result: any, item) => {
    item.shifts.forEach((shift) => {
      const shiftMoment = moment.unix(shift.start!);

      // Check if the shift is in the current week
      if (shiftMoment.isSameOrAfter(startDate, 'day')) {
        const dayKey = shiftMoment.format('YYYY-MM-DD');
        const turnoverOfDay = turnover ? turnover[moment(dayKey).startOf('day').unix()] : null;
        const user = shift.userRecordId ? usersMap.get(shift.userRecordId!) : null;

        const price =
          shift.clocking && user && user.hourlyRate
            ? shift.clocking.reduce((result, item) => {
                if (!item.worktime) return result;
                if (!user.hourlyRate) return result;
                return result + (item.worktime / 60) * user.hourlyRate;
              }, 0)
            : shift.price;

        if (turnoverOfDay && turnoverOfDay.type == 'real' && !shift.approved) {
          result[dayKey] = (result[dayKey] || 0) + 0;
        } else {
          result[dayKey] = (result[dayKey] || 0) + price;
        }
      }
    });

    weekDates.forEach((day) => {
      if (!result[day]) {
        result[day] = 0;
      }
    });

    return Object.keys(result)
      .sort()
      .reduce((sortedResult: any, key) => {
        sortedResult[key] = result[key];
        return sortedResult;
      }, {});
  }, {});

  const productivityCoefficient = activeDepartment?.scheduleParams?.coefficient || 1;
  const productivityDefaultHours = activeDepartment?.scheduleParams?.default_hours || 0;
  const productivity = valuesForSameDay.map((data, index) => {
    const productivityCalculationType =
      activeDepartment?.scheduleParams?.productivity_calculation_type || 'HOURS_WORKED';
    if (data && data.value !== null) {
      if (productivityCalculationType === 'HOURS_WORKED') {
        return Number(
          Number(data.value / (totalHours[weekDates[index]] + productivityDefaultHours)) * productivityCoefficient,
        ).toFixed(2);
      } else {
        return Number(
          Number(data.value / (shiftsCost[weekDates[index]] + productivityDefaultHours)) * productivityCoefficient,
        ).toFixed(2);
      }
    } else {
      return null;
    }
  });

  const onCopyDay = () => {
    copyShifts(hoursDispatch, {
      ids: [...shiftIds],
    });
  };

  const onMultipleAySync = () => {
    setMoreDropdownVisible(false);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/ay/sync-multiple-shifts`,
        {
          shiftIds: [...selectedShifts],
        },
        { params: { departmentId: activeDepartment?.id } },
      )
      .then(() => {
        message.success(t('USERS.AY_USERS_SYNCED'));
        hoursDispatch({
          type: 'SET_SELECTED_SHIFTS',
          payload: [],
        });
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const onDeleteDay = () => {
    hoursDispatch({
      type: 'SET_SHIFT_IDS_TO_DELETE',
      payload: [...shiftIds],
    });
  };

  const MoreMenu = (
    <Menu style={{ borderRadius: 10, border: '1px solid #EFEFEF', padding: '5px 0' }}>
      {picker !== 'day' &&
        picker !== 'operational' &&
        filteredShiftsByUsers.reduce((a, b) => a + b.shifts.length, 0) > 0 && (
          <SelectAllShifts allShiftsSelected={allShiftsSelected} onClick={handleAllSelectedShifts} />
        )}
      {(picker == 'day' || picker == 'week') && <PrintSchedules onClick={() => setMoreDropdownVisible(false)} />}
      {picker == 'week' && (
        <SaveWeek
          setShowModalCreateShiftTemplate={setShowModalCreateShiftTemplate}
          onClick={() => setMoreDropdownVisible(false)}
        />
      )}
      {picker == 'week' && (
        <ImportWeek setImportWeekVisible={setImportWeekVisible} onClick={() => setMoreDropdownVisible(false)} />
      )}
      {picker == 'week' && <DuplicatePreviousWeek onClick={() => setMoreDropdownVisible(false)} />}
      {(picker == 'week' || picker == 'day') &&
        (activeSection
          ? quotaTemplates.filter((template) =>
              !isFeatureEnabled(features, FEATURES.SECTIONS)
                ? template.details.every((detail) => detail.section_id === null)
                : true,
            )
          : quotaTemplates.length > 1) && (
          <EditQuotaTemplate
            setModalEditQuotaTemplateVisible={setModalEditQuotaTemplateVisible}
            onClick={() => setMoreDropdownVisible(false)}
          />
        )}
      {picker == 'week' && activeDepartment?.ay_core && (
        <AySync selectedShifts={selectedShifts} onClick={onMultipleAySync} />
      )}
      {picker === 'day' && <CopyDay onClick={onCopyDay} />}
      {picker === 'week' && <CopyShifts selectedShifts={selectedShifts} onClick={handleCopyShifts} />}
      {picker === 'day' && <PasteDay clipboard={clipboardHistory} onClick={handlePasteShifts} />}
      {picker === 'week' && <PasteShifts clipboard={clipboardHistory} onClick={handlePasteShifts} />}
      {picker === 'day' && <DeleteDay shiftIds={shiftIds} onClick={onDeleteDay} />}
      {picker === 'week' && <DeleteShifts selectedShifts={selectedShifts} onClick={handleDeleteShifts} />}
      {picker === 'month' && (
        <DeleteMonth selected_shifts_on_shift_key={selected_shifts_on_shift_key} onClick={deleteSelectedShifts} />
      )}
    </Menu>
  );

  const MoreDropdown = (
    <Dropdown
      className="more-dropdown"
      overlay={MoreMenu}
      placement="bottomRight"
      visible={moreDropdownVisible}
      onVisibleChange={(value) => setMoreDropdownVisible(value)}
    >
      <Button className="trigger-btn" type="primary">
        {t('GLOBAL.MORE')} <i className="icon-down-dir" />
      </Button>
    </Dropdown>
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === ' ' || e.key === 'Spacebar') {
      e.preventDefault();
    }
  };

  return (
    <header className={className}>
      <div className="left" style={{ gap: picker == 'operational' ? 10 : 0 }}>
        <div className="actions">
          <Button type="ghost" onClick={toggleActionsDrawer}>
            <i className="icon-settings" />
          </Button>
        </div>
        {picker !== 'operational' && (
          <div className="select-view-container">
            {picker == 'week' && !loadingWeeklyViews && weeklyViews && weeklyViews.length ? (
              <Select
                placeholder={<p style={{ color: '#fff' }}>{t('WEEKLY_VIEWS.PLACEHOLDER')}</p>}
                style={{ marginRight: 10 }}
                allowClear
                onChange={onWeeklyViewChange}
                value={active_weekly_view?.id}
                className="weekly-views-selector"
              >
                {weeklyViews.map((wv) => (
                  <Select.Option key={`weekly_view_${wv.id}`} value={wv.id!}>
                    {wv.name}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              ''
            )}
          </div>
        )}
        {picker == 'operational' && <OperationalViewFilters />}
        <div className="radio-options-container">
          <Radio.Group
            value={picker}
            onChange={(e) => {
              history.push(`/app/hours/manage/${e.target.value}/${selectedDate.format('YYYY-MM-DD')}`);
            }}
            buttonStyle="solid"
            className="light"
          >
            <>
              {activeDepartment?.scheduleParams?.schedule_views?.includes('day') && (
                <Radio.Button value="day">{t('GLOBAL.DAY')}</Radio.Button>
              )}
              {activeDepartment?.scheduleParams?.schedule_views?.includes('ops') && (
                <Radio.Button value="operational">OPS</Radio.Button>
              )}
              <Radio.Button value="week">{t('GLOBAL.WEEK')}</Radio.Button>
            </>
            {windowSize.innerWidth > 900 && activeDepartment?.scheduleParams?.schedule_views?.includes('month') && (
              <Radio.Button value="month">{t('GLOBAL.MONTH')}</Radio.Button>
            )}
          </Radio.Group>
          {picker !== 'operational' && (
            <div className="options-container">
              <Options>
                <Popover placement="bottomLeft" content={() => <HeaderOptionDetails department={activeDepartment} />}>
                  <div className="option">
                    <i className="icon-toggle-on" />
                  </div>
                </Popover>
              </Options>
              {picker !== 'month' && (
                <div>
                  {t('SCHEDULE.WEEK_FIRST_LETTER')}
                  {startDate.isoWeek()}
                  {cycleNumber && `(${cycleNumber})`}
                </div>
              )}
            </div>
          )}
        </div>
        {productivity && productivity[0] && picker == 'day' && (
          <div className="productivity-index">
            <b style={{ color: colors.blue }}>PI</b>
            <Divider type="vertical" />
            {turnover && turnover[startDate.startOf('day').unix()] && (
              <span
                style={{
                  color:
                    turnover[startDate.startOf('day').unix()].type == 'real' ? colors.green : colors.blueLightPastel,
                }}
              >
                {isNaN(turnover[startDate.startOf('day').unix()].value) ||
                turnover[startDate.startOf('day').unix()].value == Infinity
                  ? '-'
                  : turnover[startDate.startOf('day').unix()].value}
              </span>
            )}
            {productivity[0] && productivity[0] !== 'NaN' && productivity[0] !== 'Infinity' && !isNaN(productivity[0]) && (
              <>
                <Divider type="vertical" />
                {<span style={{ color: 'blueviolet', fontWeight: 'bold' }}>{productivity[0]}</span>}
              </>
            )}
          </div>
        )}
        {picker === 'week' && filterOptions?.length > 0 && !active_weekly_view && (
          <div className="save-view">
            <Button shape="circle" className="action" onClick={() => setSaveViewModalVisible(true)}>
              <i className="icon-save" />
              {t('SCHEDULE.SAVE_VIEW')}
            </Button>
          </div>
        )}
        {picker === 'week' && active_weekly_view && (
          <div className="delete-view">
            <Button shape="circle" className="action" onClick={() => setDeleteViewModalVisible(true)}>
              <i className="icon-save" />
              {t('SCHEDULE.DELETE_VIEW')}
            </Button>
          </div>
        )}
        {(picker === 'day' || picker == 'operational') &&
          !!isFeatureEnabled(features, FEATURES.TASKS) &&
          !!tasks?.length && (
            <Button
              shape="circle"
              type={taskScheduler ? 'primary' : 'default'}
              onClick={() => {
                const container = document.getElementsByClassName('app-hours-container')?.[0];
                if (container) {
                  (container as HTMLElement).style.maxHeight = !taskScheduler
                    ? 'calc(100vh - 80px - 50px)'
                    : 'calc(100vh - 80px)';
                }
                hoursDispatch({
                  type: 'SET_TASK_SCHEDULER',
                  payload: !taskScheduler,
                });
              }}
              style={{ marginLeft: 10 }}
            >
              T
            </Button>
          )}
        {picker === 'week' && comparisonTemplate && (
          <Button onClick={() => setShowModalChooseShiftTemplate('compare')}>
            <i className="icon-compare" style={{ marginRight: 5 }} /> {comparisonTemplate?.name}
            {comparisonTemplate?.name && <CloseOutlined onClick={onRemoveComparison} style={{ color: colors.grey }} />}
          </Button>
        )}
        {section && (
          <div className="sectionIndicator">
            <i style={{ backgroundColor: section.background }} />
            {section.name}
          </div>
        )}
      </div>

      <div className="center">
        <DateSelector
          picker={picker}
          startDate={startDate}
          endDate={endDate}
          onArrowClick={onArrowClick}
          onDatePickerChange={onDatePickerChange}
          disabledDate={(date) => {
            return !(activeDepartment?.scheduleParams as any)?.[lioWeekdays[date.weekday()]];
          }}
          onKeyDown={handleKeyDown}
        />
      </div>

      <div className="right">
        <div className="actions">
          {picker == 'week' &&
            isFeatureEnabled(features, FEATURES.PRODUCTIVITY) &&
            !shiftsByUsers.every((item) => item.shifts.length === 0) && (
              <Tooltip title={showStats ? t('SCHEDULE.STATS.HIDE_STATS') : t('SCHEDULE.STATS.SHOW_STATS')}>
                <Button
                  shape="circle"
                  className="action"
                  onClick={() => {
                    setShowStats(!showStats);
                    localStorage.setItem(`schedule-stats-${activeDepartment?.id}`, showStats ? 'false' : 'true');
                  }}
                >
                  <i className="icon-chart-bar" style={{ color: showStats ? colors.green : '' }} />
                </Button>
              </Tooltip>
            )}
          {picker !== 'operational' && <div className="more-dropdown-container">{MoreDropdown}</div>}
          {/*
          {picker != 'operational' && (
            <div className="selections">
              {picker === 'month' && monthly_selected_elements.length > 0 && (
                <div className="monthly_selected_elements">
                  <p>
                    {monthly_selected_elements.length} {t('SCHEDULE.MONTHLY_VIEW.HEADER.SELECTIONS')}
                  </p>
                  <Button onClick={cancelMonthlySelectingElements}>
                    {t('SCHEDULE.MONTHLY_VIEW.HEADER.CANCEL_SELECTION')}
                  </Button>
                </div>
              )}
              {picker === 'month' && selected_shifts_on_shift_key && selected_shifts_on_shift_key.length > 0 && (
                <div className="monthly_selected_on_shift">
                  <p>
                    {selected_shifts_on_shift_key.length} {t('SCHEDULE.MONTHLY_VIEW.HEADER.SHIFTS_SELECTED')}
                  </p>
                  <Button onClick={cancelSelectedOnShift}>{t('SCHEDULE.MONTHLY_VIEW.HEADER.CANCEL_SELECTION')}</Button>
                </div>
              )}
            </div>
          )}
          {picker == 'month' && selected_shifts_on_shift_key && selected_shifts_on_shift_key.length > 0 && (
            <Tooltip title={t('SCHEDULE.MONTHLY_VIEW.HEADER.DELETE_ALL_SELECTED_SHIFTS')}>
              <Button
                danger
                shape="circle"
                className="action"
                disabled={selected_shifts_on_shift_key?.length <= 0}
                onClick={deleteSelectedShifts}
              >
                <i className="icon-trash-empty" />
              </Button>
            </Tooltip>
          )} */}
          {picker !== 'operational' && <GenerateScheduleModels setIsGeneratingSchedule={setIsGeneratingSchedule} />}
          {picker !== 'operational' && (
            <PublishShifts
              isLoading={isLoading}
              shiftNotApprovedIds={shiftNotApprovedIds}
              shiftsToApprove={shiftsToApprove}
            />
          )}
          <GeneratePdf isLoading={isLoading} shiftNotApprovedIds={shiftNotApprovedIds} />
        </div>
      </div>
      <ModalPasteMultipleFreeShifts
        visible={pasteMultipleFreeShiftsVisible}
        onClose={() => hoursDispatch({ type: 'SET_PASTE_MULTIPLE_FREE_SHIFTS_MODAL_VISIBLE', payload: false })}
      />
      <ModalSaveView visible={saveViewModalVisible} onClose={() => setSaveViewModalVisible(false)} />
      <ModalDeleteView
        visible={deleteViewModalVisible}
        onClose={() => {
          hoursDispatch({
            type: 'SET_ACTIVE_WEEKLY_VIEW',
            payload: null,
          });
          setDeleteViewModalVisible(false);
        }}
        view={active_weekly_view!}
      />
      <ModalAddOccurrences
        visible={addOccurrencesModalVisible}
        onClose={() => hoursDispatch({ type: 'SET_ADD_OCCURRENCES_MODAL_VISIBLE', payload: false })}
      />
      <ModalAssignShift
        visible={assignModalVisible}
        onClose={() => hoursDispatch({ type: 'SET_ASSIGN_MODAL_VISIBLE', payload: false })}
      />
      <ModalDeleteShiftConfirmation
        shiftIds={shiftIdsToDelete}
        visible={!!shiftIdsToDelete}
        onClose={() => {
          hoursDispatch({ type: 'SET_SHIFT_IDS_TO_DELETE', payload: null });
        }}
        onSubmit={() => {
          hoursDispatch({
            type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
            payload: null,
          });
        }}
      />
      <ModalChooseShiftTemplate
        start={startDate.unix()}
        mode={showModalChooseShiftTemplate}
        onClose={() => setShowModalChooseShiftTemplate(null)}
      />
      <ModalCreateShiftTemplate
        start={startDate.unix()}
        visible={showModalCreateShiftTemplate}
        onClose={() => setShowModalCreateShiftTemplate(false)}
      />
      <ModalPublishShifts
        shiftIds={shiftIdsToPublish}
        visible={!!shiftIdsToPublish}
        sectionId={activeSection}
        departmentId={activeDepartment?.id}
        onClose={() => hoursDispatch({ type: 'SET_SHIFT_IDS_TO_PUBLISH', payload: null })}
      />
      <ModalTransferShifts
        shiftsToTransfer={shiftsToTransfer}
        visible={!!shiftsToTransfer}
        onClose={() => hoursDispatch({ type: 'SET_SHIFTS_TO_TRANSFER', payload: null })}
      />
      <ModalGeneratingSchedule visible={isGeneratingSchedule} />
      <HeaderSmallDrawer
        visible={headerSmallDrawerVisible}
        toggleDrawer={toggleActionsDrawer}
        picker={picker}
        loadingWeeklyViews={loadingWeeklyViews}
        weeklyViews={weeklyViews}
        onWeeklyViewChange={onWeeklyViewChange}
        active_weekly_view={active_weekly_view}
        setSaveViewModalVisible={setSaveViewModalVisible}
        setShowModalChooseShiftTemplate={setShowModalChooseShiftTemplate}
        setIsGeneratingSchedule={setIsGeneratingSchedule}
        isLoading={isLoading}
        shiftNotApprovedIds={shiftNotApprovedIds}
        shiftsToApprove={shiftsToApprove}
      />
      <ModalImportWeek visible={importWeekVisible} setVisible={setImportWeekVisible} />
      <ModalEditQuotaTemplate
        picker={picker}
        visible={modalEditQuotaTemplateVisible}
        setVisible={setModalEditQuotaTemplateVisible}
      />
    </header>
  );
};

const HeaderStyled = styled(React.memo(Header))`
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;

  .right {
    .more-dropdown-container {
      margin-right: 10px;
      margin-left: 5px;

      .more-dropdown {
        background-color: #f1f1f1;
        border-color: #f1f1f1;
        color: #222;
        padding: 5px 8px 5px 15px;
        border-radius: 8px;

        &:hover,
        &:focus,
        &.ant-dropdown-open {
          background-color: ${colors.greenLight};
          border-color: ${colors.green};
          color: ${colors.green};
        }
      }
    }
  }

  .weekly-views-selector {
    .ant-select-selector {
      background-color: ${colors.green} !important;
      border: none !important;
    }

    .ant-select-arrow,
    .ant-select-selection-item,
    .ant-select-clear {
      color: #fff !important;
      background-color: transparent !important;
    }
  }

  .save-view {
    .action {
      font-weight: normal;
      color: ${colors.green};
    }
  }

  .delete-view {
    .action {
      font-weight: normal;
      color: ${colors.red};
    }
  }

  .selections {
    display: flex;
    gap: 10px;

    .monthly_selected_elements,
    .monthly_selected_on_shift {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        margin: 0;
        margin-right: 10px;
      }
    }
  }

  .tab-view {
    label {
      padding: 0 8px;
    }
  }

  .left {
    display: flex;

    .actions {
      display: none;
    }

    .productivity-index {
      border: 1px solid ${colors.greyLight};
      color: ${colors.grey};
      border-radius: 4px;
      padding: 4px 8px;
      margin: 0 10px;
    }

    .sectionIndicator  {
      display: flex;
      align-items: center;
      border: 1px solid ${colors.greyLight};
      border-radius: 4px;
      height: 28px;
      overflow: hidden;
      padding-right: 10px;
      font-weight: bold;
      margin-left: 10px;

      i {
        width: 28px;
        height: 28px;
        margin-right: 10px;
      }
    }
  }

  .center {
    position: absolute;
    left: 50%;
    top: 0px;
    transform: translate(-50%, 20px);
  }

  .date-selector {
    display: flex;
    button {
      padding: 0;
      i {
        font-size: 22px;
        line-height: 12px;
      }
    }

    .datePicker {
      text-align: center;
      width: fit-content;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      i {
        font-size: 16px;
        line-height: 12px;
      }
    }
  }

  .beta-tag {
    background-color: ${colors.red};
    color: #fff;
    padding: 2px 7px;
    border-radius: 5px;
    align-self: center;
    margin-left: 10px;
  }

  .radio-options-container {
    display: flex;
    gap: 10px;

    .options-container {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    gap: 15px;

    .left {
      width: 100%;
      justify-content: center;

      .actions {
        display: block;
        position: absolute;
        right: 20px;
        top: 20px;
      }

      .select-view-container {
        display: none;
      }

      > div {
        flex-direction: column;
        gap: 0;
      }

      .radio-options-container {
        display: flex;
        gap: 10px;
        flex-direction: row;
      }

      .beta-tag {
        align-self: center;
        margin-left: 15px;
      }
    }

    .center {
      position: relative;
      left: 0;
      top: 0;
      transform: translate(0, 0);
    }

    .right {
      display: none;
    }
  }
`;

export default HeaderStyled;
