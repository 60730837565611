import { setToken } from '@/actions';
import AuthContext from '@/context';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { IAccountPricing } from '@/types/pricing.model';
import { formatPrice, handleError } from '@/utils';
import { Button, Modal, Spin } from 'antd';
import { default as Axios, default as axios } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Script from 'react-load-script';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { store as dashboardStore } from '../../../dashboard/redux/store';
import { store as manageStore } from '../../../hours/manage/redux/store';

interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings;
}

const Payement: React.FC<Props> = ({ className, currentSettings }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(undefined, { useSuspense: false });
  const locationRef = useLocation<any>();
  const [loadingChangePayementMethod, setloadingChangePayementMethod] = useState<boolean>(false);
  const {
    dispatch: authContextDispatch,
    state: { impersonate },
  } = useContext(AuthContext);
  const history = useHistory();
  const {
    state: { activeDepartment },
    dispatch: appContextDispatch,
  } = useContext(AppContext);
  const [loadingPricing, setLoadingPricing] = useState<boolean>(false);
  const [pricing, setPricing] = useState<IAccountPricing[]>([]);

  useEffect(() => {
    if (!activeDepartment) return;

    setLoadingPricing(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/operations/pricing-plan`, {
        params: {
          departmentId: activeDepartment?.id,
        },
      })
      .then(({ data }) => {
        setPricing(data);
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoadingPricing(false);
      });
  }, [activeDepartment]);

  const onScriptLoad = () => {
    (window as any).Chargebee.registerAgain();
  };

  const onCheckout = () => {
    setloadingChangePayementMethod(true);
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/operations/update-method`, {
      params: {
        departmentId: activeDepartment?.id,
      },
    })
      .then(({ data }) => {
        location.href = data.url;
        setloadingChangePayementMethod(false);
      })
      .catch((error) => {
        console.log(error);
        setloadingChangePayementMethod(false);
      });
  };

  const onWantToDeleteDemoData = () => {
    Modal.confirm({
      title: t('GLOBAL.DELETION'),
      icon: null,
      content: t('ACCOUNT.MODAL_DELETE_DEMO_DATA.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.REMOVE'),
      okType: 'danger',
      onOk: () => {
        onDeleteDemoData();
      },
      onCancel: () => {},
    });
  };

  const onDeleteDemoData = () => {
    Axios.post(`${process.env.REACT_APP_API_URL}/v3/operations/delete-demo-content`, null, {
      params: {
        departmentId: activeDepartment?.id,
      },
    }).then(() => {
      location.reload();
    });
  };

  const onChangePayementMethod = () => {
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/operations/update-method`, {
      params: {
        departmentId: activeDepartment?.id,
      },
    })
      .then(({ data }) => {
        setloadingChangePayementMethod(false);
        location.href = data.url;
      })
      .catch((error) => {
        setloadingChangePayementMethod(false);
        console.log(error);
      });
  };

  const onPauseAccount = () => {
    Modal.confirm({
      className: 'modal-constraints',
      closable: true,
      maskClosable: true,
      width: 520,
      title: t('SETTINGS.BILLING.PAYEMENT.CLOCKING_ACCOUNT.PAUSE_ACCOUNT_TITLE'),
      icon: null,
      content: (
        <p
          dangerouslySetInnerHTML={{
            __html: t('SETTINGS.BILLING.PAYEMENT.CLOCKING_ACCOUNT.PAUSE_ACCOUNT_DESCRIPTION'),
          }}
        ></p>
      ),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('SETTINGS.BILLING.PAYEMENT.CLOCKING_ACCOUNT.PAUSE_ACCOUNT'),
      onOk: () => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/v3/operations/pause-account`, {
            params: {
              departmentId: activeDepartment?.id,
            },
          })
          .then(({ data }) => {
            console.log(data);
          })
          .catch((error) => {
            handleError(error);
          })
          .finally(() => {});
      },
    });
  };

  const onCloseAccount = () => {
    Modal.confirm({
      className: 'modal-danger',
      closable: true,
      maskClosable: true,
      width: 520,
      title: t('SETTINGS.BILLING.PAYEMENT.CLOCKING_ACCOUNT.CLOSE_ACCOUNT_TITLE'),
      icon: null,
      content: (
        <p
          dangerouslySetInnerHTML={{
            __html: t('SETTINGS.BILLING.PAYEMENT.CLOCKING_ACCOUNT.CLOSE_ACCOUNT_DESCRIPTION'),
          }}
        ></p>
      ),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('SETTINGS.BILLING.PAYEMENT.CLOCKING_ACCOUNT.CLOSE_ACCOUNT'),
      onOk: () => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/v3/operations/cancel-account`, {
            params: {
              departmentId: activeDepartment?.id,
            },
          })
          .then(({ data }) => {
            console.log(data);
            const accessToken = localStorage.getItem('accessToken');
            if (impersonate && accessToken) {
              sessionStorage.removeItem('impersonateToken');
              history.push('/app');
              setToken(authContextDispatch, accessToken);
            } else {
              history.push('/app');
              localStorage.clear();
              manageStore.dispatch({
                type: 'RESET',
              });
              dashboardStore.dispatch({
                type: 'RESET',
              });
              authContextDispatch({
                type: 'RESET',
              });
              appContextDispatch({
                type: 'RESET',
              });
            }
          })
          .catch((error) => {
            handleError(error);
          })
          .finally(() => {});
      },
    });
  };

  return (
    <div className={className}>
      <Script
        url="https://js.chargebee.com/v2/chargebee.js"
        attributes={{ 'data-cb-site': process.env.REACT_APP_CHARGEBEE_DATA_CB_SITE }}
        onLoad={onScriptLoad}
      />
      <h2>{t('SETTINGS.BILLING.PAYEMENT.TITLE')}</h2>
      {!['PAID', 'FREE'].includes(currentSettings?.account?.accountType || '') && (
        <div style={{ backgroundColor: 'white', padding: 25, borderRadius: 10 }}>
          <h3>{t('SETTINGS.BILLING.PAYEMENT.TRY_ACCOUNT.TITLE')}</h3>
          <div className="container">
            <div className="header">
              <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                {loadingPricing ? (
                  <Spin />
                ) : (
                  <>
                    {pricing.map((pricing) => (
                      <span>
                        <span style={{ fontWeight: 'bold' }}>{formatPrice(pricing.price)}</span>
                        <span> - {pricing.desc}</span>
                      </span>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
          <div style={{ marginBottom: 15 }}>
            <div>{t('MODAL_SWITCH_TO_PRO.FEATURES')}</div>
            <p className="subtitle">
              {t('MODAL_SWITCH_TO_PRO.NOTE')} <span className="accent">{t('MODAL_SWITCH_TO_PRO.NOTE_ACCENT')}</span>
            </p>
          </div>
          <Button
            type="primary"
            className="ant-btn-md"
            style={{ display: 'block', marginBottom: 10 }}
            onClick={() => {
              onCheckout();
            }}
            loading={loadingChangePayementMethod}
          >
            {t('SETTINGS.BILLING.PAYEMENT.TRY_ACCOUNT.SUBSCRIBE_BUTTON')}
          </Button>
        </div>
      )}
      {['PAID', 'FREE'].includes(currentSettings?.account?.accountType || '') && (
        <div style={{ backgroundColor: 'white', padding: 25, borderRadius: 10 }}>
          <h3>{t('SETTINGS.BILLING.PAYEMENT.PRO_ACCOUNT.TITLE')}</h3>
          <p>{t('SETTINGS.BILLING.PAYEMENT.PRO_ACCOUNT.DESCRIPTION')}</p>
          <div className="container">
            <div className="header">
              <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                {loadingPricing ? (
                  <Spin />
                ) : (
                  <>
                    {pricing.map((pricing) => (
                      <span>
                        <span style={{ fontWeight: 'bold' }}>{formatPrice(pricing.price)}</span>
                        <span> - {pricing.desc}</span>
                      </span>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
          <Button
            loading={loadingChangePayementMethod}
            type="primary"
            className="ant-btn-md"
            onClick={onChangePayementMethod}
          >
            {t('ACCOUNT.MODAL_BLOCKED_ACCOUNT.BUTTON')}
          </Button>
        </div>
      )}
      {currentSettings?.account?.demoData && (
        <div style={{ backgroundColor: 'white', padding: 25, borderRadius: 15 }}>
          <p>{t('SETTINGS.BILLING.PAYEMENT.PRO_ACCOUNT.HAS_DEMO_DATA')}</p>
          <Button
            type="primary"
            className="ant-btn-md"
            danger
            style={{ display: 'block', marginBottom: 10 }}
            onClick={() => {
              onWantToDeleteDemoData();
            }}
          >
            {t('SETTINGS.BILLING.PAYEMENT.PRO_ACCOUNT.REMOVE_DEMO_DATA')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default styled(Payement)`
  display: flex;
  flex-direction: column;
  gap: 20px;

  h3 {
    font-size: 16px !important;
    font-weight: bold !important;
  }
  #chargeBeeSubscribeButton {
    display: none !important;
  }

  .features {
    background-color: #eeeeee;
    padding: 25px;
    display: inline-block;
    text-align: start;
    margin: 15px 0;
    margin-top: 5px;
  }

  .subtitle {
    margin-top: 1em;
    font-weight: bold;

    .accent {
      color: ${colors.green};
      font-weight: bold;
    }
  }
`;
