import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import colors from '@/styles/colors';
import { Button, Form, InputNumber, message, Spin, Tabs } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { useTranslation } from 'react-i18next';
import Chartjs from 'chart.js';
import Axios from 'axios';
import CustomDatePicker from '@/pages/app/components/CustomDatePicker';
import AppContext from '@/pages/app/context';
import { FEATURES } from '@/types/features.model';
import { isFeatureEnabled } from '@/utils';
import SectionTab from '../../components/SectionTab';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  className?: string;
}

const InsightDailyTurnover: React.FC<Props> = ({ className }) => {
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { activeDepartmentId, sections, features },
    dispatch,
  } = useContext(AppContext);
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState<Chartjs | null>(null);
  const [loadingChart, setLoadingChart] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Moment>(moment().startOf('month'));
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [days, setDays] = useState<Moment[]>([]);
  const [form] = Form.useForm();
  const [activeKey, setActiveKey] = useState<string>('null');

  useEffect(() => {
    if (activeKey) return;
    if (!isFeatureEnabled(features, FEATURES.SECTIONS)) return;
    if (sections.length > 0) {
      setActiveKey(sections[0].id!);
    }
  }, [sections, activeKey]);

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();
    form.resetFields();
    setFormHasChanged(false);
    if (!activeDepartmentId) {
      return;
    }

    setLoadingChart(true);
    if (chartInstance) {
      chartInstance.destroy();
    }
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/insights/daily-turnover`, {
      params: {
        departmentId: activeDepartmentId,
        start: startDate.unix(),
        sectionId: isFeatureEnabled(features, FEATURES.SECTIONS) ? (activeKey == 'null' ? null : activeKey) : null,
      },
      cancelToken: cancelTokenSource.token,
    })
      .then((response) => {
        if (mounted) {
          setLoadingChart(false);
          const { real, provisional, lastYear } = response.data;
          setFieldValues({ real, provisional, lastYear });
        }
      })
      .catch((error) => {
        if (!Axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setLoadingChart(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [activeDepartmentId, startDate, activeKey]);

  useEffect(() => {
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
  }, [i18n.language]);

  useEffect(() => {
    let currentUnix = moment(startDate).unix();
    const endDateUnix = moment(startDate).endOf('month').unix();
    const days = [];
    while (currentUnix < endDateUnix) {
      days.push(moment.unix(currentUnix));
      currentUnix += 86400;
    }
    setDays(days);
  }, [startDate]);

  const setFieldValues = ({ real, provisional, lastYear }: { real: any; provisional: any; lastYear: any }) => {
    form.setFieldsValue({ real, provisional });
    const newChartInstance = new Chartjs(chartContainer.current!, {
      type: 'line',
      data: {
        datasets: [
          {
            label: moment(startDate).subtract(1, 'year').format('YYYY'),
            data: Object.entries(lastYear).map((data: any) => ({ x: moment.unix(data[0]).add(1, 'year'), y: data[1] })),
            backgroundColor: 'rgba(255,0,0,0.3)',
            fill: 'start',
          },
          {
            label: startDate.format('YYYY'),
            data: Object.entries(real).map((data: any) => ({ x: moment.unix(data[0]), y: data[1] })),
            backgroundColor: 'rgba(0,0,255,0.3)',
            fill: 'start',
          },
        ],
      },
      options: {
        spanGaps: true,
        maintainAspectRatio: false,
        responsive: true,
        elements: {
          line: {
            tension: 0.5,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              type: 'time',
              time: {
                unit: 'day',
              },
            },
          ],
        },
      },
    });
    setChartInstance(newChartInstance);
    setFormHasChanged(false);
  };

  const onArrowClick = (n: number) => {
    const m = moment(startDate).add(n, 'month');
    setStartDate(m);
  };

  const onCopyLastMonth = () => {
    Axios.post(
      `${process.env.REACT_APP_API_URL}/v3/operations/insights/daily-turnover/copy-last-month`,
      {},
      {
        params: {
          departmentId: activeDepartmentId,
          start: startDate.unix(),
        },
      },
    )
      .then(({ data }) => {
        const { real, provisional, lastYear } = data;
        setFieldValues({ real, provisional, lastYear });
        setIsSaving(false);
        setLoadingChart(false);
      })
      .catch((error) => {
        message.error(t('INSIGHTS.DAILY_TURNOVER.MESSAGE_COPYING_ERROR'));
        setIsSaving(false);
        setLoadingChart(false);
        console.log(error);
      });
  };

  const onFinish = (values: any) => {
    setLoadingChart(true);
    setIsSaving(true);
    Axios.post(
      `${process.env.REACT_APP_API_URL}/v3/insights/daily-turnover`,
      {
        ...values,
      },
      {
        params: {
          departmentId: activeDepartmentId,
          start: startDate.unix(),
          sectionId: isFeatureEnabled(features, FEATURES.SECTIONS) ? (activeKey == 'null' ? null : activeKey) : null,
        },
      },
    )
      .then(({ data }) => {
        const { real, provisional, lastYear } = data;
        setFieldValues({ real, provisional, lastYear });
        setIsSaving(false);
        setLoadingChart(false);
      })
      .catch((error) => {
        message.error(t('INSIGHTS.DAILY_TURNOVER.MESSAGE_SAVING_ERROR'));
        setIsSaving(false);
        setLoadingChart(false);
        console.log(error);
      });
  };

  const onChangeTab = (key: string) => {
    setActiveKey(key);
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.INSIGHTS.DAILY_TURNOVER.TITLE')}</h2>
      <header>
        <button onClick={() => onArrowClick(-1)}>
          <i className="icon-angle-left" />
        </button>
        <CustomDatePicker
          picker="month"
          startDate={startDate}
          onDatePickerChange={(date) => setStartDate(date || moment().startOf('month'))}
        />
        <button onClick={() => onArrowClick(1)}>
          <i className="icon-angle-right" />
        </button>
        <Button onClick={onCopyLastMonth} style={{ position: 'absolute', right: 20 }}>
          {t('SETTINGS.INSIGHTS.DAILY_TURNOVER.COPY_LAST_MONTH')}
        </Button>
      </header>
      <Spin spinning={loadingChart}>
        <div className="chart-container" style={{ position: 'relative', width: '100%', height: 200 }}>
          <canvas ref={chartContainer} />
        </div>
      </Spin>
      {isFeatureEnabled(features, FEATURES.SECTIONS) && sections && sections.length > 0 && (
        <Tabs onChange={onChangeTab}>
          <Tabs.TabPane key="null" tab={t('GLOBAL.GENERAL')}></Tabs.TabPane>
          {sections.map((section) => (
            <Tabs.TabPane
              key={section.id}
              tab={<SectionTab section={section} hasValues activeKey={activeKey} />}
            ></Tabs.TabPane>
          ))}
        </Tabs>
      )}
      <div className="form-header">
        <div>
          <span>{t('SETTINGS.INSIGHTS.DAILY_TURNOVER.PROVISIONAL')}</span>
          <span>{t('SETTINGS.INSIGHTS.DAILY_TURNOVER.REAL')}</span>
        </div>
        <div>
          <span>{t('SETTINGS.INSIGHTS.DAILY_TURNOVER.PROVISIONAL')}</span>
          <span>{t('SETTINGS.INSIGHTS.DAILY_TURNOVER.REAL')}</span>
        </div>
      </div>
      <Form form={form} onFinish={onFinish} onValuesChange={() => setFormHasChanged(true)} size="large">
        {days.map((day) => {
          const unix = day.unix();
          return (
            <div key={unix} className="day">
              <span className="L">{day.format('L')}</span>
              <span className="weekday">{day.format('dddd')}</span>
              <FormItem name={['provisional', `${unix}`]}>
                <InputNumber type="number" />
              </FormItem>
              <FormItem name={['real', `${unix}`]}>
                <InputNumber type="number" />
              </FormItem>
            </div>
          );
        })}
        <Form.Item className={`actions ${formHasChanged ? 'active' : ''}`}>
          <Button type="primary" htmlType="submit" loading={isSaving} style={{ width: '100%' }}>
            {t('GLOBAL.SAVE')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default styled(InsightDailyTurnover)`
  header {
    border-radius: 5px;
    background-color: ${colors.greyLight};
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form-header {
    display: flex;
    > div {
      margin-bottom: 15px;
      display: flex;
      width: 50%;
      span {
        flex: 1;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        &:nth-of-type(1) {
          margin-left: 185px;
          margin-right: 10px;
        }
      }
      &:nth-of-type(1) {
        margin-right: 20px;
      }
      &:nth-of-type(2) {
        margin-left: 20px;
      }
    }
  }

  form {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    .day {
      width: 50%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &:nth-of-type(odd) {
        padding-right: 20px;
      }
      &:nth-of-type(even) {
        padding-left: 20px;
      }
      .L {
        width: 100px;
      }
      .weekday {
        width: 80px;
        text-align: right;
        margin-right: 10px;
      }
      .ant-row.ant-form-item {
        flex: 1;
        margin-bottom: 0;
        width: 100%;
        &:first-of-type {
          margin-right: 5px;
        }
        &:nth-of-type(2) {
          margin-left: 5px;
        }
        .ant-input-number.ant-input-number-lg {
          width: 100%;
        }
      }
    }
    > .actions {
      position: fixed;
      width: 100%;
      max-width: 1024px;
      background: white;
      bottom: -80px;
      margin: 0;
      display: flex;
      box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.15);
      padding: 10px;
      border-radius: 3px 3px 0 0;
      transition: bottom 0.2s;
      z-index: 2;

      &.active {
        transition-timing-function: ease-in;
        bottom: 0px;
      }

      .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;
