import ContainerView from '@/layouts/ContainerView';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { RESOURCE_TYPES } from '@/types/field.model';
import { IUserRole } from '@/types/user-role.model';
import { IUser } from '@/types/user.model';
import { getResourceTranslated } from '@/utils';
import { Avatar, Space } from 'antd';
import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import CollaboratorsList from './List';
import AppTeamCollaboratorPage from './collaborator';

export type ExportMode = 'XLS' | 'PDF' | null;

const defaultFields: string[] = [RESOURCE_TYPES.EMAIL, RESOURCE_TYPES.PHONE];

const AppTeamCollaboratorsPage: React.FC = () => {
  const {
    state: { users, loadingUsers, usersFields, activeDepartmentId, activeDepartment, activeSection },
    dispatch: appContextDispatch,
  } = useContext(AppContext);
  const { path, url } = useRouteMatch();
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);
  const [defaultColumns, setDefaultColumns] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [displayedColumns, setDisplayedColumns] = useState<any[]>([]);
  const [columnFilters, setColumnFilters] = useState<
    Map<string, { name: string; value: boolean; resourceType?: string }>
  >(new Map());
  const history = useHistory();

  const [filterSearch, setFilterSearch] = useState<string>('');
  const [filterStatus, setFilterStatus] = useState<string | null>(null);
  const [filterRole, setFilterRole] = useState<string | null>(null);
  const [exportMode, setExportMode] = useState<ExportMode>(null);
  const location = useLocation();

  useEffect(() => {
    setDefaultColumns([
      {
        title: t('GLOBAL.DISPLAY_NAME'),
        key: 'displayName',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: any) => {
          return (
            <Space>
              <Avatar src={record.picture} size={35}>
                {record.initials}
              </Avatar>
              <b>
                {record.displayName}{' '}
                {record.ay_sync && <i className="icon-ay_icon" style={{ color: colors.ayBrand }} />}
              </b>
            </Space>
          );
        },
        sorter: (a: any, b: any) => a.lastname?.localeCompare(b.lastname),
      },
      {
        title: t('GLOBAL.ACCESSES'),
        key: 'role',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: any) => {
          return <div>{t(`USERS.ROLES.${record.role?.name}`)}</div>;
        },
        sorter: (a: any, b: any) => a.role?.name?.localeCompare(b.role?.name),
      },
      {
        title: t('USERS.COLUMNS.PRIORITY_RESOURCE_SHORT'),
        key: 'prior',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: any) => {
          return <div>{record.prior ? t('GLOBAL.YES') : t('GLOBAL.NO')}</div>;
        },
        sorter: (a: any, b: any) => (a.prior === b.prior ? 0 : a.prior ? -1 : 1),
      },
      {
        title: t('GLOBAL.USER_CATEGORY_SHORT'),
        key: 'status',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: any) => {
          return <div>{record.category?.name}</div>;
        },
        sorter: (a: any, b: any) => a.category?.name?.localeCompare(b.category?.name),
      },
      // {
      //   title: t('USERS.COLUMNS.HOURS_IN_YEAR_SHORT'),
      //   key: 'hoursInYear',
      //   dataIndex: ['stats', 'hoursInYear'],
      //   sorter: (a: any, b: any) => b.stats?.hoursInYear - a.stats?.hoursInYear,
      // },
      // {
      //   title: t('USERS.COLUMNS.HOURS_IN_QUARTER_SHORT'),
      //   key: 'hoursInTrimester',
      //   dataIndex: ['stats', 'hoursInTrimester'],
      //   sorter: (a: any, b: any) => b.stats?.hoursInTrimester - a.stats?.hoursInTrimester,
      // },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    // USERS
    if (!location?.pathname || !location?.pathname.endsWith('all')) {
      return;
    }
    const cancelTokenSource = Axios.CancelToken.source();
    appContextDispatch({
      type: 'SET_LOADING_USERS',
      payload: true,
    });
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/users`, {
      params: {
        departmentId: activeDepartmentId,
        sectionId: activeSection,
      },
      cancelToken: cancelTokenSource.token,
    })
      .then((response) => {
        const { data } = response;
        appContextDispatch({
          type: 'SET_USERS',
          payload: data.users,
        });
        appContextDispatch({
          type: 'SET_USERS_FIELDS',
          payload: data.fields,
        });
      })
      .catch((error) => {
        console.error(error);
        appContextDispatch({
          type: 'SET_USERS',
          payload: [],
        });
        appContextDispatch({
          type: 'SET_USERS_FIELDS',
          payload: [],
        });
      })
      .finally(() => {
        appContextDispatch({
          type: 'SET_LOADING_USERS',
          payload: false,
        });
      });

    return () => {
      cancelTokenSource.cancel();
    };
  }, [location?.pathname, activeSection]);

  useEffect(() => {
    const columns: any[] = [...defaultColumns];
    const defaultFieldsToDisplay: { [key: string]: boolean } = {};

    columns.splice(
      1,
      0,
      ...usersFields.map((field) => {
        if (defaultFields.includes(field.resourceType || '')) {
          defaultFieldsToDisplay[`field_${field.id}`] = true;
        }

        return {
          title: getResourceTranslated(field),
          key: `field_${field.id}`,
          // eslint-disable-next-line react/display-name
          render: (text: any, record: IUser) => {
            const value = record.fields?.find((x) => x.id === field.id)?.value;
            return (
              <Space style={{ whiteSpace: field.resourceType === RESOURCE_TYPES.PHONE ? 'nowrap' : undefined }}>
                {value}
              </Space>
            );
          },
          sorter: (a: any, b: any) => {
            const aValue: string | undefined = a.fields?.find((x: any) => x.id === field.id)?.value;
            if (aValue) {
              const bValue: string | undefined = b.fields?.find((x: any) => x.id === field.id)?.value;
              if (bValue) {
                return aValue.localeCompare(bValue);
              } else {
                return 0;
              }
            } else {
              return -1;
            }
          },
        };
      }),
    );

    if (usersFields && usersFields.length) {
      const columnFiltersMap = new Map<string, { name: string; value: boolean; resourceType?: string }>();
      const userColumnFilters = localStorage.getItem(`userColumnFilters_${activeDepartmentId}`);
      let _columnFilters = {
        displayName: true,
        hoursInYear: true,
        hoursInTrimester: true,
        prior: true,
        status: true,
        role: true,
        ...defaultFieldsToDisplay,
      };

      if (userColumnFilters) {
        _columnFilters = JSON.parse(userColumnFilters);
      }

      columnFiltersMap.set('displayName', { name: t('GLOBAL.DISPLAY_NAME'), value: false });
      columnFiltersMap.set('hoursInYear', { name: t('USERS.COLUMNS.NUMBER_OF_HOURS_BOOKED_YEAR'), value: false });
      columnFiltersMap.set('hoursInTrimester', {
        name: t('USERS.COLUMNS.NUMBER_OF_HOURS_BOOKED_QUARTER'),
        value: false,
      });
      columnFiltersMap.set('prior', { name: t('GLOBAL.PRIORITY_RESOURCE'), value: false });
      columnFiltersMap.set('status', { name: t('GLOBAL.USER_CATEGORY_SHORT'), value: false });
      columnFiltersMap.set('role', { name: t('GLOBAL.ACCESS_LEVEL'), value: false });
      columnFiltersMap.set('employer_cost', { name: t('GLOBAL.EMPLOYER_COST'), value: false });
      columnFiltersMap.set('employee_cost', { name: t('GLOBAL.EMPLOYEE_COST'), value: false });
      usersFields.map((field) => {
        columnFiltersMap.set(`field_${field.id}`, {
          name: field.name!,
          value: false,
          resourceType: field.resourceType,
        });
        return false;
      });

      for (const [key, value] of Object.entries(_columnFilters) as [string, boolean][]) {
        const column: { name: string; value: boolean; resourceType?: string } | undefined = columnFiltersMap.get(key);
        if (column) {
          column.value = value;
          columnFiltersMap.set(key, column);
        }
      }

      if (columnFiltersMap.get('employer_cost')) {
        columns.push({
          title: t('GLOBAL.EMPLOYER_COST'),
          key: `employer_cost`,
          // eslint-disable-next-line react/display-name
          render: (text: any, record: IUser) => {
            return <span>{record.priceBruto}</span>;
          },
        });
      }

      if (columnFiltersMap.get('employee_cost')) {
        columns.push({
          title: t('GLOBAL.EMPLOYEE_COST'),
          key: `employee_cost`,
          // eslint-disable-next-line react/display-name
          render: (text: any, record: IUser) => {
            return <span>{record.price_per_hour}</span>;
          },
        });
      }

      setColumnFilters(columnFiltersMap);
    }
    setColumns(columns);
  }, [defaultColumns, users, usersFields]);

  useEffect(() => {
    setDisplayedColumns(
      columns.filter((column) => {
        return columnFilters.get(column.key)?.value || false;
      }),
    );
    // eslint-disable-next-line
  }, [columnFilters, columns]);

  useEffect(() => {
    if (users) {
      const filteredUsers = users.filter((user: IUser) => {
        let showUser = true;
        if (filterSearch) {
          showUser = Object.keys(user).some((key) => {
            if (typeof (user as any)[key] === 'string') {
              return (user as any)[key].toLowerCase().includes(filterSearch.toLowerCase());
            } else {
              return false;
            }
          });
        }
        if (showUser && filterStatus) {
          showUser = user.category?.id === filterStatus;
        }
        if (showUser && filterRole) {
          showUser = (user.role as IUserRole)?.id === filterRole;
        }
        return showUser;
      });
      setFilteredUsers(filteredUsers as IUser[]);
    } else {
      setFilteredUsers([]);
    }
  }, [users, filterSearch, filterStatus, filterRole]);

  const onRowClick = (user: IUser) => {
    history.push(`/app/team/collaborators/${user.recordId}`);
  };

  const onRow = (user: IUser) => {
    return {
      onClick: () => {
        onRowClick(user);
      },
    };
  };

  return (
    <React.Fragment>
      <Switch>
        <Route exact path={`${path}`} render={() => <Redirect to={`${url}/all`} />} />
        <Route path={`${path}/all`}>
          <ContainerView>
            <CollaboratorsList
              columns={displayedColumns}
              dataSource={filteredUsers}
              columnFilters={columnFilters}
              loading={loadingUsers}
              departmentId={activeDepartmentId}
              filterSearch={filterSearch}
              filterStatus={filterStatus || undefined}
              filterRole={filterRole || undefined}
              onRow={onRow}
              setColumnFilters={setColumnFilters}
              setExportMode={setExportMode}
              setFilterRole={setFilterRole}
              setFilterSearch={setFilterSearch}
              setFilterStatus={setFilterStatus}
              userIds={filteredUsers?.map((user) => user.recordId!) || []}
            />
          </ContainerView>
        </Route>
        <Route path={`${path}/:id`}>
          <AppTeamCollaboratorPage />
        </Route>
      </Switch>
      {/* <ModalExport
        departmentId={activeDepartmentId}
        mode={exportMode}
        userIds={filteredUsers?.map((user) => user.recordId!) || []}
        fields={usersFields}
        onClose={() => setExportMode(null)}
      /> */}
    </React.Fragment>
  );
};

export default AppTeamCollaboratorsPage;
