import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { Menu } from 'antd';

interface Props {
  className?: string;
  setImportWeekVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onClick: () => void;
}

const ImportWeek: React.FC<Props> = ({ className, setImportWeekVisible, onClick }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);

  const onImport = () => {
    setImportWeekVisible(true);
    onClick();
  };

  return (
    <Menu.Item className={className} onClick={onImport}>
      <i className="icon-dupe" /> {t('SCHEDULE.IMPORT_WEEK.TITLE')}
    </Menu.Item>
  );
};

export default styled(ImportWeek)`
  margin: 0 5px;
  &:hover {
    background-color: #efefef !important;
    border-radius: 8px;
  }
`;
