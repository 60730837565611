import { setToken } from '@/actions';
import AuthContext from '@/context';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { Button, Divider, Form, Modal, Select } from 'antd';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { store as dashboardStore } from '../dashboard/redux/store';
import { store as manageStore } from '../hours/manage/redux/store';
import ModalOpenInvoices from './ModalOpenInvoices';

const { Option } = Select;
interface Props {
  className?: string;
  visible: boolean;
}

const ModalAccountBlockedOld: React.FC<Props> = ({ className, visible }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const {
    state: { activeDepartmentId, departments, loadingDepartments },
    dispatch: appContextDispatch,
  } = useContext(AppContext);
  const {
    dispatch: authContextDispatch,
    state: { impersonate },
  } = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [showInvoices, setShowInvoices] = useState<boolean>(false);
  const history = useHistory();

  const myDepartments = departments?.filter((department) => department.role !== 'USER');

  const onChangePayementMethod = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/operations/update-method`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        location.href = data.url;
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleDepartmentChange = (value: string) => {
    if (value && activeDepartmentId !== value) {
      const department = departments?.find((x) => x.id === value);
      if (department) {
        appContextDispatch({
          type: 'SET_ACTIVE_DEPARTMENT',
          payload: department?.id,
        });
        authContextDispatch({
          type: 'SET_TRIAL_END_DATE',
          payload: department?.trialEndDate || null,
        });
        authContextDispatch({
          type: 'SET_BLOCKED',
          payload: department?.accountType === 'BLOCKED' ? true : false,
        });
        history.push('/app/dashboard');
      }
    }
  };

  const handleLogout = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (impersonate && accessToken) {
      sessionStorage.removeItem('impersonateToken');
      history.push('/app');
      setToken(authContextDispatch, accessToken);
    } else {
      history.push('/app');
      localStorage.clear();
      manageStore.dispatch({
        type: 'RESET',
      });
      dashboardStore.dispatch({
        type: 'RESET',
      });
      authContextDispatch({
        type: 'RESET',
      });
      appContextDispatch({
        type: 'RESET',
      });
    }
  };

  const onContact = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'launchchat',
      },
      dataLayerName: 'PageDataLayer',
    });
  };

  return (
    <Modal className={className} width={465} forceRender={true} closable={false} visible={visible} footer={null}>
      <h1 style={{ color: colors.red }}>{t('ACCOUNT.MODAL_BLOCKED_ACCOUNT.TITLE')}</h1>
      <p style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold' }}>{t('ACCOUNT.MODAL_BLOCKED_ACCOUNT.CONTENT')}</p>
      <p style={{ whiteSpace: 'pre-wrap', color: colors.grey }}>{t('ACCOUNT.MODAL_BLOCKED_ACCOUNT.EXPLANATION')}</p>
      <Button type="link" style={{ color: colors.green }} onClick={() => setShowInvoices(true)}>
        {'> '}
        {t('ACCOUNT.MODAL_BLOCKED_ACCOUNT.DOWNLOAD')}
      </Button>
      <Button
        loading={loading}
        type="primary"
        size="large"
        onClick={onChangePayementMethod}
        style={{ width: 'auto', margin: '30px 0' }}
      >
        {t('ACCOUNT.MODAL_BLOCKED_ACCOUNT.BUTTON')}
      </Button>
      <p style={{ color: colors.grey, fontSize: '0.9em' }}>{t('ACCOUNT.MODAL_BLOCKED_ACCOUNT.FOOTER')}</p>
      <Button type="link" style={{ color: colors.green }} onClick={onContact}>
        {t('GLOBAL.CONTACT_US')}
      </Button>
      <br />
      {myDepartments?.length > 1 && (
        <Form layout="vertical">
          <Divider />
          <Form.Item label={t('FORMS.ACCOUNT_PLACEHOLDER')}>
            <Select
              getPopupContainer={(trigger) => trigger}
              disabled={loadingDepartments}
              loading={loadingDepartments}
              placeholder={t('FORMS.ACCOUNT_PLACEHOLDER')}
              onChange={handleDepartmentChange}
            >
              {myDepartments?.map((department) => {
                const { id, company } = department;
                return (
                  <Option key={`dept_${id}`} value={`${id}`}>
                    {company}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      )}
      <Button danger size="large" style={{ width: '100%' }} onClick={handleLogout}>
        {t('GLOBAL.LOGOUT')}
      </Button>
      {visible && <ModalOpenInvoices visible={showInvoices} onClose={() => setShowInvoices(false)} />}
    </Modal>
  );
};

export default styled(ModalAccountBlockedOld)`
  .ant-modal-body {
    padding-top: 50px;
    text-align: center;
    h1 {
      font-weight: 600;
      margin-bottom: 20px;
    }
  }
`;
