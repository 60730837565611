import React, { useContext, useEffect, useState } from 'react';
import FadeIn from 'react-fade-in';
import Lottie from 'react-lottie';
import * as legoData from './legoloading.json';
import * as doneData from './doneloading.json';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import AuthContext from '@/context';
import { setToken } from '@/actions';
import { useHistory } from 'react-router-dom';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: (legoData as any).default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
const defaultOptions2 = {
  loop: false,
  autoplay: true,
  animationData: (doneData as any).default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

interface Props {
  className?: string;
  token: string | null;
}

const CreatingAccount: React.FC<Props> = ({ className, token }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const { state: authContextState, dispatch: authContextDispatch } = useContext(AuthContext);
  const history = useHistory();

  // Create account
  const [creatingAccount, setCreatingAccount] = useState<boolean>(false);
  const [createdAccount, setCreatedAccount] = useState<boolean>(false);

  // Create settings
  const [creatingSettings, setCreatingSettings] = useState<boolean>(false);
  const [createdSettings, setCreatedSettings] = useState<boolean>(false);

  // Create access
  const [creatingAccess, setCreatingAccess] = useState<boolean>(false);
  const [createdAccess, setCreatedAccess] = useState<boolean>(false);

  // Create data
  const [creatingData, setCreatingData] = useState<boolean>(false);
  const [createdData, setCreatedData] = useState<boolean>(false);

  useEffect(() => {
    if (token) {
      setToken(authContextDispatch, token);
    }
    setCreatingAccount(true);
    setTimeout(() => {
      setCreatedAccount(true);
      setCreatingSettings(true);
    }, AVG_REQUEST / 4);
    setTimeout(() => {
      setCreatedSettings(true);
      setCreatingAccess(true);
    }, AVG_REQUEST / 2);
    setTimeout(() => {
      setCreatedData(true);
      if (token) {
        history.push('/app/dashboard?productTour=true');
      } else {
        history.push('/login');
      }
    }, (AVG_REQUEST / 4) * 3);
  }, [token]);

  return (
    <div className={className}>
      <div>
        {creatingAccount && (
          <FadeIn>
            <div className="loading">
              <h2>{t('CONFIRM_EMAIL.STEP_LOADING.ACCOUNT_CREATION')}</h2>
              {!createdAccount ? (
                <Lottie options={defaultOptions} height={120} width={120} />
              ) : (
                <Lottie options={defaultOptions2} height={120} width={120} />
              )}
            </div>
          </FadeIn>
        )}
        {creatingSettings && (
          <FadeIn>
            <div className="loading">
              <h2>{t('CONFIRM_EMAIL.STEP_LOADING.SETTINGS_CREATION')}</h2>
              {!createdSettings ? (
                <Lottie options={defaultOptions} height={120} width={120} />
              ) : (
                <Lottie options={defaultOptions2} height={120} width={120} />
              )}
            </div>
          </FadeIn>
        )}
        {creatingAccess && (
          <FadeIn>
            <div className="loading">
              <h2>{t('CONFIRM_EMAIL.STEP_LOADING.ACCESS_CREATION')}</h2>
              {!createdAccess ? (
                <Lottie options={defaultOptions} height={120} width={120} />
              ) : (
                <Lottie options={defaultOptions2} height={120} width={120} />
              )}
            </div>
          </FadeIn>
        )}
      </div>
    </div>
  );
};

export default styled(CreatingAccount)`
  .loading {
    transform: scale(0.8);
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2  {
      padding: 0 !important;
      margin: 0 !important;
      line-height: 34px;
    }
  }
`;

const AVG_REQUEST = 17000;
