import { usePusher } from '@/components/PusherProvider';
import AuthContext from '@/context';
import NewBadge from '@/pages/app/components/NewBadge';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { FEATURES } from '@/types/features.model';
import { IShiftTemplate } from '@/types/shift-template.model';
import { createNumberArray, handleError, isFeatureEnabled } from '@/utils';
import { Button, Form, Modal, Select, Switch, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import moment from 'moment';
import { Channel } from 'pusher-js';
import React, { Dispatch, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { ActionType } from '../redux/actions';
import { InitialStateType } from '../redux/store';

interface Props {
  className?: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalImportWeek: React.FC<Props> = ({ className, visible, setVisible }) => {
  const { t } = useTranslation();
  const {
    dispatch: authContextDispatch,
    state: { userDetails, impersonate },
  } = useContext(AuthContext);
  const routeMatch = useRouteMatch();
  const { formatedDate } = routeMatch.params as any;
  const {
    state: {
      activeDepartment,
      activeSection,
      activeDepartmentId,
      users,
      loadingUsers,
      userCategories,
      loadingUserCategories,
      sections,
      skills,
      features,
    },
  } = useContext(AppContext);
  const { selectedDate, startDate, endDate, picker, shiftsMap } = useSelector(
    ({ selectedDate, startDate, endDate, picker, shiftsMap }: InitialStateType) => ({
      selectedDate,
      startDate,
      endDate,
      picker,
      shiftsMap,
    }),
  );
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [templates, setTemplates] = useState<IShiftTemplate[]>([]);
  const [loadingTemplates, setLoadingTemplates] = useState<boolean>(false);
  const pusher = usePusher();
  const hoursDispatch: Dispatch<ActionType> = useDispatch();

  const listenerRef: any = useRef(null);
  const usersSelectRef: any = useRef(null);

  useEffect(() => {
    let channel: Channel | null = null;
    const setupPusherListener = () => {
      if (pusher && userDetails && activeDepartment) {
        // PUSHER
        channel = pusher.channel(`private-App.User.${userDetails?.id}.Department.${activeDepartment?.id}`);

        // Check if the connection is established before binding the event
        if (channel) {
          channel.bind(`Illuminate\\Notifications\\Events\\BroadcastNotificationCreated`, (message: any) => {
            if (message && message.reload_shifts) {
              const selectedWeek = moment(selectedDate).week();
              const selectedYear = moment(selectedDate).year();
              if (
                message.weeks &&
                message.weeks.some((entry: any) => entry.week_number != selectedWeek && entry.year != selectedYear)
              ) {
                return;
              } else {
                hoursDispatch({
                  type: 'SET_IS_LOADING',
                  payload: true,
                });
                hoursDispatch({
                  type: 'SET_HAS_ERROR',
                  payload: false,
                });
                axios
                  .get(`${process.env.REACT_APP_API_URL}/v3/shifts`, {
                    params: {
                      departmentId: activeDepartment?.id,
                      sectionId: activeSection,
                      startDate: startDate.unix(),
                      endDate: endDate.unix(),
                      picker,
                    },
                  })
                  .then((response) => {
                    const {
                      shifts,
                      users,
                      days = [],
                      hours = [],
                      totalBudget = null,
                      comparisonTemplate = null,
                      cycleNumber = null,
                      scheduleModel = false,
                      productivityIndex = null,
                      productivityIndexReal = null,
                    } = response.data;
                    batch(() => {
                      hoursDispatch({
                        type: 'SET_SHIFTS',
                        payload: {
                          skills,
                          userStatus: userCategories,
                          picker,
                          activeDepartment,
                          shifts,
                          users,
                          days,
                          hours,
                          totalBudget,
                          comparisonTemplate,
                          cycleNumber,
                          scheduleModel,
                          productivityIndex,
                          productivityIndexReal,
                          activeSection,
                        },
                      });
                      hoursDispatch({
                        type: 'UPDATE_FILTERED_SHIFTS',
                        payload: { department: activeDepartment },
                      });
                      hoursDispatch({
                        type: 'SET_IS_LOADING',
                        payload: false,
                      });
                    });
                  })
                  .catch((error) => {
                    if (!axios.isCancel(error)) {
                      batch(() => {
                        hoursDispatch({
                          type: 'SET_SHIFTS',
                          payload: {
                            skills,
                            userStatus: userCategories,
                            picker,
                            activeDepartment,
                            users: [],
                            shifts: [],
                            days: [],
                            hours: [],
                            totalBudget: null,
                            comparisonTemplate: null,
                            cycleNumber: null,
                            productivityIndex: null,
                            productivityIndexReal: null,
                            scheduleModel: false,
                            activeSection,
                          },
                        });
                        hoursDispatch({
                          type: 'UPDATE_FILTERED_SHIFTS',
                          payload: { department: activeDepartment },
                        });
                        hoursDispatch({
                          type: 'SET_HAS_ERROR',
                          payload: true,
                        });
                        hoursDispatch({
                          type: 'SET_IS_LOADING',
                          payload: false,
                        });
                      });
                    }
                    console.error(error);
                  });
              }
            }
          });
        }
      }
    };

    setupPusherListener();

    return () => {
      if (channel) {
        channel.unbind();
      }
    };
  }, [pusher, userDetails, activeDepartment, selectedDate, hoursDispatch, activeSection]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      freeshifts: false,
      import_shifts: true,
      import_no_section: true,
      import_freeshifts: true,
      import_dayoffs: true,
      import_unavailabilities: true,
      freeshifts_if_booked: false,
      weeks: [selectedDate.week()],
    });
    if (visible) {
      getTemplates();
    }
  }, [visible, activeSection]);

  const onCancel = () => {
    setVisible(false);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    const allUsers = values.users.includes('ALL');
    const weeks = values.weeks.map((week: number) => {
      let newWeek = week;
      let nextYear = false;
      if (week > 52) {
        newWeek = week - 52;
        nextYear = true;
      }
      const year = moment(selectedDate).year();
      return { week_number: newWeek, year: nextYear ? year + 1 : year };
    });
    if (values.from == 'PREVIOUS_WEEK') {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v3/shifts/duplicate-week`,
          {
            ...values,
            import_no_section: !isFeatureEnabled(features, FEATURES.SECTIONS) ? true : values.import_no_section,
            import_freeshifts: !isFeatureEnabled(features, FEATURES.FREESHIFTS) ? true : values.import_freeshifts,
            import_dayoffs: !isFeatureEnabled(features, FEATURES.LEAVE_MANAGEMENT) ? true : values.import_dayoffs,
            freeshifts_if_booked: !isFeatureEnabled(features, FEATURES.FREESHIFTS) ? true : values.freeshifts_if_booked,
            users: allUsers
              ? users
                  .filter((user: any) =>
                    activeSection && typeof user.sections == 'string'
                      ? user.sections.split(',').includes(activeSection)
                      : true,
                  )
                  .map((user) => user.recordId)
              : values.users,
            week_number: Number(values.week_number.split('.')[0]),
            year: Number(values.week_number.split('.')[1]),
            weeks,
          },
          {
            params: {
              departmentId: activeDepartmentId,
              sectionId: activeSection,
            },
          },
        )
        .then(({ data }) => {
          message.success(t('SCHEDULE.IMPORT_WEEK.REQUEST_SENT'));
        })
        .catch((err) => {
          handleError(err);
        })
        .finally(() => {
          setLoading(false);
          setVisible(false);
        });
    } else {
      const templateId = values.template;
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v3/shifts/duplicate-week/${templateId}`,
          {
            ...values,
            users: allUsers
              ? users
                  .filter((user: any) =>
                    activeSection && typeof user.sections == 'string'
                      ? user.sections.split(',').includes(activeSection)
                      : true,
                  )
                  .map((user) => user.recordId)
              : values.users,
            weeks,
          },
          {
            params: {
              departmentId: activeDepartmentId,
              sectionId: activeSection,
            },
          },
        )
        .then(({ data }) => {
          message.success(t('SCHEDULE.IMPORT_WEEK.REQUEST_SENT'));
        })
        .catch((err) => {
          handleError(err);
        })
        .finally(() => {
          setLoading(false);
          setVisible(false);
        });
    }
  };

  const getTemplates = () => {
    setLoadingTemplates(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/shift-templates`, {
        params: {
          departmentId: activeDepartment?.id,
        },
      })
      .then(({ data }) => {
        if (activeSection) {
          setTemplates(data.filter((template: IShiftTemplate) => template.section == activeSection));
        } else {
          setTemplates(data);
        }
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoadingTemplates(false);
      });
  };

  const onSelect = (value: string) => {
    if (value == 'ALL') {
      usersSelectRef.current.blur();
      form.setFieldsValue({
        users: ['ALL'],
      });
    } else {
      const users = form.getFieldValue('users');
      if (users && users.includes('ALL')) {
        form.setFieldsValue({
          users: users.filter((user: string) => user !== 'ALL'),
        });
      }
    }
  };

  return (
    <Modal
      className={className}
      title={
        <div className="title">
          <div className="top">
            <NewBadge end="2024-01-01" />
            <span>{t('SCHEDULE.IMPORT_WEEK.TITLE')}</span>
          </div>
          <div className="bottom">
            <span>{t('SCHEDULE.IMPORT_WEEK.DESCRIPTION')}</span>
          </div>
        </div>
      }
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={650}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="from" label={t('SCHEDULE.IMPORT_WEEK.IMPORT_FROM.TITLE')} rules={[{ required: true }]}>
          <Select size="large" placeholder={t('SCHEDULE.IMPORT_WEEK.IMPORT_FROM.TITLE')}>
            <Select.Option value="PREVIOUS_WEEK">{t('SCHEDULE.IMPORT_WEEK.IMPORT_FROM.PREVIOUS_WEEK')}</Select.Option>
            <Select.Option value="WEEKLY_TEMPLATE">{t('SCHEDULE.IMPORT_WEEK.IMPORT_FROM.WEEK_TEMPLATE')}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 0 }}
          shouldUpdate={(prevValues, curValues) => prevValues.from !== curValues.from}
        >
          {(form) => {
            const from = form.getFieldValue('from');
            if (from == 'PREVIOUS_WEEK') {
              return (
                <Form.Item name="week_number" label={t('GLOBAL.WEEK')} rules={[{ required: true }]}>
                  <Select size="large" placeholder={t('GLOBAL.WEEK')}>
                    {/* {createNumberArray(moment().week() - 10, moment().week()).map((week) => {
                      return (
                        <Select.Option value={week}>
                          {t('GLOBAL.WEEK_SHORT')}
                          {week} - {t('GLOBAL.FROM')}{' '}
                          {moment().year(moment().year()).isoWeek(week).startOf('isoWeek').format('L')} {t('GLOBAL.TO')}{' '}
                          {moment().year(moment().year()).isoWeek(week).endOf('isoWeek').format('L')}
                        </Select.Option>
                      );
                    })} */}

                    {createNumberArray(moment(selectedDate).week() - 10, moment(selectedDate).week())
                      .reverse()
                      .map((week, i) => {
                        let previousYear = false;
                        if (week <= 0) {
                          week = 52 - Math.abs(week);
                          previousYear = true;
                        }
                        if (shiftsMap.size == 0 && week == moment(selectedDate).week()) return;
                        return (
                          <Select.Option
                            value={`${week}.${
                              previousYear ? moment(selectedDate).year() - 1 : moment(selectedDate).year()
                            }`}
                          >
                            {t('GLOBAL.WEEK_SHORT')}
                            {week} -{' '}
                            {t('GLOBAL.FROM_DATE_TO_DATE', {
                              date1: moment()
                                .year(previousYear ? moment(selectedDate).year() - 1 : moment(selectedDate).year())
                                .isoWeek(week)
                                .startOf('isoWeek')
                                .format('L'),
                              date2: moment()
                                .year(previousYear ? moment(selectedDate).year() - 1 : moment(selectedDate).year())
                                .isoWeek(week)
                                .endOf('isoWeek')
                                .format('L'),
                            })}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              );
            }
            return (
              <Form.Item
                name="template"
                label={t('SCHEDULE.IMPORT_WEEK.IMPORT_FROM.WEEK_TEMPLATE_SHORT')}
                rules={[{ required: true }]}
              >
                <Select
                  size="large"
                  placeholder={t('SCHEDULE.IMPORT_WEEK.IMPORT_FROM.WEEK_TEMPLATE_SHORT')}
                  loading={loadingTemplates}
                  disabled={loadingTemplates}
                  showSearch
                  filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {templates.map((template) => (
                    <Select.Option value={template.id!}>
                      {template.section !== '0' && sections.find((section) => section.id == template.section)
                        ? `[${sections.find((section) => section.id == template.section)?.name}] `
                        : null}
                      {template.template_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item name="users" label={t('GLOBAL.USERS')} rules={[{ required: true }]}>
          <Select
            size="large"
            placeholder={t('GLOBAL.USERS')}
            mode="multiple"
            loading={loadingUsers || loadingUserCategories}
            disabled={loadingUsers || loadingUserCategories}
            maxTagCount={2}
            onSelect={onSelect}
            ref={usersSelectRef}
            filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Select.Option value="ALL">{t('SCHEDULE.IMPORT_WEEK.ALL_EMPLOYEES')}</Select.Option>
            {users.map((user) => (
              <Select.Option value={user.recordId!}>{user.displayName}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="weeks" label={t('SCHEDULE.IMPORT_WEEK.APPLY_ON_WEEKS')} rules={[{ required: true }]}>
          <Select size="large" placeholder={t('GLOBAL.WEEKS')} mode="multiple" maxTagCount={1}>
            {createNumberArray(moment(selectedDate).week(), moment(selectedDate).week() + 15).map((week) => {
              let previousYear = false;
              if (week <= 0) {
                week = 52 - Math.abs(week);
                previousYear = true;
              }
              return (
                <Select.Option value={week}>
                  {t('GLOBAL.WEEK_SHORT')}
                  {week > 52 ? week - 52 : week} -{' '}
                  {t('GLOBAL.FROM_DATE_TO_DATE', {
                    date1: moment()
                      .year(previousYear ? moment(selectedDate).year() - 1 : moment(selectedDate).year())
                      .isoWeek(week)
                      .startOf('isoWeek')
                      .format('L'),
                    date2: moment()
                      .year(previousYear ? moment(selectedDate).year() - 1 : moment(selectedDate).year())
                      .isoWeek(week)
                      .endOf('isoWeek')
                      .format('L'),
                  })}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        {isFeatureEnabled(features, FEATURES.FREESHIFTS) && (
          <div className="content">
            <span>{t('SCHEDULE.IMPORT_WEEK.CONVERT_TO_FREESHIFTS')}</span>
            <Form.Item className="one-line" name="freeshifts" rules={[{ required: true }]} valuePropName="checked">
              <Switch />
            </Form.Item>
          </div>
        )}
        {isFeatureEnabled(features, FEATURES.FREESHIFTS) && (
          <div className="content">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <NewBadge end="2024-02-01" />
              <span>{t('SCHEDULE.IMPORT_WEEK.FREE_SHIFTS_IF_BOOKED')}</span>
            </div>
            <Form.Item
              className="one-line"
              name="freeshifts_if_booked"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </div>
        )}
        <div className="content">
          <span>{t('SCHEDULE.IMPORT_WEEK.IMPORT_SHIFTS')}</span>
          <Form.Item className="one-line" name="import_shifts" rules={[{ required: true }]} valuePropName="checked">
            <Switch />
          </Form.Item>
        </div>
        {isFeatureEnabled(features, FEATURES.SECTIONS) && sections.length > 0 && (
          <div className="content">
            <span>{t('SCHEDULE.IMPORT_WEEK.IMPORT_WITHOUT_SECTION')}</span>
            <Form.Item
              className="one-line"
              name="import_no_section"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </div>
        )}
        {isFeatureEnabled(features, FEATURES.FREESHIFTS) && (
          <div className="content">
            <span>{t('SCHEDULE.IMPORT_WEEK.IMPORT_FREESHIFTS')}</span>
            <Form.Item
              className="one-line"
              name="import_freeshifts"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </div>
        )}
        <div className="content">
          <span>{t('SCHEDULE.IMPORT_WEEK.IMPORT_DAYOFFS')}</span>
          <Form.Item className="one-line" name="import_dayoffs" rules={[{ required: true }]} valuePropName="checked">
            <Switch />
          </Form.Item>
        </div>
        <div className="content">
          <span>{t('SCHEDULE.IMPORT_WEEK.IMPORT_UNAVAILABILITIES')}</span>
          <Form.Item
            className="one-line"
            name="import_unavailabilities"
            rules={[{ required: true }]}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </div>
        <div className="actions-container">
          <div className="actions">
            <Button danger onClick={onCancel} disabled={loading}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
              {t('GLOBAL.IMPORT')}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default styled(ModalImportWeek)`
  margin-top: -75px;

  .title {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .top {
      display: flex;
      align-items: center;
    }

    .bottom {
      font-size: 12px;
      color: ${colors.grey};
    }
  }

  .one-line {
    margin: 0;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .actions-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;

    .actions {
      display: flex;
      gap: 10px;
    }
  }
`;
