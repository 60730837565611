import AppContext from '@/pages/app/context';
import { Button } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import DrawerTurnover from './DrawerTurnover';
import TurnoverTemplate from './TurnoverTemplate';
import TemplatesTable from './TurnoverTemplatesTable';

interface Props {
  className?: string;
}

const TurnoverTemplates: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const {
    state: { turnoverTemplates },
    dispatch,
  } = useContext(AppContext);
  const history = useHistory();
  const { templateId } = useParams() as any;
  const [drawerTurnoverVisible, setDrawerTurnoverVisible] = useState<boolean>(false);

  const activeTemplate = turnoverTemplates.find((template) => template.id == templateId) || null;

  const onCreateNewTemplate = () => {
    setDrawerTurnoverVisible(true);
  };

  const Table = () => {
    return (
      <div className="container">
        <Button className="add-btn" type="primary" onClick={onCreateNewTemplate}>
          {t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.CREATE_TEMPLATE')}
        </Button>
        <TemplatesTable />
      </div>
    );
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.TITLE')}</h2>
      {templateId ? <TurnoverTemplate activeTemplate={activeTemplate} /> : <Table />}
      <DrawerTurnover visible={drawerTurnoverVisible} setVisible={setDrawerTurnoverVisible} />
    </div>
  );
};

export default styled(TurnoverTemplates)`
  .container {
    background-color: #fff;
    padding: 25px;
    border: 1px solid #f0f0f0;
    border-top: 0px;

    .add-btn {
      margin-bottom: 15px;
    }
  }
`;
