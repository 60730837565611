import { IConfirmEmailData } from '@/types/confirm-email/data.model';
import { Spin, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ModalAlreadyConfirmed from './components/ModalAlreadyConfirmed';
import DetailsForm from './components/DetailsForm';
import CreatingAccount from './components/CreatingAccount';

interface Props {
  className?: string;
}

const ConfirmEmailPage: React.FC<Props> = ({ className }) => {
  const { t, i18n } = useTranslation();
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [showLegos, setShowLegos] = useState<boolean>(false);
  const [modalAlreadyConfirmedVisible, setModalAlreadyConfirmedVisible] = useState<boolean>(false);
  const [data, setData] = useState<IConfirmEmailData | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const params = new URLSearchParams(window.location.search);
  const lang = params && params.get('lang');

  useEffect(() => {
    i18n.changeLanguage(lang ?? 'fr');
    setModalAlreadyConfirmedVisible(false);
    setShowLegos(false);
  }, [lang]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data.billing);
    }
  }, [data]);

  const getData = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/operations/confirmEmail`, {
        params: {
          id: params.get('id'),
        },
      })
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setModalAlreadyConfirmedVisible(true);
        console.error(error);
      });
  };

  const onFinish = (values: any) => {
    if (values.password !== values.password2) {
      return message.error(t('CONFIRM_EMAIL.PASSWORDS_DONT_MATCH'));
    }
    if (values.password.length < 8 || values.password2.length < 8) {
      return message.error(t('CONFIRM_EMAIL.PASSWORD_MIN_LENGTH'));
    }
    setShowLegos(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/operations/confirmEmail`,
        {
          ...values,
          password: undefined,
          password2: undefined,
          newpassword: values.password,
        },
        {
          params: {
            id: params.get('id'),
          },
        },
      )
      .then(({ data }) => {
        setToken(data.token);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={className}>
      <div className="container">
        <div className="header">
          <img className="logo" src={process.env.PUBLIC_URL + '/shyfter-logo.svg'} alt="Shyfter" />
          <h2 className="title">{t(`CONFIRM_EMAIL.TITLE${showLegos ? '_LOADING' : ''}`)}</h2>
          {!showLegos && <p>{t('CONFIRM_EMAIL.SUBTITLE')}</p>}
        </div>
        {loading && <Spin spinning={loading} />}
        {!showLegos && <DetailsForm form={form} loading={loading} onFinish={onFinish} />}
        {showLegos && <CreatingAccount token={token} />}
      </div>
      <ModalAlreadyConfirmed visible={modalAlreadyConfirmedVisible} />
    </div>
  );
};

export default styled(ConfirmEmailPage)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0;

  .container {
    width: 50%;

    .logo {
      height: 50px;
      margin-bottom: 25px;
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;

    .container {
      width: 100%;
      padding: 0 25px;
    }
  }
`;
